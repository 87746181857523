import { API_ADMIN_URL } from "../../config/constants";
import { postRequest, getRequest } from "../API";

/*********************************************************
 * Function Name : List
 * Description   : Get list of all inquiry list
 * By            : Siddharth Singh
 * Date          : 27-06-2024
 *********************************************************/
export const list = async (options) => {
  try {
    const params = {
      url: `${API_ADMIN_URL}newsletter/list`,
      postData: options,
    };
    const res = await postRequest(params);
    if (res.status === true || res.status === 200) {
      return {
        status: true,
        result: res?.data?.response?.result,
        count: res?.data?.response?.count,
      };
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: "Under Maintenance, Please try after some time.",
    };
  }
}; //End of Function



/*********************************************************
* Function Name : chnageStatus
* Description   : This function is used for chnage prmomo code status
* By            : Afsar Ali
* Date          : 02-04-2024 
*********************************************************/
export const deleteNewsletter = async (options) =>{
  const { url, postData={} } = options;
  try {
      const params = {
          url : `${API_ADMIN_URL}${url}`,
          postData : {
              ...postData,
              ipAddress : sessionStorage.getItem('IP_ADDRESS')
          }
      }
      const res = await postRequest(params);
      if(res.status === true || res.status === 200){
          return {status : true, result : res?.data?.response?.result};
      } else{
          return {status : false, message:res?.response?.data?.statusMessage}
      }
  } catch (error) {
      return {status : false, message:"Under Maintenance, Please try after some time."}
  }
};//End of Function
