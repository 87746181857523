import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import Loading from "../../components/Loading/LoadingEffect";
import { addEditData as addEditFaqsData } from "../../controllers/cms/faqs.js";

const AddEditPopUp = ({
  addEditPopUp,
  toggleAddEditPopUp,
  addEditData,
  setAddEditData,
  toggleIsAddEdited
}) => {
  const handleCancel = () => {
    setAddEditData("");
    setErrors('');
    toggleAddEditPopUp();
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChnage = (e) => {
    try {
      const { name, value } = e.target;
      if(name === 'title' && value.length > 100){
        setErrors((preError) => ({
          ...preError,
          [name]: "Title should be in less than 100 characters.",
        }));
      } else if(name === 'description' && value.length > 500){
        setErrors((preError) => ({
          ...preError,
          [name]: "Description should be in less than 100 characters.",
        }));
      } else{
        setAddEditData((pre) => ({
          ...pre,
          [name]: value,
        }));
  
        setErrors((preError) => ({
          ...preError,
          [name]: "",
        }));
      }
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault(e);
      setLoading(true);
      const form = new FormData(e.target);
      if (addEditData?._id) {
        form.append("editId", addEditData._id);
      }

      const rawData = {};

      const isValidate = await validateFormData(form);
      if (isValidate) {
        const rawData = {};

        form.forEach((value, key) => {
          rawData[key] = value;
        });
        // return true;
        const res = await addEditFaqsData(rawData);
        if (res.status === true) {
          setLoading(false);
          notification.open({
            message: "Success",
            description: " Data save Successfully.",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          toggleIsAddEdited();
          handleCancel();
        } else {
          setLoading(false);
          notification.open({
            message: "Error",
            description: res?.message || "Please try after some time",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        }
      } else {
        setLoading(false);
        notification.open({
          message: "Oops!",
          description: " Please fill required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateFormData = async (formData) => {
    try {
      if (
        formData.get("type") === "undefined" ||
        formData.get("type") === null ||
        formData.get("type").trim() === "" 
      ) {
        setErrors((preError) => ({
          ...preError,
          type: "Type is required",
        }));
        return false;
      }else if (
        formData.get("title") === "undefined" ||
        formData.get("title") === null ||
        formData.get("title").trim() === "" 
      ) {
        setErrors((preError) => ({
          ...preError,
          title: "Title is required",
        }));
        return false;
      } else if(
        formData.get("description") === "undefined" ||
        formData.get("description") === null ||
        formData.get("description").trim() === "" 
      ){
        setErrors((preError) => ({
          ...preError,
          title: "Description is required",
        }));
        return false;
      }else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <Dialog
      open={addEditPopUp}
      onClose={toggleAddEditPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              {addEditData?._id ? "Edit" : "Add"} FAQs
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form
                  className="categories_open_popup_form"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="editId"
                    id="editId"
                    maxLength={100}
                    value={addEditData?._id}
                  />
                  <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="type"
                        className="categories_open_popup_label_name"
                      >
                        <span style={{ color: "red" }}>*</span>Type
                      </label>
                      <select className="form-control categories_select_tg_enter_product" name="type" id="type" >
                        <option value=''>Select type</option>
                        <option value="Web" selected={addEditData?.type === 'Web'?true:false}>Web</option>
                        <option value="App" selected={addEditData?.type === 'App'?true:false}>App</option>
                        <option value="Store" selected={addEditData?.type === 'Store'?true:false}>Store</option>
                        <option value="Branch" selected={addEditData?.type === 'Branch'?true:false}>Branch</option>
                      </select>
                      {errors?.type ? (
                        <label className="text-danger">{errors.type}</label>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="title"
                        className="categories_open_popup_label_name"
                      >
                        <span style={{ color: "red" }}>*</span>Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        value={addEditData?.title}
                        onChange={handleChnage}
                        className="form-control categories_select_tg_enter_product"
                        placeholder="Enter name"
                      />
                      {errors?.title ? (
                        <label className="text-danger">{errors.title}</label>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="description"
                        className="categories_open_popup_label_name"
                      >
                        <span style={{ color: "red" }}>*</span>Description
                      </label>
                      <textarea
                        type="text"
                        name="description"
                        id="description"
                        value={addEditData?.description}
                        onChange={handleChnage}
                        rows={6}
                        className="form-control categories_select_tg_enter_product"
                        placeholder="Enter description"
                      />
                      {errors?.description ? (
                        <label className="categories_open_popup_label_name text-danger">{errors.description}</label>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        className="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button type="Submit" className="btn btn-danger ">
                        {addEditData?._id ? "Save Change" : "Create"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
        <Loading isLoading={loading} />
      </div>
    </Dialog>
  );
};

export default AddEditPopUp;
