import React, {useState, useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Flex } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

import { ASSETS_BASE_URL } from "../../../config/constants";
import { list, addEditInventory, requestChnageStatus } from "../../../controllers/Products/inventory";
import { Link } from "react-router-dom";

const ViewRequestPopUp = ({ requestPopUp, setRequestPopUp, requestData, setRequestData }) => {
  const handleCancel = () => {
    setRequestPopUp();
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
  const [availavleVariants, setAvailableVariants] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const variants = (ALLVARIANTS = requestData?.variants || []) => {
    return ALLVARIANTS.map((items, index) => (
      <div key={index} className="variantCount row">
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="unit" className="vendor_popup_product_name">Unit</label>
            <input
              title="Enter your last name."
              type="text"
              name={`unit_${index}`}
              value={items.unit ? items.unit : ""}
              onChange={handleVarientChange}
              className="form-control store_input_field"
              id="unit"
            />
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="Price" className="vendor_popup_product_name">Price <span style={{ fontSize: "xx-small" }}>(Inclusive of VAT)</span></label>
            <input
              title="Enter your last name."
              type="number"
              name={`price_${index}`}
              value={items.price ? items.price : ""}
              onChange={handleVarientChange}
              className="form-control store_input_field"
              id="Price"
            />
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="qty" className="vendor_popup_product_name">Qty</label>
            <input
              title="Enter your last name."
              type="number"
              name={`qty_${index}`}
              value={items.qty ? items.qty : ""}
              onChange={handleVarientChange}
              className="form-control store_input_field"
              id="qty"
            />
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="discount" className="vendor_popup_product_name">Discount (%)</label>
            <input
              title="Enter your last name."
              type="number"
              name={`discount_${index}`}
              value={items.discount ? items.discount : ""}
              onChange={handleVarientChange}
              className="form-control store_input_field"
              id="discount"
            />
          </div>
        </div>

        <div className="from-group mb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label htmlFor="size" className="vendor_popup_product_name">Size :</label>
          <Flex vertical gap="middle" className="">
            <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="size" id="size" size="small" >
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <div className="vendor_dashbaord_gender_main_section">
                      <label htmlFor={`small_${index}`}>Small</label>
                      <input
                        type="checkbox"
                        id={`small_${index}`}
                        name={`small_${index}`}
                        value="Y"
                        onChange={handleVarientChange}
                        checked={items.small === 'Y'? true : false}
                        className="form-check-input"
                      />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <div className="vendor_dashbaord_gender_main_section">
                      <label htmlFor={`medium_${index}`}>Medium</label>
                      <input
                        type="checkbox"
                        id={`medium_${index}`}
                        name={`medium_${index}`}
                        value="Y"
                        onChange={handleVarientChange}
                        checked={items.medium === 'Y'? true : false}
                        className="form-check-input"
                      />
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <div className="vendor_dashbaord_gender_main_section">
                      <label htmlFor={`large_${index}`}>Large</label>
                      <input
                        type="checkbox"
                        id={`large_${index}`}
                        name={`large_${index}`}
                        value="Y"
                        onChange={handleVarientChange}
                        checked={items.large === 'Y'? true : false}
                        className="form-check-input"
                      />
                  </div>
                </div>
              </div>
            </div>
          </Flex>
        </div>
      </div>
    ));
};

const handleSubmit = async (event) => {
  try {
    event.preventDefault(); 
    // setIsLoading(true);
    const formData = new FormData(event.target);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    const variants = await transformData(formDataObject);
    const options = {
      vendor    : requestData?.vendor,
      store     : requestData?.storeId?._id,
      branch    : requestData?.branchId?._id,
      productid : requestData?.productId._id,
      varients  : variants
    }
    const res = await addEditInventory(options);
    if(res.status === true){
     const options2 = {
      id : requestData._id,
      status : "C"
     }
     const result = await requestChnageStatus(options2);
     if(result.status === true){
      notification.open({
        message: "Completed",
        description: " Your request Successfully completed.",
        placement: "topRight",
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      });
      setRequestPopUp();
     }else{
      notification.open({
        message: "Oops!",
        description: "Inventory updated but request status not chnage.",
        placement: "topRight",
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      });
     }
    }else{
      notification.open({
        message: "Oops!",
        description: " Your request not processed.",
        placement: "topRight",
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      });
    }
  } catch (error) {
    
  }
};

 /**** This function is used for generate post form data *********** */
 const transformData = (input) => {
  const { product, store, ...variants } = input;
  const variantKeys = Object.keys(variants).filter(key => key !== 'product');
  const transformedData = [];

  for (let i = 0; i < variantKeys.length / 7; i++) {
    const unit = variants[`unit_${i}`];
    const rate = variants[`price_${i}`];
    const qty = variants[`qty_${i}`];
    const small = variants[`small_${i}`]?variants[`small_${i}`]:'N';
    const medium = variants[`medium_${i}`]?variants[`medium_${i}`]:'N';
    const large = variants[`large_${i}`]?variants[`large_${i}`]:'N';

    // Only add the variant if all fields are non-empty
    if (unit !== "" && rate !== "" && qty !== "") {
      transformedData.push({
        unit,
        rate,
        qty,
        discount: variants[`discount_${i}`],
        small,
        medium,
        large
      });
    }
  }

  return transformedData;
};

const handleVarientChange = (e) => {
  try {
    const {name, value}=e.target;
    const vv = name.split('_');
    if(vv[0] === 'small' || vv[0] === 'medium' || vv[0] === 'large'){
      const size = requestData?.variants[vv[1]][vv[0]] === 'Y'?'N':'Y';
      handleChangeVariant(vv[1],vv[0],size);  
    }else{
      handleChangeVariant(vv[1],vv[0],value);
    }
  } catch (error) {
    
  }
};

const handleChangeVariant = (index, key, value) => {
  const updatedVariants = [...requestData.variants];
  updatedVariants[index][key] = value;

  setRequestData({ ...requestData, variants: updatedVariants });
};

const getVarients = async () => {
  try {
    const options = {
      type : "",
      condition : { 
        vendorData : requestData?.vendor,
        productData : requestData?.productId?._id,
        store : requestData?.storeId?._id,
        branch : requestData?.branchId?._id
      },
      select    : { unit : true, qty : true, rate : true, discount : true },
      sort      : {"_id" : -1},
      skip      : 0,
      limit     : 0
    }
    const listData = await list(options);
    if(listData?.status === true){
      setAvailableVariants(listData?.result);
    }else{
      setAvailableVariants([]);
    }
  } catch (error) {
    setAvailableVariants([]);
  }
};

useEffect(()=>{
  getVarients();
},[requestPopUp]);
  return (
    <Dialog
      open={requestPopUp}
      onClose={setRequestPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Product Request
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <img src={`${ASSETS_BASE_URL}${requestData?.productId?.image}`} width={`100px`} />
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                      <label className="categories_open_popup_label_name"> {requestData?.productId?.name} </label><br/>
                      <label className="categories_open_popup_label_name"> Category : {requestData?.productId?.category?.name} </label> <br/>
                      <label className="categories_open_popup_label_name"> Sub Category : {requestData?.productId?.subcategory?.name} </label> <br/>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      {showMore === true ? (<label> {requestData?.productId?.details} {requestData?.productId?.details?.length > 256 ?(<Link to={false} onClick={()=>setShowMore(false)}>View less</Link>):('')} </label>):(<label> {`${requestData?.productId?.details.substring(0,256)}`} {requestData?.productId?.details?.length > 256 ?(<Link to={false} onClick={()=>setShowMore(true)}>View more</Link>):('')} </label>)}
                      
                    </div>
                    <hr/>
                    {availavleVariants?.length > 0 ? (<>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label> Available Varieants : </label>
                      </div>
                        {availavleVariants.map((item, index)=>(
                          <div key={index} class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label> {index + 1}. Unit : {item?.unit} {` | `} </label>
                            <label> Price : AED {item?.price} {` | `} </label>
                            <label> Qty : {item?.qty} {` | `} </label>
                            <label> Discount (%) : {item?.discount?item.discount:0} </label>
                          </div>
                        ))}
                        <hr/>
                    </>):("")}

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label> Store Name : {requestData?.storeId?.shop_name} </label><br/>
                      <label> Phone : {requestData?.storeId?.phone} </label> <br/>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label> Address : {requestData?.storeId?.street}, {requestData?.storeId?.city}, {requestData?.storeId?.address} </label>
                      <label> Email : {requestData?.storeId?.email} </label> <br/>
                    </div>
                    <hr/>
                    {/* <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label> Branch Name : {requestData?.branchId?.branch_name} </label><br/>
                      <label> Phone : {requestData?.branchId?.phone} </label> <br/>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label> Address : {requestData?.branchId?.street}, {requestData?.branchId?.city}, {requestData?.branchId?.address} </label>
                      <label> Email : {requestData?.branchId?.email} </label> <br/>
                    </div>
                    <hr/> */}

                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">  
                      <label className="categories_open_popup_label_name" > Requested Varients : </label>
                    </div>
                      
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">  
                      {/* <input type="text" name="unit" id="unit" class="form-control categories_select_tg_enter_product" placeholder="Unit" /> */}
                      {variants()}
                    </div>
                    <hr/>


                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        class="btn btn-light "
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        class="btn btn-danger "
                      >
                        Save & Complete
                      </button>
                    </div>
                  </div>
                  
                </form>
              </div>
            </div>
          </Typography>
          {/* <DialogActions>
          <Button color="primary">Cancel</Button>
          <Button style={{ color: "red" }}>Delete</Button>
        </DialogActions> */}
        </div>
      </div>
    </Dialog>
  );
};

export default ViewRequestPopUp;
