import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../../DashBaord/Top_navbar";
import VendorListDeletePopUp from "./VendorListDeletePopUp";
import { Link, useNavigate } from "react-router-dom";
import "../UserList/UserList.css";

import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import {
  list,
  chnageStatus,
} from "../../../controllers/vendors/vendorsController";
import { getPage, statusMessage } from "../../../controllers/common";

import { FaPlusSquare } from "react-icons/fa";

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  } 

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");

  const handleEdit = async (item = {}) => {
    navigate("/vendors/addeditdata", { state: item });
  };
  /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: {
          ...(showRequest ? { status: showRequest } : null),
        },
        ...(filter?{filter:filter}:null),
        select: {},
        sort: { _id: -1 },
        skip: SKIP ? SKIP : 0,
        limit: LIMIT ? LIMIT : 10,
      };
      console.log('vendors options',options);
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
          setIsLoading(false);
        } else {
          // setListData([]);
          setIsLoading(false);
        }
        const countOptions = {
          type: "count",
          condition: {
            ...(showRequest ? { status: showRequest } : null),
          },
          ...(filter?{filter:filter}:null)
        };
        const count = await list(countOptions);
        setTotalPages(getPage(count?.result));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  /*********************************************************
   *  This function is use to handle remove vendor
   *********************************************************/
  const handleStatusChnage = async (status = "", id) => {
    try {
      setListData([]);
      const options = {
        url: `accounts/vendors/changestatus`,
        postData: {
          user_id: id,
          status: status,
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "Data updated.",
          description: `Data updated Successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };
  /*********************************************************
   *  This function is use to handle view all store
   *********************************************************/
  const handleViewAllStore = async (item = {}) => {
    navigate("/store/list", { state: item });
  };



  const dropdownMenu = (items) => {
    return (
      <>
        <Menu>
          <Menu.Item key="0" onClick={() => handleEdit(items)}>
            <FaEdit />
            <span className="show_span_edit">Edit</span>
          </Menu.Item>
          <Menu.Item key="1" onClick={() => handleViewAllStore(items)}>
            <FaEye />
            <span className="show_span_edit">All Store</span>
          </Menu.Item>
          {items?.status === "I" && (
            <Menu.Item
              key={`02`}
              onClick={() => {
                handleStatusChnage("A", items._id);
              }}
            >
              <LikeOutlined /> <span className="show_span">Active</span>
            </Menu.Item>
          )}
          {items?.status === "A" && (
            <Menu.Item
              key={`02`}
              onClick={() => {
                handleStatusChnage("I", items._id);
              }}
            >
              <DislikeOutlined /> <span className="show_span">Inactive</span>
            </Menu.Item>
          )}
        </Menu>
      </>
    );
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }))
      // console.log('filter',filter);
      // alert('submit');
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }

  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "Frisbee Super Admin || Vendors List";
  }, [currentPage, showRequest, popUpOpen, filter]);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Vendor's List"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                    <Tab
                      label="Active"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => setShowRequest("A")}
                    />
                    <Tab
                      label="Inactive"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => setShowRequest("I")}
                    />
                  </Tabs>
                </div>

                <div className="fa_filter_rating_review filter_img_and_add_btn_main">
                  <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt="filter"
                      className="user_iconses"
                    />
                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filters
                    </Link>
                  </div>
                  <div onClick={() => handleEdit()}>
                    <button type="button" class="btn btn-dark">
                      <FaPlusSquare className="Promotions_faPlus_Squares" />
                      <span className="request_new_categories">Add New</span>
                    </button>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <VendorList 
              //value={value} index={0}
              >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    {/* <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        All Vendor's List
                      </h6>
                    </div> */}
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Account Created On</th>
                              <th>Contact No.</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index} onDoubleClick={() => handleViewAllStore(items)}>
                                    <td>
                                      <div className="admin_user_list_table">
                                        <div>
                                          {items?.vendor_image ? (
                                            <img
                                              src={`${ASSETS_BASE_URL}${items.vendor_image}`}
                                              alt={items?.vendor_image}
                                              width="50px"
                                            />
                                          ) : (
                                            <img
                                              src={`../images/IntentoryTableLogo.png`}
                                              alt={`profile-pic`}
                                              width="30px"
                                            />
                                          )}
                                        </div>
                                        <div className="admin_user_list_name_gmail">
                                          <span className="admin_user_list_name">
                                            {`${items?.vendor_first_name} ${items?.vendor_last_name}`}
                                          </span>
                                          <span className="admin_user_list_gmail">
                                            {items?.vendor_email}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="admin_user_list_date">
                                      {moment(`${items?.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}{" "}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {" "}
                                      {items?.vendor_phone}{" "}
                                    </td>
                                    <td
                                      className="admin_user_list_date"
                                      dangerouslySetInnerHTML={{
                                        __html: statusMessage(items.status),
                                      }}
                                    ></td>
                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={5} /> : <tr> 
                                <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
            {/* Second Inventory */}
            {/* <VendorList value={value} index={1}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Inventory
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Account Created On</th>
                              <th>Contact No.</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      <div className="admin_user_list_table">
                                        <div>
                                          {items?.vendor_image ? (
                                            <img
                                              src={`${ASSETS_BASE_URL}${items.vendor_image}`}
                                              alt={items?.vendor_image}
                                              width="50px"
                                            />
                                          ) : (
                                            <img
                                              src={`../images/IntentoryTableLogo.png`}
                                              alt={`profile-pic`}
                                              width="30px"
                                            />
                                          )}
                                        </div>
                                        <div className="admin_user_list_name_gmail">
                                          <span className="admin_user_list_name">
                                            {`${items?.vendor_first_name} ${items?.vendor_last_name}`}
                                          </span>
                                          <span className="admin_user_list_gmail">
                                            {items?.vendor_email}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="admin_user_list_date">
                                      {moment(`${items?.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}{" "}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.vendor_phone}
                                    </td>

                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <SkeltonList row={10} col={4} />
                            )}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={50} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList> */}
            {/* Third Inventory */}
          </Box>
        </div>
        <VendorListDeletePopUp
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
      </div>
      {/* Filter */}
      <Drawer
        title="Vendor Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }} >
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='vendor_first_name' selected={filter?.filter_by ==='vendor_first_name'?true:false}>First Name</option>
                  <option value='vendor_last_name' selected={filter?.filter_by ==='vendor_last_name'?true:false}>Last name</option>
                  <option value='vendor_email' selected={filter?.filter_by ==='vendor_email'?true:false}>Email</option>
                  <option value='vendor_phone' selected={filter?.filter_by ==='vendor_phone'?true:false}>Phone</option>
                  <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"  
                  ref={inputRef2}
                  // onChange={handleFilterChnage} 
                  // value={filter?.search}             
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field"
                  id="from_date"      
                  ref={inputRef3}
                  // onChange={handleFilterChnage}          
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field"
                  id="to_date"   
                  ref={inputRef4}
                  // onChange={handleFilterChnage}             
                />
              </div>
              <br/>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black"    
                  style={{ backgroundColor: "black", color: "white" }}           
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
