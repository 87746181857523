import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";

const OrderConformatuinPopUp = ({ popUpOpen, togglePopUp, handleOrderDecline }) => {
  const handleCancel = () => {
    togglePopUp();
  };
  const [error, setError] = useState('');
  const [reason, setreason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const handleconform = () => {
    if (reason === '') {
      setError((pre) => ({
        ...pre,
        reason: "Reason is required."
      }))
    } else if(reason === 'Other' && otherReason === ''){
      setError((pre) => ({
        ...pre,
        reason: "Reason is required."
      }))
    } else {
      let cancelReason = reason;
      if(reason === 'Other'){
        cancelReason = otherReason;
      }
      handleOrderDecline(cancelReason);
      handleCancel();
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setreason(value);
    setError("");
  }

  const handleOtherReason = (e) => {
    setOtherReason(e.target.value);
    setError("");
  }

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Select a reason to decline.</p>
          <div className="please_a_reason">
            <div>
              <select name="reason" id="reason" class="form-control categories_select_tg_enter_product" onChange={handlechange} >
                <option value="">Select reason</option>
                <option value="Incomplete Payment" >Payment issues</option>
                <option value="Pricing mistakes" >Pricing mistakes</option>
                <option value="Product not available">Out of stock</option>
                <option value="Delivery area out of range">Delivery area out of range</option>
                <option value="Other">Other</option>
              </select>
              {reason === 'Other' && (
                <input type="text" name="otherReason" id="otherReason" class="form-control categories_select_tg_enter_product" placeholder="Enter you reason here..." onChange={handleOtherReason} />
              )}
            </div>
            {error?.reason ? (<p style={{ color: 'red' }}>{error.reason}</p>) : ""}
          </div>

        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleconform} style={{ color: "red" }}>
            Yes
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default OrderConformatuinPopUp;
