import React, { useEffect, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { list } from "../../../controllers/bookings/groomingControllers";
import moment from "moment";
import { Progress } from 'antd';

const OrderContentDelete = ({ popUpOpen, togglePopUp }) => {
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const [isGenerated, setIsGenerated] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [percentage, setPercentage] = useState(20);
    const [ csvData, setCSVData] = useState([]);

    const [filter, setFilter] = useState({
        from: "",
        to: "",
        filter_by: "",
        search: "",
      });

    const handleClose = () => {
        try {
            setIsGenerated(false);
            inputRef1.current.value = '';
            inputRef2.current.value = '';
            inputRef3.current.value = '';
            inputRef4.current.value = '';
            togglePopUp();
        } catch (error) {
            
        }
    }

    const handleGenerate = async (e) => {
        try {
            e.preventDefault();
            setInProgress(true);
            setPercentage(0); // Initialize progress
    
            const form = new FormData(e.target);
            setFilter((pre) => ({
                ...pre,
                'filter_by': form.get('filter_by') || '',
                'search': form.get('search') || '',
                'to': form.get('to_date') || '',
                'from': form.get('from_date') || '',
            }));
    
            const options = {
                type: 'count',
                condition: {},
                filter: {
                    filter_by: form.get('filter_by') || '',
                    search: form.get('search') || '',
                    to: form.get('to_date') || '',
                    from: form.get('from_date') || '',
                },
                skip: 0,
                limit: 0
            };
    
            const res = await list(options);
            if (res.status === true) {
                setTotalDataCount(res.result);
                let totalCount = res.result;
                let batchCount = Math.ceil(totalCount / 100);
                let currentBatch = 0;
    
                if (totalCount <= 100) {
                    const dataWhere = {
                        type: '',
                        condition: {},
                        filter: {
                            filter_by: form.get('filter_by') || '',
                            search: form.get('search') || '',
                            to: form.get('to_date') || '',
                            from: form.get('from_date') || '',
                        },
                        skip: 0,
                        limit: 0
                    };
                    const resData = await list(dataWhere);
                    if (resData.status === true) {
                        // console.log('resData',resData?.result);
                        const formatData = await formatOrderData(resData?.result);
                        setCSVData(formatData);
                    }
                    setPercentage(100); // Complete progress
                } else {
                    let allData = [];
                    for (let i = 0; i < batchCount; i++) {
                        const dataWhere = {
                            type: '',
                            condition: {},
                            filter: {
                                filter_by: form.get('filter_by') || '',
                                search: form.get('search') || '',
                                to: form.get('to_date') || '',
                                from: form.get('from_date') || '',
                            },
                            skip: i * 100,
                            limit: 100
                        };
                        const resData = await list(dataWhere);
                        if (resData.status === true) {
                            allData = [...allData, ...resData.result];
                        }
                        // Update percentage based on progress
                        setPercentage(Math.round(((i + 1) / batchCount) * 100));
                    }
                    const formatData = await formatOrderData(allData);
                    setCSVData(formatData);
                    setPercentage(100); // Complete progress
                }
            }
            setIsGenerated(!isGenerated);
            setInProgress(false);
        } catch (error) {
            console.log('error', error);
            setInProgress(false);
            setPercentage(0); // Reset on error
        }
    };
    
    useEffect(()=>{
        setIsGenerated(false);
    },[popUpOpen])


    const formatOrderData = (orders) => {
        console.log("orders", orders)
        if(orders && orders?.length > 0){
            return orders.map(order => ({
                booking_seq_id    : order.booking_seq_id || "",
                user_name       : order.userData ? order.userData.name : "",
                user_email      : order.userData ? order.userData.email : "",
                user_phone      : order.userData ? order.userData.phone : "",
                store_name      : order.shopId ? order.shopId.shop_name : "",
                store_email     : order.shopId ? order.shopId.email : "",
                store_phone     : order.shopId ? order.shopId.phone : "",
                
                service         : order.bookingdetails[0] ? order.bookingdetails[0].services.name:"",
                amount          : order.total ? order.total : "",
                service_type    : order.servive_type ? order.servive_type : "",
                appointment_date  : order.appointment_date ? moment(order.appointment_date).format('YYYY-MM-DD') : "",
                appointment_time  : order.appointment_time ? order.appointment_time : "",
                
                payment_mode    : order.paidBy || "",
                payment_status  : order.paymentStatus || "",
                date           :  moment(order.createdAt).format('YYYY-MM-DD') || ""
                
            }));
        } else{
            return {};
        }
    }

    // console.log("formatOrderData", formatOrderData)
  const headers = [
    { label: "Booking ID", key: "booking_seq_id" },
    { label: "User Name", key: "user_name" },
    { label: "User Email", key: "user_email" },
    { label: "User Phone", key: "user_phone" },
    { label: "Store Name", key: "store_name" },
    { label: "Store Email", key: "store_email" },
    { label: "Store Phone", key: "store_phone" },
    { label: "Service", key: "service" },
    { label: "Amount", key: "amount" },
    { label: "Service Type", key: "service_type" },
    { label: "Appointment Date", key: "appointment_date" },
    { label: "Appointment Time", key: "appointment_time" },
    { label: "Patment Mode", key: "payment_mode" },
    { label: "Patment Status", key: "payment_status" },
    { label: "Date", key: "date" }
  ];

  const conicColors = {
    '0%': '#87d068',
    '50%': '#ffe58f',
    '100%': '#ffccc7',
  };

  return (
    <Dialog open={popUpOpen} onClose={handleClose} maxWidth="md" PaperProps={{ className: "myDialogPopUp", }} >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography variant="h5" component="div" gutterBottom className="myFilterClass" > Export to Excel </Typography>
          </div>
          <div>
            <IconButton onClick={handleClose}> <IoMdClose /> </IconButton>
          </div>
        </div>
        {inProgress === false ? (
            <Typography variant="body1" component="div" className="my_delete_popup_paragraph" >
                <form id='filter_form' onSubmit={handleGenerate}>
                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" > Search Field : </label>
                            <select name="filter_by" className="form-control all_input_tag_tagenter_product_" id="filter_by" ref={inputRef1}>
                                <option value=''>Select search field</option>
                                <option value='store_name' selected={filter?.filter_by ==='store_name'?true:false}>Store Name</option>
                                <option value='store_phone' selected={filter?.filter_by ==='store_phone'?true:false}>Store Phone</option>
                                <option value='store_email' selected={filter?.filter_by ==='store_email'?true:false}>Store Email</option>
                                <option value='booking_seq_id' selected={filter?.filter_by ==='booking_seq_id'?true:false}>Booking ID</option>
                                <option value='servive_type' selected={filter?.filter_by ==='servive_type'?true:false}>Service Type</option>
                                <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option>
                            </select>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                            Search Text :
                            </label>
                            <input
                            title="Enter search text."
                            placeholder="Enter search text."
                            type="text"
                            name="search"
                            className="form-control all_input_tag_tagenter_product_"
                            id="search"  
                            ref={inputRef2}
                            />
                        </div>

                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                            From :
                            </label>
                            <input
                            title="Enter search text."
                            type="date"
                            name="from_date"
                            className="form-control all_input_tag_tagenter_product_"
                            id="from_date"     
                            ref={inputRef3}      
                            />
                        </div>

                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                            To :
                            </label>
                            <input
                            title="Enter search text."
                            type="date"
                            name="to_date"
                            className="form-control all_input_tag_tagenter_product_"
                            id="to_date"  
                            ref={inputRef4} 
                            />
                        </div>
                        <br/>
                        {isGenerated === false?(
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <button title="Enter search text." type="submit" className="form-control btn btn-black all_top_and_bottom_for_input_text" style={{ backgroundColor: "black", color: "white" }}>Generate</button>
                            </div>
                        ):(
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <CSVLink className="form-control btn btn-success " data={csvData} headers={headers} filename={`order-data-${moment().format('YYYY-MM-DD H:mm:s')}.csv`}> Download </CSVLink>
                            </div>
                        )}

                    </div>
                </form>
            </Typography>
        ):(
            <Typography style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh', // Adjust height as needed
            }}>
                <Progress type="dashboard" percent={percentage} strokeColor={conicColors} />
            </Typography>
        )}

      </div>
    </Dialog>
  );
};

export default OrderContentDelete;
