import { API_ADMIN_URL } from "../../config/constants";
import { postRequest, fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : Get list of all service category
* By            : Md RAshid Ali
* Date          : 26-02-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : chnageStatus
* Description   : chnage service status
* By            : MD RAshid Ali
* Date          : 26-02-2024 
*********************************************************/
export const chnageStatus = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : sessionStorage.getItem('IP_ADDRESS')
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : addEditData
* Description   : Add/Edit service category
* By            : MD RAshid Ali
* Date          : 26-06-2024 
*********************************************************/
export const addEditData = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : postData
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : deleteCategory
* Description   : delete service category
* By            : MD RAshid Ali
* Date          : 01-02-2024 
*********************************************************/
export const deleteProduct = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : sessionStorage.getItem('IP_ADDRESS')
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : addEditData
* Description   : Add/Edit service category
* By            : MD RAshid Ali
* Date          : 26-06-2024 
*********************************************************/
export const bulkUpload = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/bulk-product-upload`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function