import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Drawer, Button, Space  } from "antd";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { FaPlusSquare, FaEdit, FaTimes, FaCheck } from "react-icons/fa";
import { Select, notification } from "antd";
import "./Promotions.css";
import PromotionsAddEditPopUp from "./AddEditPopUp";
import PromotionsDeletePopUp from "./PromtionsDeletePopUp";
import Top_Navbar from "../DashBaord/Top_navbar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import {
  list,
  chnageStatus,
} from "../../controllers/promocode/promocodeController";
import { getPage, statusMessage } from "../../controllers/common";

function Promotions(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Promotions.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [promotionsAddEditPopUpmodal, setAddEditPromoCode] = useState(false);
  const promotionaToggleAddEditPopUp = () => {
    setAddEditPromoCode(!promotionsAddEditPopUpmodal);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /************************************************************************************* */
  const [addEditData, setAddEditData] = useState("");
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End
  const [total_active, setTotalActive] = useState(0);
  const [total_inactive, setTotalinactive] = useState(0);

  const [showRequest, setShowRequest] = useState("");

  const handleEdit = async (item = {}) => {
    navigate("/vendors/addeditdata", { state: item });
  };
  /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    setListData([]);
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        ...(showRequest ? { status: showRequest } : null),
      },
      ...(filter?{filter: filter}: null),
      select: {},
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    if(listData.status === true){
      if(listData.result.length > 0){
        setIsLoading(false);
        setTotalActive(listData?.count?.active);
        setTotalinactive(listData?.count?.inactive);
        setListData(listData?.result);
      } else{
        setListData([{booking_seq_id : 'No Data Found'}]);  
      }
      const countOptions = {
        type : "count",
        condition : {...(showRequest?{status : showRequest}:null) },
        ...(filter?{filter:filter}:null)
      };
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result)); 
      setIsLoading(false);
    }else{
      // setListData([{booking_seq_id : 'No Data Found'}]);
      setIsLoading(false);
    }
  };//End
  //   if (listData.status === true) {
  //     if (listData.result.length > 0) {
  //       setListData(listData?.result);
  //       setTotalPages(getPage(listData?.count?.total || 0));
  //       setTotalinactive(listData?.count?.inactive || 0);
  //       setTotalActive(listData?.count?.active || 0);
  //     } else {
  //       setListData([{ title: "No Data Found" }]);
  //       setTotalPages(getPage(0));
  //       setTotalinactive(0);
  //       setTotalActive(0);
  //     }
  //   } else {
  //     setListData([{ title: "No Data Found" }]);
  //   }
  // }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  /*********************************************************
   *  This function is use to handle chnage status
   *********************************************************/
  const handleStatusChnage = async (status = "", id) => {
    try {
      setListData([]);
      // isLoading(false);
      const options = {
        id: id,
        status: status,
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "Data updated.",
          description: `Data updated Successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };

  const [isFilterShow, setFilterShow] = useState(false);
const handleFiterDrawer = () => {
  setFilterShow(!isFilterShow);
} 
 /*********************************************************
 *  This function is ued for handle filter input chnage
 *********************************************************/
 const handleFilterReset = () => {
  try {
    setFilter('');
    inputRef1.current.value = '';
    inputRef2.current.value = '';
    inputRef3.current.value = '';
    inputRef4.current.value = '';
  } catch (error) {
    
  }
}

const handleFilterApply = (e) => {
  try {
    e.preventDefault();
    const form = new FormData(e.target);
    setFilter((pre)=>({
      ...pre,
      'filter_by' : form.get('filter_by') || '',
      'search'    : form.get('search') || '',
      'to'        : form.get('to_date') || '',
      'from'      : form.get('from_date') || '',
    }))
    handleFiterDrawer();
    setSkip(0);
  } catch (error) {
    
  }
}

  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Frisbee Super Admin || Promo Code List";
  }, [currentPage, showRequest, promotionsAddEditPopUpmodal, filter]);

  const dropdownMenu = (item) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setAddEditData(item);
          promotionaToggleAddEditPopUp();
        }}
      >
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      {item?.status === "A" ? (
        <Menu.Item key="2" onClick={() => handleStatusChnage("I", item._id)}>
          <FaTimes /> <span className="show_span">Inactive</span>
        </Menu.Item>
      ) : (
        <Menu.Item key="2" onClick={() => handleStatusChnage("A", item._id)}>
          <FaCheck /> <span className="show_span">Active</span>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_Navbar title={`Promo Code`}></Top_Navbar>
        <div className="promotions_card_class">
          <div className="row">
            <div className="col-sm-6">
              <div className="card promotion_card_child_class">
                <div className="card-body promotion_card_body">
                  <div>
                    <h5 className="card-title promotion_Active_promocodes">
                      Active promocodes
                    </h5>
                    <p className="card-text promotions_represents">
                      Represents Active Promocodes available recently.
                    </p>
                    <a
                      href="#"
                      style={{ color: "rgba(48, 194, 35, 1)" }}
                      className="promotion_num"
                    >
                      {total_active}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card promotion_card_child_class">
                <div className="card-body promotion_card_body">
                  <div>
                    <h5 className="card-title promotion_Active_promocodes">
                      InActive promocodes
                    </h5>
                    <p className="card-text promotions_represents">
                      Represents InActive Promocodes available recently.
                    </p>
                    <a
                      className="promotion_num"
                      href="#"
                      style={{ color: "rgba(225, 37, 27, 1)" }}
                    >
                      {total_inactive}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                    <Tab
                      label="Active"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => setShowRequest("A")}
                    />
                    <Tab
                      label="Inactive"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => setShowRequest("I")}
                    />
                  </Tabs>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Promotions>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="promotions_table_heading">
                      <div>
                        <h6 className="m-0 promotions_heading">
                          Promocodes list
                        </h6>
                      </div>
                      <div className="promotions_filters_div">
                        <div>
                          <h6 className="m-0 promotions_heading_filter">
                            <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                              <img
                                src={ASSETS_BASE_URL + "./images/Filter.svg"}
                                alt=""
                                className="user_iconses"
                              />

                              <Link
                                to={false}
                                className="filter_btn_invontry_rating_review"
                              >
                                Filters
                              </Link>
                            </div>
                          </h6>
                        </div>
                        <div>
                          <button
                            type="button"
                            class="btn btn-dark"
                            onClick={promotionaToggleAddEditPopUp}
                          >
                            <FaPlusSquare className="Promotions_faPlus_Squares" />
                            Add New
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>Discount</th>
                              <th>Valid Date & Time</th>
                              <th>Expiry Date & Time</th>
                              <th>Usage Limit</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr>
                                    <td>{items.promocode}</td>
                                    <td>Percentage ({items.discounts}%)</td>
                                    <td>
                                      {moment(`${items.valid_date}`).format(
                                        "DD/MM/YYYY"
                                      )}{" "}
                                      {moment(
                                        `2024-01-01 ${items.valid_time}:00`
                                      ).format("h:m A")}
                                    </td>
                                    <td>
                                      {moment(`${items.expire_date}`).format(
                                        "DD/MM/YYYY"
                                      )}{" "}
                                      {moment(
                                        `2024-01-01 ${items.expire_time}:00`
                                      ).format("h:m A")}
                                    </td>
                                    <td>{items.qty}</td>
                                    <td
                                      dangerouslySetInnerHTML={{
                                        __html: statusMessage(items.status),
                                      }}
                                    ></td>

                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                             ))) : isLoading ? <SkeltonList row={10} col={5} /> : <tr> 
                             <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                         </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Promotions>
          </Box>
        </div>
        <PromotionsDeletePopUp
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
        />
        <PromotionsAddEditPopUp
          promotionsAddEditPopUpmodal={promotionsAddEditPopUpmodal}
          promotionaToggleAddEditPopUp={promotionaToggleAddEditPopUp}
          addEditData={addEditData}
          setAddEditData={setAddEditData}
        />
      </div>
        {/* Filter */}
        <Drawer
        title="PromoCode Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }} >
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                    <option value='promocode' selected={filter?.filter_by ==='promocode'?true:false}>Promo Code</option>
                    <option value='discounts' selected={filter?.filter_by ==='discounts'?true:false}>Discount</option>
                    <option value='valid_date' selected={filter?.filter_by ==='valid_date'?true:false}>Valid Date</option>
                    <option value='expire_date' selected={filter?.filter_by ==='expire_date'?true:false}>Expire Date</option>
                    <option value='qty' selected={filter?.filter_by ==='qty'?true:false}>Usage Limit</option>
                    <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"  
                  ref={inputRef2}             
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field"
                  id="from_date"      
                  ref={inputRef3}        
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field"
                  id="to_date"   
                  ref={inputRef4}            
                />
              </div>
              <br/>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black"    
                  style={{ backgroundColor: "black", color: "white" }}           
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
