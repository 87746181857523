import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Progress, Upload, Flex, Button } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import { FaFileUpload } from "react-icons/fa";
import "../ServiceList.css";
import ExcelJS from 'exceljs';
import {ucfirst, commonList} from '../../../controllers/common';
import { blukUpload } from "../../../controllers/services/veterinaryController"
import { vendorListByService as vendorlist} from "../../../controllers/vendors/vendorsController"
import { list as serviceList } from "../../../controllers/categories/services"
import { Link } from "react-router-dom";
import { ASSETS_BASE_URL } from '../../../config/constants';
const conicColors = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#ffccc7',
};
const ProductListAddEdit = ({ show, setBulkUploadPopUp }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = (msg="") => {
    notification.open({
      message: "Opps!",
      description: error?.formError || msg,
      placement: "topRight",
      duration : 2,
      icon: <CloseOutlined  style={{color : "red"}} />,
    });
  };//End
  const [isLoading, setIsLoading] = useState(false);
  const [error, serError] = useState([]);
  const [success, serSuccess] = useState([]);
  const [formData, setFormData] = useState("");
  const [imageList, setImageList] = useState("");
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [ALLCATEGORY, setAllCategory] = useState([]);
  const [currentService, setCurrentService] = useState(''); 
  const [jsonData, setJsonData] = useState(null);
  const [progress, setProgress] = useState(0);
  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
  const handleCancel = () => {
    setFormData("");
    setImageList("");
    setBulkUploadPopUp(false);
    setJsonData('')
  };

/*********************************************************
  *  This function is use to get all vendor list
  *********************************************************/
  const getVendorList = async () => {
    try {
      const options ={
        category_id : currentService,
        condition : { status : "A"},
        select : {
          vendor_id : false, 
          vendor_email : false,
          creation_date : false,
          createdAt : false,
          creation_ip : false,
          vendor_address : false,
          vendor_city : false,
          vendor_country : false,
          vendor_image : false,
          vendor_otp : false,
          vendor_phone : false,
          vendor_pincode : false,
          vendor_state : false,
          created_by : false,
          update_date : false,
          update_ip : false,
          updatedAt : false,
          updated_by : false,
          vendor_commission : false,
          vendor_country_code : false,
          vendor_gender : false,
          vendor_revenue : false,
          vendor_street : false
        },
        sort : {vendor_first_name : 1},
      }
      const res = await vendorlist(options);
      if(res.status === true){
        setAllVendor(res?.result)
      }else{
        setAllVendor([]);
      }
    } catch (error) {
      setAllVendor([]);
    }
  };

  /*********************************************************
  *  This function is use to get service id
  *********************************************************/
  const getService = async () => {
    try {
      const options = {
        condition : { name : 'Veterinary' },
        type : "single",
        select : {name : true }
      }
      const currentService = await serviceList(options);
      if(currentService.status === true){
        setCurrentService(currentService?.result?._id);
        getVendorList();
      }
    } catch (error) {
      
    }
  }

  const getCommonListData = async (vendorId) => {
    try{
      setAllStore([]);
      setAllBranch([]);
      setAllCategory([]);
      const options ={
        vendorId : vendorId,
        list : ["service_category","store","branch"],
        serviceCat : { select : { name : true} },
        serviceSubCat : { select : { category : true, name : true } },
        store : { select : { shop_name : true } },
        branch : { select : { storeData : true, branch_name : true } }
      }
      const res =await commonList(options);
      if(res?.status === true){
        setAllStore(res?.result?.store);
        setAllBranch(res?.result?.branch);
        setAllCategory(res?.result?.serviceCat);
      }
      setIsLoading(false);
    } catch (error){
      setIsLoading(false);
    }
  }

  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e)=>{
    const {name, value} = e.target;
    if(name === 'vendor'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      getCommonListData(value);
    } else if(name === 'store'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      const filterArray = ALLBRANCH.filter(val=> val.storeData == value )
      setFilterBranch(filterArray);
    } else if(name === 'category'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
    } else{
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }))
    }
    serError((pre)=>({
      ...pre,
      [e.target.name] : ""
    }))
  }; //End
  
  /*********************************************************
  *  This function is use to handle image object
  *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj);
    const file = e.fileList[0].originFileObj;
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      await workbook.xlsx.load(buffer);

      const worksheet = workbook.worksheets[0];
      const rows = [];

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values.slice(1); // Remove the first empty value
        rows.push(rowValues);
      });

      // Convert rows to JSON (assuming first row is the header)
      const headers = rows[0];
      const data = rows.slice(1).map(row => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });
      setJsonData(data);
      console.log('data',data);
    };

    reader.readAsArrayBuffer(file);
  }
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e)=>{
    e.preventDefault();
    if(!formData?.vendor){
      handleErrorMsg('Please select vendor.');
    } else if(!formData?.store){
      handleErrorMsg('Please select store.');
    } else if(jsonData && jsonData?.length === 0){
      handleErrorMsg('No data found in file.');
    } else{
      const option = {
        vendor    : formData.vendor,
        store     : formData.store,
        branch    : ALLBRANCH[0]?._id,
        category  : currentService,
        data      : jsonData
      }
      const dataArray = await transformBulkData(option);
      
      if(dataArray && dataArray.length > 0){
        if (dataArray && dataArray?.length > 0) {
          for (let i = 0; i < dataArray.length; i += 10) {
              const batch = dataArray.slice(i, i + 10);
              const res = await blukUpload(batch);
              if (res.status !== true) {
                  notification.open({
                    message: "Oops!!",
                    description: `Error while uploading`,
                    placement: "topRight",
                    icon: <CheckCircleTwoTone twoToneColor="red" />,
                  });
                  break;
              }
              const progressPercentage = Math.round(((i + 10) / dataArray.length) * 100);
              setProgress(progressPercentage);
          }
          notification.open({
            message: "Success",
            description: "Upload Successfully.",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="green" />,
          });
          handleCancel();
        }
      } else{
        handleErrorMsg('No data found in file.');
      }
    }
  } //End

  const transformBulkData = async (option) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { vendor, store, branch, category, data } = option;
            let result = [];
            for (let i = 0; i < data.length; i += 10) {
                const batch = data.slice(i, i + 10);
                const transformedBatch = await Promise.all(batch.map(async (item) => {
                    return {
                      vendor: vendor,
                      store: store,
                      branch: branch,
                      category: category,
                      name: item?.name || '',
                      details : item?.details || 'N',
                      price: item?.price || '',
                      image : item?.image.text,
                      status : 'A'
                    };
                }));
                result = result.concat(transformedBatch);
            }
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
  };
  

  useEffect(()=>{
    getService();
    setProgress(0);
    setJsonData('')
  },[show]);
  return ( 
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              Bulk Upload Service
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="vendor" className="all_lable_for_vendor_dashbaord"> Vendor </label>
                      <select id="vendor" name="vendor" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a vendor</option>
                        {ALLVENDOR && ALLVENDOR.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id} >
                            {`${ucfirst(items.vendor_first_name)} ${ucfirst(items.vendor_last_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.vendor?<p style={{color:"red"}}>{error.vendor}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="store" className="all_lable_for_vendor_dashbaord"> Store </label>
                      <select id="store" name="store" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a store</option>
                        {ALLSTORE && ALLSTORE.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id} >
                            {`${ucfirst(items.shop_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.store?<p style={{color:"red"}}>{error.store}</p>:""}
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data inventory_upload_excel_section">
                      <label htmlFor="company_registration_document" className="all_lable_for_vendor_dashbaord">
                        Upload excel
                      </label>
                      <div
                        className="sign_up_file_upload_for_inventory"
                        id="company_registration_document"
                      >
                        <div className="company_registration_document_button">{imageList?.name ? imageList.name : 'Upload File'}</div>
                        <Upload 
                          id="company_registration_document"
                          fileList={[]}
                          onChange={handleImageChnage}
                        >
                          <Button icon={<FaFileUpload />}>{imageList?.name ? `Change` : 'Upload File'}</Button>
                        </Upload>
                      </div>
                        <Link to={`${ASSETS_BASE_URL}assets/veterinary-services-bulk-upload.xlsx`} className="bulk_upload_text" target="_blank">Download template</Link>
                    </div>
                  </div>
                  {progress === 0 ? (
                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-danger "
                    >
                      Submit
                    </button>
                  </div>
                 ):(
                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data inventory_upload_excel_section">
                    <Progress type="line" percent={progress} strokeColor={conicColors} />
                  </div>
                 )}
                </form>
              </div>
            </div>
          </Typography>
          {/* <Loading
            isLoading={isLoading}
          /> */}
        </div>
      </div>
    </Dialog>
  );
};

export default ProductListAddEdit;
