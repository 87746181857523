import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";

import { addEditData } from "../../../controllers/subcategories/products";
import "./List.css";

const AddEdit = ({ show, editdata, setEditData, setAddEditPopup, categories }) => {

    const handleBackdropClick = (event) => {
        event.stopPropagation();
      };
      
  const [formData, setFormData] = useState({
    categoryId: '',
    name: '',
    status: 'A',
  });
  const [imageList, setImageList] = useState('');
  const [error, serError] = useState({});

  useEffect(() => {
    setFormData({
      categoryId: editdata?.categoryId?._id || '',
      name: editdata?.name || '',
      status: 'A',
    });
  }, [editdata]);

  const handleCancel = () => {
    setFormData({ categoryId: '', name: '', status: 'A' });
    setImageList('');
    setEditData('');
    setAddEditPopup(false);
  };

  const handleChnage = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    serError((prev) => ({
      ...prev,
      [e.target.name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.categoryId) {
        serError((prev) => ({ ...prev, categoryId: 'Please select category.' }));
        return;
      }
      if (!formData.name) {
        serError((prev) => ({ ...prev, name: 'Please enter sub-category.' }));
        return;
      }

      const params = {
        url: `subcategories/products/addeditdata/${editdata?._id || ':editId'}`,
        postData: formData,
      };

      const res = await addEditData(params);
      if (res.status) {
        setFormData({ categoryId: '', name: '', status: 'A' });
        setImageList('');
        handleCancel();
        notification.success({
          message: "Success!",
          description: "Data saved successfully.",
        });
      } else {
        throw new Error(res?.message || 'Unknown error');
      }
    } catch (error) {
      serError((prev) => ({ ...prev, formError: error.message }));
      notification.error({
        message: "Oops!",
        description: error.message,
      });
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              {editdata?._id?"Edit":"Add"} Sub-Category
            </Typography>
          </div>
          <Typography variant="body1" component="div" className="my_delete_popup_paragraph" >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">

                  <div class="form-group">
                    <div class="form-group">
                      <label for="categoryId" className="categories_open_popup_label_name" >
                        Select Category
                      </label>
                      <select name="categoryId" id="categoryId" className="form-control categories_select_tg_enter_product" onChange={handleChnage}>
                        <option value="" >Select Category</option>
                        {categories && categories.map((item, index)=>(
                          <option value={item?._id}  selected={editdata?.categoryId?._id === item?._id ? true:false } > {item?.name}</option>
                        ))}
                      </select>
                      {error?.categoryId?<p style={{color:"red"}}>{error.categoryId}</p>:""}
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="cannedProducts"
                        className="categories_open_popup_label_name"
                      >
                        Sub Category
                      </label>
                      <input
                        type="text"
                        class="form-control categories_select_tg_enter_product"
                        id="cannedProducts"
                        placeholder="Enter Sub Category Name"
                        name="name"
                        value={formData?.name?formData.name:editdata?.name}
                        onChange={handleChnage}
                      />
                      {error?.name?<p style={{color:"red"}}>{error.name}</p>:""}
                    </div>
                  </div>
                  
                

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={handleSubmit}
                    >
                      {editdata._id?"Save Changes":'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>          
        </div>
      </div>
    </Dialog>
  );
};

export default AddEdit;
