import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Upload } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "../BannerList.css";
import { addEditData } from "../../../controllers/cms/banner"
import { beforeUpload } from '../../../controllers/common';

const BannerListAddEdit = ({ show, editdata, setEditData, setAddEditPopup }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
  const handleCancel = () => {
    setEditData("");
    setFormData("");
    setImageList("");
    setAddEditPopup(false);
  };
  /*********************************************************
  *  This function is use to handle success notification show
  *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  };//End

  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = () => {
    notification.open({
      message: "Opps!",
      description: error?.formError,
      placement: "topRight",
      icon: <CloseOutlined twoToneColor="#fc2339" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  };//End
  const [error, serError] = useState([]);
  const [formData, setFormData] = useState({
    status: "A"
  });
  const [imageList, setImageList] = useState("");
  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value
    }))

    setEditData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value
    }))

    serError((pre) => ({
      ...pre,
      [e.target.name]: ""
    }))
  }; //End

  /*********************************************************
  *  This function is use to handle image object
  *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj)
  }
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("page", formData?.page ? formData?.page : editdata?.page);
    form.append("order", formData?.order ? formData?.order : editdata?.order);
    form.append("image", imageList ? imageList : null);
    form.append("status", formData?.status ? formData?.status : editdata?.status);
    form.append("ipAddress", localStorage.getItem('IP_ADDRESS'));
    const isValidate = validateFormData(form)
    if (isValidate) {
      const options = {
        url : `cms/banner/addeditdata/${editdata?._id?editdata._id:':editId'}`,
        postData : form
      }
      const res = await addEditData(options);
      if (res.status === true) {
        setFormData("");
        setImageList("");
        handleSuccessMsg();
        handleCancel();
      } else {
        serError((pre) => ({
          ...pre,
          formError: res?.message
        }))
        handleErrorMsg();
        setFormData("");
        setImageList("");
        handleCancel();
      }
    }
  } //End

  /*********************************************************
  *  This function is use to validate form data before submit
  *********************************************************/
  const validateFormData = (formData) => {
    if (formData.get('page') === 'undefined' || formData.get('page') === null || formData.get('page').trim() === '') {
      serError((preError) => ({
        ...preError,
        page: "Page is required"
      }));
      return false;
    } else if (!editdata?._id && formData.get('image') === 'null') {
      serError((preError) => ({
        ...preError,
        image: "Image is required"
      }));
      return false;
    } else {
      return true;
    }
  }; //End

  return (
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              {editdata?._id ? "Edit" : "Add"} Banner
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">
                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="cannedProducts"
                        className="categories_open_popup_label_name"
                      >
                        Page Name
                      </label>
                      <input
                        type="text"
                        class="form-control categories_select_tg_enter_product"
                        id="page"
                        placeholder="Enter Page Name"
                        name="page"
                        value={formData?.page ? formData?.page : editdata?.page}
                        onChange={handleChnage}
                      />
                      {error?.page ? <p style={{ color: "red" }}>{error.page}</p> : ""}
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="formGroupExampleInput"
                        className="categories_open_popup_label_name"
                      >
                        Order  
                      </label>
                      <input
                        type="text"
                        class="form-control categories_select_tg_enter_product"
                        id="formGroupExampleInput"
                        placeholder="Enter Order"
                        name="order"
                        value={formData?.order ? formData?.order : editdata?.order}
                        onChange={handleChnage}
                      />
                      {error?.order ? <p style={{ color: "red" }}>{error.order}</p> : ""}
                    </div>
                  </div>


                  <div className="inventory_edit_page_popup">
                    <div class="form-group inventory_popup_create_promocodes_product_img">
                      <label
                        for="formGroupExampleInput"
                        className="categories_open_popup_label_name"
                      >
                        Image
                      </label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                          beforeUpload={beforeUpload}
                        >
                          <button className="inventroy_edit_page_antd_uplaod_button" type="button">
                            <div className="inventroy_edit_page_antd_choose_file">Choose File</div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{color:"blue"}} className="all_top_and_bottom_for_input_text">*Note: Please choose an image of JPEG/JPG/PNG format file.</p>
                      {error?.image?<p style={{ color: "red" }}>{error.image}</p> : ""}
                    </div>
                  </div>

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={handleSubmit}
                    >
                      {editdata._id ? "Save Changes" : 'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

export default BannerListAddEdit;
