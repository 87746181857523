import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Switch, notification, Drawer, Button, Space } from "antd";
import { FaEdit, FaPlusSquare, FaCubes, FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import AddQuantityPopUp from "./AddQuantityPopUp";
import InventoryDeletePopUp from "./InventoryDeletePopup";
import InventoryAddProductPopUp from "./InventoryAddProductPopUp";
import Top_navbar from "../DashBaord/Top_navbar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import {
  InfoCircleOutlined,
  CheckCircleTwoTone
} from "@ant-design/icons";
import LoadingEffect from '../../components/Loading/LoadingEffect';
import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import { list, varientChnageStatus } from "../../controllers/Products/inventory";
import { getPage, statusMessage } from "../../controllers/common";
import EditPopup from "./InventoryEditPopUp";

function Inventory(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Inventory.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // Add product PopUp
  const [inventoryAddProProductPopUp, setAddProductPopUpOpen] = useState(false);
  const inventoryToggleAddProductPopUp = () => {
    setAddProductPopUpOpen(!inventoryAddProProductPopUp);
  };
  //for PopUP modal
  const [inventoryPopUp, setPopUpOpenInventory] = useState(false);
  const AddQuantityTogglePopUp = () => {
    setPopUpOpenInventory(!inventoryPopUp);
  };
  // Delete popup
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  //Handle Filter show
  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  } 

  // Edit popup
  const [editPopUpOpen, setEditPopUpOpen] = useState(false);
  const toggleEditPopUp = () => {
    setEditPopUpOpen(!editPopUpOpen);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /************************************************************************************* */
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading_status, setIsLoading_status] = useState(false);
  const [variantId, setVariantId ] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [EDITDATA, setEditData] = useState([]);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from : '',
    to : new Date(),
    filter_by : "",
    search : ""
  })//End

  const [showRequest, setShowRequest] = useState("");
  const [showOutOfStock, setShowOutOfStock] = useState(false);
  const [isStatusChange, setIsStatusChange] = useState(true);
  
  const handleEdit = async (item={}) =>{
    navigate('/vendors/addeditdata',{state : item});
  }
  /*********************************************************
  *  This function is use to fetch vendor list
  *********************************************************/
  const getList = async ()=>{
    try {
      setListData([]);
      setIsLoading(true);
      const options = {
        type : "",
        condition : { 
          ...(showRequest?{status : showRequest}:null),
          ...(showOutOfStock?{qty : {$lt : 1}}:null)
        },
        ...(filter?{filter:filter}:null),
        select    : { },
        sort      : {"_id" : -1},
        skip      : SKIP?SKIP:0,
        limit     : LIMIT?LIMIT:10
      }
      const listData = await list(options);
      if(listData.status === true){
        setIsLoading(false);
        if(listData.result.length > 0){
          setListData(listData?.result);
        } 
        const countOptions = {
          type : "count",
          condition : {
            ...(showRequest?{status : showRequest}:null),
            ...(showOutOfStock?{qty : {$lt : 1}}:null)
          },
          ...(filter?{filter:filter}:null),
        }
        const count = await list(countOptions);
        setTotalPages(getPage(count?.result)); 
      }else{
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };//End

  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }));
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }

  /*********************************************************
  *  This function is use for change inventory status
  *********************************************************/
  const handleChangeStatus = async (id, status = '') => {
    try{
      setIsLoading_status(true);
      if(!id){
        notification.open({
          message: "Opps!!",
          description: `Id not found`,
          placement: "topRight",
          icon: <InfoCircleOutlined twoToneColor="red" />,
        });
        // setIsLoading_status(false);
      } else if(!status){
        notification.open({
          message: "Opps!!",
          description: `Status not found`,
          placement: "topRight",
          icon: <InfoCircleOutlined twoToneColor="red" />,
        });
        // setIsLoading_status(false);
      } else{
        const res = await varientChnageStatus({id, status})
        if(res.status === true){
          setIsStatusChange(!isStatusChange);
          // setIsLoading_status(false);
          notification.open({
            message: "Success",
            description: `Status updated successfully`,
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="green" />,
          });
        } else{
          // setIsLoading_status(false);
          notification.open({
            message: "Opps!!",
            description: `Status not updated`,
            placement: "topRight",
            icon: <InfoCircleOutlined twoToneColor="red" />,
          });
        }
      }
      setIsLoading_status(false);
    } catch(error){
      setIsLoading_status(false);
      notification.open({
        message: "Opps!!",
        description: `Under Maintenance, Please try after some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined twoToneColor="red" />,
      });
    }
  }
  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(()=>{
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = 'Frisbee Super Admin || Inventry List'
  },[currentPage,showRequest, popUpOpen,editPopUpOpen, inventoryPopUp, filter, showOutOfStock, isStatusChange]);

  const dropdownMenu = (item) => (
    <Menu>
      <Menu.Item key="1" onClick={()=>{
        setVariantId(item._id)
        AddQuantityTogglePopUp()
        }}>
        <FaCubes />
        <span className="show_span_edit">Add Quantity</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={()=>{
        setEditData(item)
        toggleEditPopUp()
        }}>
        <FaEdit /> <span className="show_span">Edit</span>
      </Menu.Item>
      {item.status !== 'A'?(
        <Menu.Item key="3" onClick={()=>handleChangeStatus(item._id, 'A')}>
          <FaRegThumbsUp /> <span className="show_span">Active</span>
        </Menu.Item>
      ):(
        <Menu.Item key="4" onClick={()=>handleChangeStatus(item._id, 'I')}>
          <FaRegThumbsDown /> <span className="show_span">Inactive</span>
        </Menu.Item>
      )}

    </Menu>
  );

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_navbar title={`Inventory List`}></Top_navbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      onClick={()=>{
                        setShowRequest('');
                        setShowOutOfStock(false);
                      }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Out of stock"
                      className="tabs_main_contnet"
                      onClick={()=>setShowOutOfStock(true)}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review">
                  <div className="inventory_filter_div"  onClick={handleFiterDrawer}>
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />
                    <a href="#" className="filter_btn_invontry_rating_review">
                      Filters
                    </a>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Inventory>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 inventory_vendor_add_new_btn all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Inventory
                      </h6>
                      <div>
                        <button
                          type="button"
                          class="btn btn-dark"
                          onClick={inventoryToggleAddProductPopUp}
                          // onClick={togglePopUp}
                        >
                          <FaPlusSquare className="Promotions_faPlus_Squares" />
                          <span className="request_new_categories">
                            Add New
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Store</th>
                              <th>Category</th>
                              <th>Sub Category</th>
                              <th>Unit</th>
                              <th>Quantity</th>
                              <th>Rate</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                          {ALLLISTDATA?.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>
                            <tr key={index} onDoubleClick={()=>{ setEditData(items); toggleEditPopUp(); }}>
                              <td width="100px">
                                <div className="admin_prodcut_list_span">
                                  <img
                                    src={`${ASSETS_BASE_URL}${items?.productData?.image}`}
                                    alt=""
                                  />
                                  <span className="admin_prodcut_list_span_name">
                                    {items?.productData?.name}
                                  </span>
                                </div>
                              </td>
                              <td>
                                  <span>{items?.store?.shop_name}</span><br/>
                                  <span>{items?.store?.phone}</span><br/>
                                  <span>{items?.store?.email}</span><br/>
                                  <span>{items?.store?.city}</span>
                              </td>
                              <td>{items?.productData?.category?.name}</td>
                              <td>{items?.productData?.subcategory?.name}</td>
                              <td>{items?.unit}</td>
                              <td>{items?.qty}</td>
                              <td>{items?.rate}</td>
                              <td dangerouslySetInnerHTML={{__html: statusMessage(items.status)}}></td>
                              <td>
                                <Dropdown
                                  overlay={()=>dropdownMenu(items)}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                          </>))) : isLoading ? <SkeltonList row={5} col={9} /> : <tr> 
                                <td colspan="9" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Inventory>
           
          </Box>
        </div>
        <InventoryAddProductPopUp
          inventoryAddProProductPopUp={inventoryAddProProductPopUp}
          inventoryToggleAddProductPopUp={inventoryToggleAddProductPopUp}
          editdata={[]}
        />
        <InventoryDeletePopUp popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        <AddQuantityPopUp
          inventoryPopUp={inventoryPopUp}
          AddQuantityTogglePopUp={AddQuantityTogglePopUp}
          variantId={variantId}
          setVariantId={setVariantId}

        />
        <EditPopup
          inventoryPopUp = {editPopUpOpen}
          inventoryTogglePopUp={toggleEditPopUp}
          addEditData={EDITDATA}
          setAddEditData={setEditData}
        />

        {/* Filter */}
        <Drawer
          title="Inventory Filter"
          placement={`right`}
          width={500}
          onClose={handleFiterDrawer}
          open={isFilterShow}
          extra={
            <Space>
              <Button onClick={handleFiterDrawer}>Cancel</Button>
              <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }} >
                Reset
              </Button>
            </Space>
          }
        >
          <div className="responsive">
            <div className="row">
              <form id='filter_form' onSubmit={handleFilterApply}>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                    Search Field :
                  </label>
                  <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                    <option value=''>Select search field</option>
                    <option value='product_name' selected={filter?.filter_by ==='product_name'?true:false} >Product Name</option>
                    <option value='store_name' selected={filter?.filter_by ==='store_name'?true:false}>Store Name</option>
                    <option value='store_phone' selected={filter?.filter_by ==='store_phone'?true:false}>Store Phone</option>
                    <option value='store_email' selected={filter?.filter_by ==='store_email'?true:false}>Store Email</option>
                    <option value='category' selected={filter?.filter_by ==='category'?true:false}>Category</option>
                    <option value='subcategory' selected={filter?.filter_by ==='subcategory'?true:false}>Sub-Category</option>
                    <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option>
                  </select>
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                    Search Text :
                  </label>
                  <input
                    title="Enter search text."
                    placeholder="Enter search text."
                    type="text"
                    name="search"
                    className="form-control store_input_field"
                    id="search"  
                    ref={inputRef2}
                    // onChange={handleFilterChnage} 
                    // value={filter?.search}             
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                    From :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="from_date"
                    className="form-control store_input_field"
                    id="from_date"      
                    ref={inputRef3}
                    // onChange={handleFilterChnage}          
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                    To :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="to_date"
                    className="form-control store_input_field"
                    id="to_date"   
                    ref={inputRef4}
                    // onChange={handleFilterChnage}             
                  />
                </div>
                <br/>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <button
                    title="Enter search text."
                    type="submit"
                    className="form-control btn btn-black"    
                    style={{ backgroundColor: "black", color: "white" }}          
                  >Apply</button>
                </div>
              </form>
            </div>
          </div>
        </Drawer>
        <LoadingEffect isLoading = {isLoading_status}/>
      </div>
    </>
  );
}
