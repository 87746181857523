import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import Loading from "../../components/Loading/LoadingEffect";
import { useLocation } from "react-router-dom";
import { ASSETS_BASE_URL } from "../../config/constants";

const InsuranceDetails = ({
  InsightsAddEditPopUpmodal,
  insightsToggleAddEditPopUp,
  addEditData,
  setAddEditData
}) => {
  const handleCancel = () => {
    // setAddEditData("");
    insightsToggleAddEditPopUp();
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  // const { state: addEditData } = useLocation(); // Use location to get the passed state
  const [loading, setLoading] = useState(false);
  console.log("addEditData", addEditData);

  useEffect(() => {
    if (!addEditData) {
      setLoading(true);
      // fetch data if addEditData is not available (for direct URL access)
      // setaddEditData(fetchedData);
      setLoading(false);
    }
  }, [addEditData]);

  return (
    <Dialog
      open={InsightsAddEditPopUpmodal}
      onClose={insightsToggleAddEditPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              View Insurance Details
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                {loading ? (
                  <Loading isLoading={loading} />
                ) : (
                  addEditData && (
                    <div className="form-group row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Owner Name
                      </label>
                      <p>{addEditData.name}</p>
                    </div>
                  
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Phone
                      </label>
                      <p>{addEditData.phone}</p>
                    </div>
                  
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Email
                      </label>
                      <p>{addEditData.email}</p>
                    </div>
                  
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Emirates Id
                      </label>
                      <p>{addEditData.emirates_id}</p>
                    </div>
                  
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Pets Name
                      </label>
                      <p>{addEditData.pets_name}</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Pets DOB
                      </label>
                      <p>{addEditData.pets_dob}</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Pets Breed
                      </label>
                      <p>{addEditData.pets_breed}</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Pets Gender
                      </label>
                      <p>{addEditData.pets_gender}</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Microchip Number
                      </label>
                      <p>{addEditData.microchipNumber}</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 insurance_owner_deatils">
                      <label className="categories_open_popup_label_name">
                        Image
                      </label>
                      <img
                        src={`${ASSETS_BASE_URL}${addEditData.image}`}
                        width="100px"
                        alt="Pet"
                      />
                    </div>
                  </div>
                  
                  )
                )}
                <div className="inventory_open_popup_action_btn">
                  <button
                    type="button"
                    className="btn btn-light categories_cancel_button"
                    onClick={handleCancel}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};


export default InsuranceDetails;
