import React, { useEffect, useState,useRef } from "react";
import ProfileTopNavBar from "./generalDataTopNavBar";
import {
  Collapse,
  Typography,
  Upload,
  notification,
  Switch,
  TimePicker,
} from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
// import { updateProfile } from "../../../controllers/accounts/Account";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { beforeUpload } from "../../../controllers/common";
import {
  getAdminInfo,
  updateProfile,
} from "../../../controllers/cms/generalData";

const { Panel } = Collapse;
const { Text } = Typography;

function StoreDetailsForm() {
  const targetRef = useRef();
  const [ADMININFO, setAdminInfo] = useState({});
  const [image, setImageList] = useState("");
  const navigate = useNavigate();
  const [errors, serError] = useState([]);

  useEffect(() => {
    const fetchAdminInfo = async () => {
      try {
        const data = await getAdminInfo();
        if (data.status && data.result.length > 0) {
          setAdminInfo(data.result[0]);
        }
      } catch (error) {
        console.error("Error fetching admin info:", error);
      }
    };

    fetchAdminInfo();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
     document.title = 'Frisbee Super Admin || General Data'
  }, []);

  const handleSuccessMessg = () => {
    notification.open({
      message: "Success!",
      description: "Data Saved Successfully.",
      placement: "topRight",
      duration: 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };

  const handleErrorMsg = (msg = "") => {
    notification.open({
      message: "Oops!",
      description: msg,
      placement: "topRight",
      duration: 2,
      icon: <CloseOutlined style={{ color: "red" }} />,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setImageList(e.fileList[0].originFileObj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = new FormData(e.target);
    if (ADMININFO?._id) {
      form.append("editId", ADMININFO?._id);
    }

    form.append("ipAddress", sessionStorage.getItem("IP_ADDRESS"));

    const isValidate = validateFormData(form);
    if (isValidate) {


      const rawData = {};
      form.forEach((value, key) => {
        rawData[key] = value;
      });

      const res = await updateProfile(rawData);
      console.log("res", res);
      if (res.status === true) {
        handleSuccessMessg();
        navigate("/cms/general-data");
      } else {
        handleErrorMsg(res?.message || "Error updating profile");
      }
    } else {
      handleErrorMsg("Please fill required fields.");
    }
  };

  const validateFormData = (formData) => {
    const requiredFields = [
      "generalData_phone",
      "generalData_email",
      "generalData_Instagram",
      "generalData_twitter",
      "generalData_linkedin",
      "generalData_facebook",
      "generalData_address"
    ];

    for (let field of requiredFields) {
      if (
        formData.get(field) === "undefined" ||
        formData.get(field) === null ||
        formData.get(field).trim() === ""
      ) {
        serError((prevError) => ({
          ...prevError,
          [field]: `${field} is required`,
        }));
        return false;
      }
    }

    return true;
  };

  return (
    <div>
      <ProfileTopNavBar />
      <div className="container-fluid" ref={targetRef}>
        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form onSubmit={handleSubmit}>
                <div className="py-2 text-start">
                  <span className="profile_store_details_heading">
                    General Data Form
                  </span>
                </div>
                <div className="store_details_all_form_section">
                  {/* Row 1: Phone No and Email Address */}
                  <div className="form-row row">
                    <div className="col-md-6">
                      <label
                        htmlFor="phoneNo"
                        className="profile_label_store_name"
                      >
                        Phone No.
                      </label>
                      <input
                        className="form-control store_input_field"
                        type="number"
                        name="generalData_phone"
                        placeholder="+91"
                        id="phoneNo"
                        value={ADMININFO.generalData_phone || ""}
                        onChange={handleChange}
                      />
                      {errors.generalData_phone && (
                        <p style={{ color: "red" }}>
                          {errors.generalData_phone}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="emailAddress"
                        className="profile_label_store_name"
                      >
                        Email Address*
                      </label>
                      <input
                        type="email"
                        className="form-control store_input_field"
                        name="generalData_email"
                        placeholder="Email"
                        id="emailAddress"
                        value={ADMININFO.generalData_email || ""}
                        onChange={handleChange}
                      />
                      {errors.generalData_email && (
                        <p style={{ color: "red" }}>
                          {errors.generalData_email}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Row 2: Instagram and Twitter */}
                  <div className="form-row row mt-3">
                    <div className="col-md-6">
                      <label
                        htmlFor="generalData_Instagram"
                        className="profile_label_store_name"
                      >
                        Instagram
                      </label>
                      <input
                        type="text"
                        className="form-control store_input_field"
                        placeholder="Instagram"
                        name="generalData_Instagram"
                        id="generalData_Instagram"
                        value={ADMININFO.generalData_Instagram || ""}
                        onChange={handleChange}
                      />
                      {errors.generalData_Instagram && (
                        <p style={{ color: "red" }}>
                          {errors.generalData_Instagram}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="generalData_twitter"
                        className="profile_label_store_name"
                      >
                        Twitter
                      </label>
                      <input
                        type="text"
                        className="form-control store_input_field"
                        placeholder="Twitter"
                        name="generalData_twitter"
                        id="generalData_twitter"
                        value={ADMININFO.generalData_twitter || ""}
                        onChange={handleChange}
                      />
                      {errors.generalData_twitter && (
                        <p style={{ color: "red" }}>
                          {errors.generalData_twitter}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Row 3: LinkedIn and Facebook */}
                  <div className="form-row row mt-3">
                    <div className="col-md-6">
                      <label
                        htmlFor="generalData_linkedin"
                        className="profile_label_store_name"
                      >
                        LinkedIn
                      </label>
                      <input
                        type="text"
                        className="form-control store_input_field"
                        placeholder="LinkedIn"
                        name="generalData_linkedin"
                        id="generalData_linkedin"
                        value={ADMININFO.generalData_linkedin || ""}
                        onChange={handleChange}
                      />
                      {errors.generalData_linkedin && (
                        <p style={{ color: "red" }}>
                          {errors.generalData_linkedin}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="generalData_facebook"
                        className="profile_label_store_name"
                      >
                        Facebook
                      </label>
                      <input
                        type="text"
                        className="form-control store_input_field"
                        placeholder="Facebook"
                        name="generalData_facebook"
                        id="generalData_facebook"
                        value={ADMININFO.generalData_facebook || ""}
                        onChange={handleChange}
                      />
                      {errors.generalData_facebook && (
                        <p style={{ color: "red" }}>
                          {errors.generalData_facebook}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Row 4: Address */}
                  <div className="form-row row mt-3">
                    <div className="col-md-12">
                      <label
                        htmlFor="generalData_address"
                        className="profile_label_store_name"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control store_input_field"
                        placeholder="Address"
                        name="generalData_address"
                        id="generalData_address"
                        value={ADMININFO.generalData_address || ""}
                        onChange={handleChange}
                      />
                      {errors.generalData_address && (
                        <p style={{ color: "red" }}>
                          {errors.generalData_address}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="store_details_form_submit_button mt-4">
                  <button
                    type="submit"
                    className="btn btn-dark store_details_form_btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default StoreDetailsForm;
