// import { useEffect, useState } from "react";
// import "./chatList.css";
// import { useUserStore } from "../../../../lib/userStore";
// import { doc, getDoc, onSnapshot, updateDoc, collection, query, where, getDocs, setDoc } from "firebase/firestore";
// import { db } from "../../../../config/firebaseConfig";
// import { useChatStore } from "../../../../lib/chatStore";
// import { list } from "../../../../controllers/vendors/vendorsController";
// import { ASSETS_BASE_URL } from "../../../../config/constants";
// import { toast, ToastContainer } from "react-toastify";

// const ChatList = () => {
//   const [chats, setChats] = useState([]);
//   const [vendorList, setVendorList] = useState([]);
//   const [input, setInput] = useState("");
//   const { currentUser } = useUserStore();
//   const { changeChat } = useChatStore();

//   // Fetch vendor list from backend
//   const getVendorList = async () => {
//     try {
//       const options = { type: "", select: {}, sort: { status: 1, _id: -1 } };
//       const response = await list(options);
//       if (response.status) {
//         setVendorList(response.result);
//       } else {
//         setVendorList([]);
//       }
//     } catch (error) {
//       console.error("Error fetching vendor list:", error);
//     }
//   };

//   // Load vendor list on component mount
//   useEffect(() => {
//     getVendorList();
//   }, []);

//   // Real-time listener for current user's chats
//   useEffect(() => {
//     if (!currentUser?.id) return;

//     const userChatDocRef = doc(db, "userChats", currentUser.id);
//     const unSub = onSnapshot(userChatDocRef, async (res) => {
//       if (!res.exists()) {
//         console.error("Document does not exist");
//         return;
//       }

//       const items = res.data()?.chats || [];
//       const promises = items.map(async (item) => {
//         const userDocRef = doc(db, "users", item.receiverId);
//         const userDocSnap = await getDoc(userDocRef);
//         const user = userDocSnap.data() || {};
//         return { ...item, user: { ...user, avatar: item.avatar } };
//       });

//       try {
//         const chatData = await Promise.all(promises);
//         setChats(chatData.sort((a, b) => b.updatedAt - a.updatedAt));
//       } catch (err) {
//         console.error("Error processing chat data", err);
//       }
//     });

//     return () => {
//       unSub();
//     };
//   }, [currentUser?.id]);

//   // Filter vendors who don't have an active chat with the current user
//   const getFilteredVendorList = () => {
//     const chatEmails = chats.map((chat) => chat.user.email);
//     return vendorList.filter((vendor) => !chatEmails.includes(vendor.vendor_email));
//   };

//   // Handle selection of a chat
//   const handleSelect = async (chat) => {
//     const userChats = chats.map((item) => {
//          if (item.chatId === chat.chatId) {
//         item.isSeen = true; // Set isSeen to true for selected chat
//       }
//       const { user, ...rest } = item;
//       return rest;
//     });
//     const chatIndex = userChats.findIndex((item) => item.chatId === chat.chatId);
//     if (chatIndex !== -1) {
//       userChats[chatIndex].isSeen = true;
//     }

//     const userChatsRef = doc(db, "userChats", currentUser.id);

//     try {
//       await updateDoc(userChatsRef, {
//         chats: userChats,
//       });
//       changeChat(chat.chatId, chat.user);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   // Handle vendor selection and create chat if needed
//   const handleVendorSelect = async (vendor) => {
//     try {
//       const userRef = collection(db, "users");
//       const q = query(userRef, where("email", "==", vendor.vendor_email));
//       const querySnapShot = await getDocs(q);

//       if (!querySnapShot.empty) {
//         const foundUser = querySnapShot.docs[0].data();
//         foundUser.id = querySnapShot.docs[0].id;
//         const foundUserImg = foundUser.image;

//         const newChatId = foundUser.id + currentUser.id;
//         const userChatsRef = doc(db, "userChats", currentUser.id);
//         const foundUserChatsRef = doc(db, "userChats", foundUser.id);

//         const userChatSnap = await getDoc(userChatsRef);
//         const foundUserChatSnap = await getDoc(foundUserChatsRef);

//         if (!userChatSnap.exists()) {
//           await setDoc(userChatsRef, { chats: [] });
//         }
//         if (!foundUserChatSnap.exists()) {
//           await setDoc(foundUserChatsRef, { chats: [] });
//         }

//         const currentUserChats = userChatSnap.exists() ? userChatSnap.data().chats : [];
//         const foundUserChats = foundUserChatSnap.exists() ? foundUserChatSnap.data().chats : [];

//         const existingChat = currentUserChats.find((chat) => chat.chatId === newChatId);

//         if (!existingChat) {
//           const chatDocRef = doc(collection(db, "chats"), newChatId);
//           await setDoc(chatDocRef, { messages: [] });

//           const newChatEntry = {
//             chatId: newChatId,
//             receiverId: foundUser.id,
//             lastMessage: "",
//             updatedAt: Date.now(),
//             isSeen: true,
//             avatar: foundUserImg,
//           };

//           await updateDoc(userChatsRef, { chats: [...currentUserChats, newChatEntry] });
//           await updateDoc(foundUserChatsRef, {
//             chats: [
//               ...foundUserChats,
//               { ...newChatEntry, receiverId: currentUser.id },
//             ],
//           });
//         }

//         const chatUser = {
//           id: foundUser.id,
//           username: `${vendor.vendor_first_name} ${vendor.vendor_last_name}`,
//           email: vendor.vendor_email,
//           avatar: foundUserImg,
//         };
//         changeChat(newChatId, chatUser);
//       } else {
//         alert("Vendor needs to login first!");
//       }
//     } catch (error) {
//       console.error("Error searching for user by email:", error);
//     }
//   };

//   // Filter chats and vendors based on search input
//   // const filteredChats = chats.filter((c) =>
//   //   c.user && c.user.email && c.user.email.toLowerCase().includes(input.toLowerCase())
//   // );

//   // const filteredVendors = getFilteredVendorList().filter((vendor) =>
//   //   vendor.vendor_email.toLowerCase().includes(input.toLowerCase())
//   // );

//   //   // Filter chats and vendors based on search input
//   const filteredChats = chats.filter((c) =>
//     c.user?.username?.toLowerCase().includes(input.toLowerCase())
//   );

//   const filteredVendors = getFilteredVendorList().filter((vendor) =>
//     `${vendor.vendor_first_name} ${vendor.vendor_last_name}`.toLowerCase().includes(input.toLowerCase())
//   );

//   return (
//     <> 
//       <div className="chatList">
//         <div className="search">
//           <div className="searchBar">
//             <img src="./images/chat/search.png" alt="" />
//             <input
//               type="text"
//               placeholder="Search by vendor name"
//               value={input}
//               onChange={(e) => setInput(e.target.value)}
//             />
            
//           </div>
//         </div>

//         {/* Render existing chats */}
//         {filteredChats.map((chat) => (
//           <div
//           className={`item ${chat.isSeen ? "" : "highlight"}`}
//             key={chat.chatId}
//             onClick={() => handleSelect(chat)}
//             style={{ backgroundColor: chat?.isSeen ? "transparent" : "#5183fe" }}
//           >
//             <img src={`${ASSETS_BASE_URL}${chat.user.avatar}` || "./images/chat/avatar.png"} alt="" />
//             <div className="texts">
//               <span>{chat.user.username}</span>
//               <p className={chat.isSeen ? "" : "newMessage"}>{chat.isSeen ? chat.lastMessage : "New message"}</p>
//             </div>
//           </div>
//         ))}

//         {/* Render vendor list for new chats */}
//         <div className="vendorList">
//           {filteredVendors.map((vendor) => (
//             <div
//               className="item"
//               key={vendor.vendor_email}
//               onClick={() => handleVendorSelect(vendor)}
//             >
//               <img src={`${ASSETS_BASE_URL}${vendor.vendor_image}` || "./images/chat/avatar.png"} alt="" />
//               <div className="texts">
//                 <span>{`${vendor.vendor_first_name} ${vendor.vendor_last_name}`}</span>
//                 {/* <p>{vendor.vendor_email}</p> */}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ChatList;

// Import necessary libraries and components
import { useEffect, useState } from "react";
import "./chatList.css";
import { useUserStore } from "../../../../lib/userStore";
import { doc, getDoc, onSnapshot, updateDoc, collection, query, where, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../../config/firebaseConfig";
import { useChatStore } from "../../../../lib/chatStore";
import { list } from "../../../../controllers/vendors/vendorsController";
import { ASSETS_BASE_URL } from "../../../../config/constants";
// import { toast, ToastContainer } from "react-toastify";

const ChatList = () => {
  const [chats, setChats] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [input, setInput] = useState("");
  const { currentUser } = useUserStore();
  const { changeChat } = useChatStore();

  // Fetch vendor list from backend
  const getVendorList = async () => {
    try {
      const options = { type: "", select: {}, sort: { status: 1, _id: -1 } };
      const response = await list(options);
      if (response.status) {
        setVendorList(response.result);
      } else {
        setVendorList([]);
      }
    } catch (error) {
      console.error("Error fetching vendor list:", error);
    }
  };

  // Load vendor list on component mount
  useEffect(() => {
    getVendorList();
  }, []);

  // Real-time listener for current user's chats
  useEffect(() => {
    if (!currentUser?.id) return;

    const userChatDocRef = doc(db, "userChats", currentUser.id);
    const unSub = onSnapshot(userChatDocRef, async (res) => {
      if (!res.exists()) {
        console.error("Document does not exist");
        return;
      }

      const items = res.data()?.chats || [];
      const promises = items.map(async (item) => {
        const userDocRef = doc(db, "users", item.receiverId);
        const userDocSnap = await getDoc(userDocRef);
        const user = userDocSnap.data() || {};
        
        // Calculate the unseen message count for each chat
        const chatDocRef = doc(db, "chats", item.chatId);
        const chatDocSnap = await getDoc(chatDocRef);
        const messages = chatDocSnap.exists() ? chatDocSnap.data().messages : [];
        const unseenCount = messages.filter((msg) => !msg.isSeen && msg.senderId !== currentUser.id).length;
        
        return { ...item, unseenCount, user: { ...user, avatar: item.avatar } };
      });

      try {
        const chatData = await Promise.all(promises);
        setChats(chatData.sort((a, b) => b.updatedAt - a.updatedAt));
      } catch (err) {
        console.error("Error processing chat data", err);
      }
    });

    return () => {
      unSub();
    };
  }, [currentUser?.id]);

  // Filter vendors who don't have an active chat with the current user
  const getFilteredVendorList = () => {
    const chatEmails = chats.map((chat) => chat.user.email);
    return vendorList.filter((vendor) => !chatEmails.includes(vendor.vendor_email));
  };

  // Function to mark all messages as seen
  const markMessagesAsSeen = async (chatId) => {
    const chatDocRef = doc(db, "chats", chatId);
    const chatDoc = await getDoc(chatDocRef);

    if (chatDoc.exists()) {
      const messages = chatDoc.data().messages || [];

      // Update only if there are unseen messages
      const updatedMessages = messages.map((message) =>
        !message.isSeen && message.senderId !== currentUser.id
          ? { ...message, isSeen: true }
          : message
      );

      // Save the updated messages back to Firestore
      await updateDoc(chatDocRef, { messages: updatedMessages });
    }
  };

  // Handle selection of a chat
  const handleSelect = async (chat) => {
    try {
      // Mark all messages as seen in the selected chat
      await markMessagesAsSeen(chat.chatId);

      // Update the local state for the selected chat to reflect `isSeen`
      const userChats = chats.map((item) => {
        if (item.chatId === chat.chatId) {
          item.isSeen = true;
          item.unseenCount = 0; // Reset unseen count on selection
        }
        const { user, ...rest } = item;
        return rest;
      });

      const userChatsRef = doc(db, "userChats", currentUser.id);
      await updateDoc(userChatsRef, { chats: userChats });

      // Switch to the selected chat
      changeChat(chat.chatId, chat.user);
    } catch (err) {
      console.log("Error marking messages as seen:", err);
    }
  };

  // Handle vendor selection and create chat if needed
  const handleVendorSelect = async (vendor) => {
    try {
      const userRef = collection(db, "users");
      const q = query(userRef, where("email", "==", vendor.vendor_email));
      const querySnapShot = await getDocs(q);

      if (!querySnapShot.empty) {
        const foundUser = querySnapShot.docs[0].data();
        foundUser.id = querySnapShot.docs[0].id;
        const foundUserImg = foundUser.image;

        const newChatId = foundUser.id + currentUser.id;
        const userChatsRef = doc(db, "userChats", currentUser.id);
        const foundUserChatsRef = doc(db, "userChats", foundUser.id);

        const userChatSnap = await getDoc(userChatsRef);
        const foundUserChatSnap = await getDoc(foundUserChatsRef);

        if (!userChatSnap.exists()) {
          await setDoc(userChatsRef, { chats: [] });
        }
        if (!foundUserChatSnap.exists()) {
          await setDoc(foundUserChatsRef, { chats: [] });
        }

        const currentUserChats = userChatSnap.exists() ? userChatSnap.data().chats : [];
        const foundUserChats = foundUserChatSnap.exists() ? foundUserChatSnap.data().chats : [];

        const existingChat = currentUserChats.find((chat) => chat.chatId === newChatId);

        if (!existingChat) {
          const chatDocRef = doc(collection(db, "chats"), newChatId);
          await setDoc(chatDocRef, { messages: [] });

          const newChatEntry = {
            chatId: newChatId,
            receiverId: foundUser.id,
            lastMessage: "",
            updatedAt: Date.now(),
            isSeen: true,
            avatar: foundUserImg,
          };

          await updateDoc(userChatsRef, { chats: [...currentUserChats, newChatEntry] });
          await updateDoc(foundUserChatsRef, {
            chats: [
              ...foundUserChats,
              { ...newChatEntry, receiverId: currentUser.id },
            ],
          });
        }

        const chatUser = {
          id: foundUser.id,
          username: `${vendor.vendor_first_name} ${vendor.vendor_last_name}`,
          email: vendor.vendor_email,
          avatar: foundUserImg,
        };
        changeChat(newChatId, chatUser);
      } else {
        alert("Vendor needs to login first!");
      }
    } catch (error) {
      console.error("Error searching for user by email:", error);
    }
  };

  // Filter chats and vendors based on search input
  const filteredChats = chats.filter((c) =>
    c.user?.username?.toLowerCase().includes(input.toLowerCase())
  );

  const filteredVendors = getFilteredVendorList().filter((vendor) =>
    `${vendor.vendor_first_name} ${vendor.vendor_last_name}`.toLowerCase().includes(input.toLowerCase())
  );

  return (
    <> 
      <div className="chatList">
        <div className="search">
          <div className="searchBar">
            <img src="./images/chat/search.png" alt="" />
            <input
              type="text"
              placeholder="Search by vendor name"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
        </div>

{/* Render existing chats */}
{filteredChats.map((chat) => (
  <div
    className={`item ${chat.isSeen ? "" : "highlight"}`}
    key={chat.chatId}
    onClick={() => handleSelect(chat)}
  >
    <div className="avatarContainer">
      <img src={`${ASSETS_BASE_URL}${chat.user.avatar}` || "./images/chat/avatar.png"} alt="" />
      {chat.unseenCount > 0 && (
        <span className="unseenCountBadge">{chat.unseenCount}</span>
      )}
    </div>
    <div className="texts">
      <span>{chat.user.username}</span>
      <p className={chat.isSeen ? "" : "newMessage"}>{chat.isSeen ? chat.lastMessage : "New message"}</p>
    </div>
  </div>
))}

        {/* Render vendor list for new chats */}
        <div className="vendorList">
          {filteredVendors.map((vendor) => (
            <div
              className="item"
              key={vendor.vendor_email}
              onClick={() => handleVendorSelect(vendor)}
            >
              <img src={`${ASSETS_BASE_URL}${vendor.vendor_image}` || "./images/chat/avatar.png"} alt="" />
              <div className="texts">
                <span>{`${vendor.vendor_first_name} ${vendor.vendor_last_name}`}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChatList;

