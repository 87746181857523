import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Drawer, Button, Space } from "antd";
import Pagination from "@mui/material/Pagination";
import { notification } from "antd";
import "../Inquiry/Inquiry.css";
import { MdDelete } from "react-icons/md";

import Top_Navbar from "../DashBaord/Top_navbar.js";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../config/constants.js";
import SkeltonList from "../../components/SkeltonEffect/list.js";
// import {
//   list,
//   chnageStatus,
// } from "../../controllers/inquiry/inquiryController.js";
import { getPage, statusMessage } from "../../controllers/common.js";
import { list , deleteNewsletter } from "../../controllers/newsletter/newsletterController.js";

function Promotions(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Promotions.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /************************************************************************************* */
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End
  const [isLoading, setIsLoading] = useState(true);

  const [showRequest, setShowRequest] = useState("");

  /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    setIsLoading(true);
    setListData([]);
    const options = {
      type: "",
      condition: {
        ...(showRequest ? { status: showRequest } : null),
      },
      ...(filter?{filter: filter}: null),
      select: {},
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    if (listData.status === true) {
      if (listData?.result?.length > 0) {
        setIsLoading(false);
        setListData(listData?.result);
        setTotalPages(getPage(listData?.count || 0));
      } else {
        setIsLoading(false);
        setTotalPages(getPage(0));
        // setTotalPages(getPage(0));
      }
    } else {
      setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  /*********************************************************
   *  This function is use to handle delete newsletter
   *********************************************************/
  const handleDelete = async(deleteId) => {
    try {
      const options = {
        url: `newsletter/delete`,
        postData: {
          id : deleteId,
      }
    }
    const res = await deleteNewsletter(options);
    if(res.status === true){
      notification.open({
        message: "Deleted successfully.",
        description:  `Newsletter deleted successfully.`,
        placement: "topRight", 
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        duration:2
      });
    } else{
      notification.open({
        message: "Opps!",
        description:  `${res?.message}`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
    }catch(error){
      notification.open({
        message: "Opps!",
        description:  `Operation not perform yet! please try in some time.`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
    getList();
  };
  // const handleStatusChnage = async (status = "", id) => {
  //   try {
  //     setListData([]);
  //     const options = {
  //       id: id,
  //       status: status,
  //     };
  //     const res = await chnageStatus(options);
  //     if (res.status === true) {
  //       notification.open({
  //         message: "Data updated.",
  //         description: `Data updated Successfully.`,
  //         placement: "topRight",
  //         icon: <CheckCircleOutlined style={{ color: "green" }} />,
  //         duration: 2,
  //       });
  //     } else {
  //       notification.open({
  //         message: "Opps!",
  //         description: `${res?.message}`,
  //         placement: "topRight",
  //         icon: <InfoCircleOutlined style={{ color: "red" }} />,
  //         duration: 2,
  //       });
  //     }
  //   } catch (error) {
  //     notification.open({
  //       message: "Opps!",
  //       description: `Operation not perform yet! please try in some time.`,
  //       placement: "topRight",
  //       icon: <InfoCircleOutlined style={{ color: "red" }} />,
  //       duration: 2,
  //     });
  //   }
  //   getList();
  // };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  }
  /*********************************************************
  *  This function is ued for handle filter input chnage
  *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {

    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre) => ({
        ...pre,
        'filter_by': form.get('filter_by') || '',
        'search': form.get('search') || '',

      }))
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      console.log(error);
    }
  }

  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Frisbee Super Admin || Newsletter List";
  }, [currentPage, showRequest, filter]);

  const dropdownMenu = (item) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleDelete(item._id)}>
        <MdDelete /> <span className="show_span">Delete</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_Navbar title={`Newsletter`}></Top_Navbar>

        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                  </Tabs>
                </div>
              </div>
            </Box> */}
            {/* First Inventory */}
            <Promotions>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="promotions_table_heading">
                      <div>
                        <h6 className="m-0 promotions_heading">Newsletter list</h6>
                      </div>
                      <div className="promotions_filters_div">
                        <div>
                          <h6 className="m-0 promotions_heading_filter">
                            <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                              <img
                                src={ASSETS_BASE_URL + "./images/Filter.svg"}
                                alt=""
                                className="user_iconses"
                              />
                              <Link
                                to={false}
                                className="filter_btn_invontry_rating_review"
                              >
                                Filters
                              </Link>
                            </div>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Received on</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{items.email}</td>
                                    <td>
                                      {moment(`${items.createdAt}`).format(
                                        "DD/MM/YYYY h:m A"
                                      )}{" "}
                                    </td>
                                    {
                                    <Dropdown
                                      overlay={() => dropdownMenu(items)}
                                      placement="bottomLeft"
                                      arrow
                                    >
                                      <td>
                                        <HiOutlineDotsHorizontal />
                                      </td>
                                    </Dropdown>}
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={5} /> : <tr> 
                            <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                        </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Promotions>
          </Box>
        </div>
      </div>
      {/* Filter */}
      <Drawer
        title="Newsletter Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }} >
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='email' selected={filter?.filter_by === 'email' ? true : false}>Email</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"
                  ref={inputRef2}
                // onChange={handleFilterChnage} 
                // value={filter?.search}             
                />
              </div>
              <br />
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black "
                  style={{ backgroundColor: "black", color: "white" }}
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
