import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Switch, Drawer, Button, Space } from "antd";
import { FaTimes, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import Top_navbar from '../../DashBaord/Top_navbar'
import ViewRequestPopUp from './ViewRequest';
import RejectPopup from './RejectPopup'

import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import { requestList } from "../../../controllers/Products/inventory";
import { getPage, requestStatusMessage } from '../../../controllers/common';

function Inventory(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Inventory.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  //for PopUP modal
  const [requestPopUp, setRequestPopUp] = useState(false);
  const requestTogglePopUp = () => {
    setRequestPopUp(!requestPopUp);
  };

  //remove or delete popup
  const [rejectPopUpOpen, setRejectPopUpOpen] = useState(false);
  const toggleRejectPopUp = () => {
    setRejectPopUpOpen(!rejectPopUpOpen);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /************************************************************************************* */
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [rejectId, setRejectId] = useState("");
  const [REQUESTDATA, setRequestData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: '',
    to: new Date(),
    filter_by: "",
    search: ""
  })//End

  const [showRequest, setShowRequest] = useState("");

  const handleEdit = async (item={}) =>{
    navigate('/vendors/addeditdata',{state : item});
  }
  /*********************************************************
  *  This function is use to fetch vendor list
  *********************************************************/
  const getList = async ()=>{
    setListData([]);
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        ...(showRequest ? { status: showRequest } : null)
      },
      ...(filter ? { filter: filter } : null),
      select: {},
      sort: { "_id": -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10
    }
    const listData = await requestList(options);
    
    if (listData.status === true) {
      if (listData.result.result.length > 0) {
        setIsLoading(false);
        setListData(listData?.result.result);
        setTotalPages(getPage(listData?.result?.count));
      } else {
        setIsLoading(false);
        setTotalPages(getPage(0));
      }
    }else{
      setIsLoading(false);
    }
  };//End

  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };


  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  }
  /*********************************************************
  *  This function is ued for handle filter input chnage
  *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {

    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre) => ({
        ...pre,
        'filter_by': form.get('filter_by') || '',
        'search': form.get('search') || '',
        'to': form.get('to_date') || '',
        'from': form.get('from_date') || '',

      }));
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {

    }
  }

  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = 'Frisbee Super Admin || Inventory Request List'
  }, [currentPage, showRequest, requestPopUp, rejectPopUpOpen, filter]);

  const getVariantsString = (variants) => {
    if (!Array.isArray(variants)) return '';

    let variantsString = '';

    variants.forEach((item, index) => {
      variantsString +=`<p key=${index}>`
      variantsString +=`<span>Unit : ${item.unit} | </span>` 
      variantsString +=`<span>Price : AED ${item.price} | </span>` 
      variantsString +=`<span>QTY : ${item.qty} | </span>` 
      variantsString +=`<span>Disc. : ${item?.discount?item.discount:0} %</span>` 
      variantsString +=`</p>`;
    });

    return variantsString;
};

const dropdownMenu = (item) => (
  <>
  {item.status === 'R'?(<>
    <Menu>
      <Menu.Item key="1" >
        <span>Reason : {item?.reason}</span>
      </Menu.Item>
      <Menu.Item key="2" >
      <span>Reject At :{" "} {moment(`${item.updatedAt}`).format("DD/MM/YYYY, h:m A")}</span>
      </Menu.Item>
    </Menu>
  </>):(<>
    <Menu>
      <Menu.Item key="1" 
      onClick={()=>{
        setRequestData(item);
        requestTogglePopUp()
        }}>
      <FaEye />
        <span className="show_span_edit">View</span>
      </Menu.Item>
      <Menu.Item key="2" 
        onClick={()=>{
          setRejectId(item._id)
          toggleRejectPopUp()
          }}>
        <FaTimes /> <span className="show_span">Reject</span>
      </Menu.Item>
    </Menu>
  </>)}
    
  </>
);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_navbar title={`Request List`}></Top_navbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                    />
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review" onClick={handleFiterDrawer}>
                  <div className="inventory_filter_div">
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <Link href="#" className="filter_btn_invontry_rating_review">
                      Filters
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Inventory value={value} index={0}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Store</th>
                              {/* <th>Branch</th> */}
                              <th>Category</th>
                              <th>Sub Category</th>
                              <th>Variants</th>
                              <th>Status</th>
                              <th>Request At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {ALLLISTDATA?.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>
                            <tr key={index}>
                              <td width="100px">
                                <div className="admin_prodcut_list_span">
                                  <img
                                    src={`${ASSETS_BASE_URL}${items?.productId?.image}`}
                                    alt=""
                                  />
                                  <span className="admin_prodcut_list_span_name">
                                    {items?.productId?.name}
                                  </span>
                                </div>
                              </td>
                              <td>
                                  <span>{items?.storeId?.shop_name}</span><br/>
                                  <span>{items?.storeId?.phone}</span><br/>
                                  <span>{items?.storeId?.email}</span><br/>
                                  <span>{items?.storeId?.city}</span>
                              </td>
                              {/* <td>
                                  <span>{items?.branchId?.branch_name}</span><br/>
                                  <span>{items?.branchId?.phone}</span><br/>
                                  <span>{items?.branchId?.email}</span><br/>
                                  <span>{items?.branchId?.city}</span>
                              </td> */}
                              <td>{items?.productId?.category?.name}</td>
                              <td>{items?.productId?.subcategory?.name}</td>
                              <td dangerouslySetInnerHTML={{ __html: getVariantsString(items?.variants) }}></td>
                              <td dangerouslySetInnerHTML={{ __html: requestStatusMessage(items?.status) }}></td>
                              <td>{moment(items?.createdAt).format('DD-MM-YYYY')}</td>
                              <td>
                                {items?.status === 'C'?(<>
                                  <HiOutlineDotsHorizontal />
                                </>):(<>
                                  <Dropdown
                                  overlay={()=>dropdownMenu(items)}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                                </>)}
                              </td>
                            </tr>
                            </>))) : isLoading ? <SkeltonList row={5} col={8} /> : <tr> 
                                <td colspan="8" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Inventory>
          </Box>
        </div>
        <ViewRequestPopUp
          requestPopUp={requestPopUp}
          setRequestPopUp={setRequestPopUp}
          requestData={REQUESTDATA}
          setRequestData={setRequestData}
        />
        <RejectPopup
          id = {rejectId}
          isRejectPopup={rejectPopUpOpen}
          handleRejectToggle={toggleRejectPopUp}
          setRejectId={setRejectId}
        />
      </div>
      {/* Filter */}
      <Drawer
        title="Inventory Request Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }} >
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='product_name' selected={filter?.filter_by === 'product_name' ? true : false} >Product Name</option>
                  <option value='store_name' selected={filter?.filter_by === 'store_name' ? true : false}>Store Name</option>
                  <option value='store_phone' selected={filter?.filter_by === 'store_phone' ? true : false}>Store Phone</option>
                  <option value='store_email' selected={filter?.filter_by === 'store_email' ? true : false}>Store Email</option>
                  <option value='category' selected={filter?.filter_by === 'category' ? true : false}>Category</option>
                  <option value='subcategory' selected={filter?.filter_by === 'subcategory' ? true : false}>Sub-Category</option>
                  <option value='status' selected={filter?.filter_by === 'status' ? true : false}>Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"
                  ref={inputRef2}
                // onChange={handleFilterChnage} 
                // value={filter?.search}             
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field"
                  id="from_date"
                  ref={inputRef3}
                // onChange={handleFilterChnage}          
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field"
                  id="to_date"
                  ref={inputRef4}
                // onChange={handleFilterChnage}             
                />
              </div>
              <br />
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black"
                  style={{ backgroundColor: "black", color: "white" }}
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
