import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import "./Support.css";
import { Input, Space, Skeleton } from "antd";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import Top_navbar from "../DashBaord/Top_navbar.js";
import Pagination from "@mui/material/Pagination";
import { list } from "../../controllers/cms/faqs.js";

import AddEditPopUp from "./AddEditPopUp.js";

import { getPage } from "../../controllers/common";
const { Search } = Input;
const Support = () => {
  const targetRef = useRef();
  const [addEditPopUp, setAddEditPopUp] = useState();
  const toggleAddEditPopUp = () =>setAddEditPopUp(!addEditPopUp);
  const [isAddEdited, setSetIsAddEdited] = useState();
  const toggleIsAddEdited = () =>setSetIsAddEdited(!isAddEdited);
  const [addEditData, setAddEditData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [searchValue, setSearchValue] = useState(""); 
  const [isSearch, setIsSearch] = useState(false);
  const handleSearch = () =>{
    setIsSearch(!isSearch);
  }
  
  /*********************************************************
  *  This function is use to fetch category list
  *********************************************************/
  const getList = async (source)=>{
    setListData([]);
    const options = {
      condition : {
        ...(searchValue?{ title : { $regex: searchValue, $options: 'i' } }:null)
       },
      select    : {  },
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    }
    const listData = await list(options,source);
    if(listData.status === true){
      if(listData?.result.length > 0){
        setListData(listData?.result);
        console.log('listData?.count',listData?.count);
        setTotalPages(getPage(listData?.count || 0));
      }else{
        setListData([{
          title : "No Data Found"
        }]);  
        setTotalPages(0);
      }
    }else{
      setListData([{
        title : "No Data Found"
      }]);
      setTotalPages(0);
    }
  };//End
  const handleEditData = (item) =>{
    try {
      setAddEditData(item);
      toggleAddEditPopUp();
    } catch (error) {
      
    }
  }
  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(()=>{
    const source = axios.CancelToken.source();
   getList(source);
   targetRef.current.scrollIntoView({
     behavior: 'smooth',
   });
    document.title = 'Frisbee Super Admin || FAQs'
   return () => {
     source.cancel('Request canceled due to component unmount or page change');
    };
  },[isAddEdited, currentPage]);
  return (
    <div ref={targetRef}>
      <div className="container-fluid">
        <Top_navbar title={"Support & FAQs"}></Top_navbar>
        <div className="support_main_container">
          <div className="support_main_content">
            <div className="support_search_bar_main_div">
            </div>
            <div className="card shadow mb-4 support_Accordian_main_div">
              <div>
                <h6 className="m-0 support_promotions_heading">
                  Frequently Asked Question (FAQs)
                </h6>
              </div>

              <div>
                <button type="button" className="btn btn-dark" onClick={toggleAddEditPopUp} > Add New </button>
              </div>

              <div className="support_accordian_div">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {ALLLISTDATA.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>

                    <div class="accordion-item" onDoubleClick={()=>handleEditData(items)}>
                      <h2 class="accordion-header" id={`flush-heading${index}`}>
                        <button
                          class="accordion-button collapsed support_accordian_question"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index}`}
                        >
                          {items?.title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${index}`}
                        class="accordion-collapse collapse support_accordian_question"
                        aria-labelledby={`flush-heading${index}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                            {items?.description}
                        </div>
                      </div>
                    </div>

                  </>))) : (<Skeleton loading={true} active title={false} paragraph={{ rows: 10 }} />)}
                </div>
                <div className="table_pagination_sales_order">
                  <Pagination
                    count={TOTALPAGES}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddEditPopUp
        addEditPopUp={addEditPopUp}
        toggleAddEditPopUp={toggleAddEditPopUp}
        addEditData={addEditData}
        setAddEditData={setAddEditData}
        toggleIsAddEdited={toggleIsAddEdited}
      />
    </div>
  );
}

export default Support;
