import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  addeditdata,
  getPermission
} from "../../../controllers/subAdmin/subAdminControllers";
import { fetchIpAddress } from "../../../controllers/API";

import LoadingEffect from "../../../components/Loading/LoadingEffect";
import { beforeUpload } from "../../../controllers/common";

const UserListEditPage = () => {
  const { Option } = Select;
  const { Dragger } = Upload;

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);

  const [isLoading, setIsLoading] = useState(false);

  /***************  Define Permission States ***************/
  const [assignPermission, setAssignPermission] = useState('')
  const [accountPermission, setAccountPermission] = useState({ user: 'N', vendor: 'N' });
  const [categoryPermission, setCategoryPermission] = useState({ product: 'N', service: 'N', request: 'N' });
  const [cmsPermission, setCMSPermission] = useState({ banner: 'N', termAndCondition: 'N', privacyPolicy: 'N', treats: 'N', breeds: 'N', generalData: 'N', emailTemplates: 'N', reason: 'N', ticker : 'N' });
  const [subCategoryPermission, setSubCategoryPermission] = useState({ products: 'N' });
  const [productsPermission, setProductsPermission] = useState({ products: 'N', request: 'N' });
  const [inventoryPermission, setInventoryPermission] = useState({ inventory: 'N', request: 'N' });
  const [servicesPermission, setServicesPermission] = useState({ grooming: 'N', veterinary: 'N', training: 'N', boarding: 'N', friendly: 'N', adoption: 'N', dayCare: 'N' });
  const [servicesRequestPermission, setServicesRequestPermission] = useState({ grooming: 'N', veterinary: 'N', training: 'N', boarding: 'N', adoption: 'N', dayCare: 'N' });
  const [bookingsPermission, setBookingsPermission] = useState({ grooming: 'N', veterinary: 'N', training: 'N', boarding: 'N', dayCare: 'N' });
  const [insightsPermission, setInsightsPermission] = useState({ insights: 'N' });
  const [insurancePermission, setInsurancePermission] = useState({ insurance: 'N' });
  const [paymentsPermission, setPaymentsPermission] = useState({ shop: 'N', grooming: 'N', veterinary: 'N', training: 'N', boarding: 'N', dayCare: 'N' });
  const [promotionPermission, setPromotionPermission] = useState({ promotion: 'N', request: 'N' });
  const [ratingPermission, setRatingPermission] = useState({ rating: 'N' });
  const [messagePermission, setMessagePermission] = useState({ message: 'N' });
  const [faqPermission, setFAQPermission] = useState({ faq: 'N' });
  const [notificationsPermission, setNotificationsPermission] = useState({ notifications: 'N' });
  const [ordersPermission, setOrdersPermission] = useState({ orders: 'N' });
  /*********************** End ************************ */
  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };
  /*********************************************************
   *  This function is used to handle phone number change
   *********************************************************/
  const handlePhoneChange = (value) => {
    setAddEditSata((prev) => ({
      ...prev,
      admin_phone: value,
    }));
    setError((prev) => ({
      ...prev,
      admin_phone: "", // Clear previous error message
    }));
  };
  /*********************************************************
   *  This function is used to handle phone code change
   *********************************************************/
  const handlePhoneCodeChange = (value) => {
    setAddEditSata((prev) => ({
      ...prev,
      vendor_country_code: value,
      vendor_phone: "", // Clear phone number
    }));
    setError((prev) => ({
      ...prev,
      vendor_phone: "", // Clear previous error message
    }));
  };

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const ipAddress = await fetchIpAddress();
      const form = new FormData(event.target);
      if (ADDEDITDATA?._id && ADDEDITDATA?._id !== "undefined" && ADDEDITDATA?._id !== null) {
        form.append("edit_id", ADDEDITDATA?._id);
      }
      form.append('admin_phone', ADDEDITDATA?.admin_phone);
      form.append("ipAddress", ipAddress);
      const isValidate = validateFormData(form);
      const permissions = {
        accounts: accountPermission,
        category: categoryPermission,
        cms: cmsPermission,
        subCategory: subCategoryPermission,
        products: productsPermission,
        orders: ordersPermission,
        inventory: inventoryPermission,
        services: servicesPermission,
        servicesRequest: servicesRequestPermission,
        bookings: bookingsPermission,
        insights: insightsPermission,
        insurance: insurancePermission,
        payments: paymentsPermission,
        promotion: promotionPermission,
        rating: ratingPermission,
        message: messagePermission,
        faq: faqPermission,
        notifications: notificationsPermission
      }
      if (isValidate) {
        const rowData = {};
        form.forEach((value, key) => {
          rowData[key] = value;
        });
        const options = {
          url: `users/create-sub-admin/${ADDEDITDATA?._id ? ADDEDITDATA?._id : ':editId'}`,
          postData: { ...rowData, permissions },
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated.",
            description: `Data updated Successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/sub-admin/list");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is used to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const vendor_country_code = '+971';
    if (!formData.get("admin_first_name")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        admin_first_name: "First name is required",
      }));
      return false;
    }
    if (!formData.get("admin_last_name")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        admin_last_name: "Last name is required",
      }));
      return false;
    }
    if (!formData.get("admin_email")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        admin_email: "Email address is required",
      }));
      return false;
    }
    if (!emailPattern.test(formData.get("admin_email"))) {
      setError((prevError) => ({
        ...prevError,
        admin_email: "Please enter a valid email address",
      }));
      return false;
    }
    const phone = formData.get("admin_phone");
    if (
      !phone?.trim() ||
      (vendor_country_code === "+91" && phone.length !== 10) ||
      (vendor_country_code === "+971" && phone.length !== 9)
    ) {
      setError((prevError) => ({
        ...prevError,
        admin_phone: !phone
          ? "Phone number is required"
          : `Phone number must be exactly ${vendor_country_code === "+91" ? 10 : 9
          } digits for ${vendor_country_code === "+91" ? "India" : "UAE"}`,
      }));
      return false;
    }

    return true;
  };

  const getUserpermission = async () => {
    try {
      setIsLoading(true);
      const option = { admin_id: ADDEDITDATA?._id }
      const res = await getPermission(option);
      if (res.status === true && res.result !== 'undefined') {
        setIsLoading(false);
        setAssignPermission(res.result);
        setAccountPermission(res.result?.accounts);
        setCategoryPermission(res.result?.category)

        setCMSPermission(res.result?.cms)
        setNotificationsPermission(res.result?.notifications)
        setFAQPermission(res.result?.faq)
        setMessagePermission(res.result?.message)
        setRatingPermission(res.result?.rating)
        setPromotionPermission(res.result?.promotion)

        setPaymentsPermission(res.result?.payments)
        setInsurancePermission(res.result?.insurance)
        setInsightsPermission(res.result?.insights)
        setBookingsPermission(res.result?.bookings)
        setServicesRequestPermission(res.result?.servicesRequest)
        setServicesPermission(res.result?.services)
        setInventoryPermission(res.result?.inventory)
        setProductsPermission(res.result?.products)
        setOrdersPermission(res.result?.orders)
        setSubCategoryPermission(res.result?.subCategory)




      } else {
        setAssignPermission([])
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (ADDEDITDATA?._id) {
      getUserpermission();
    }
    document.title = `Frisbee Super Admin || ${ADDEDITDATA?._id ? "Edit" : "Add"
      } Sub Admin`;
  }, []);

  /*********************************************************
*  This function is used to handle permissions dynamically
*********************************************************/
  const handlePermission = (type, value) => {
    try {
      if (type === "account") {
        setAccountPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "category") {
        setCategoryPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "cms") {
        setCMSPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "subCategory") {
        setSubCategoryPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "products") {
        setProductsPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "inventory") {
        setInventoryPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "services") {
        setServicesPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "servicesRequest") {
        setServicesRequestPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "bookings") {
        setBookingsPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "insights") {
        setInsightsPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "insurance") {
        setInsurancePermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "payments") {
        setPaymentsPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));

      } else if (type === "promotion") {
        setPromotionPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));

      } else if (type === "rating") {
        setRatingPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "message") {
        setMessagePermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "faq") {
        setFAQPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "notifications") {
        setNotificationsPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if (type === "orders") {
        setOrdersPermission((prev) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <React.Fragment>
            <h5 className="inventory_edit_page_heading"> {ADDEDITDATA?._id ? `Edit` : `Add`} Sub Admin </h5>
          </React.Fragment>
          <Link to="/sub-admin/list">
            <React.Fragment>
              <button type="button" className="btn btn-secondary"> <ArrowLeftOutlined /> Back </button>
            </React.Fragment>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet" onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="row">
                    {/* First row: First Name, Last Name, Email */}
                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_first_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>First Name :{" "}
                      </label>
                      <input
                        title="Enter your first name."
                        placeholder="Enter your first name."
                        type="text"
                        name="admin_first_name"
                        className="form-control store_input_field"
                        id="admin_first_name"
                        value={ADDEDITDATA?.admin_first_name}
                        onChange={handleChange}
                      />
                      {error.admin_first_name ? (
                        <p style={{ color: "red" }}>{error.admin_first_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_last_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Last Name :{" "}
                      </label>
                      <input
                        title="Enter your last name."
                        placeholder="Enter your last name."
                        type="text"
                        name="admin_last_name"
                        className="form-control store_input_field"
                        id="admin_last_name"
                        value={ADDEDITDATA?.admin_last_name}
                        onChange={handleChange}
                      />
                      {error.admin_last_name ? (
                        <p style={{ color: "red" }}>{error.admin_last_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_email"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Email Address :
                      </label>
                      <input
                        title="Enter your email address."
                        placeholder="Enter your email address."
                        type="text"
                        name="admin_email"
                        className="form-control store_input_field"
                        id="admin_email"
                        value={ADDEDITDATA?.admin_email}
                        readOnly={ADDEDITDATA?._id ? true : false}
                        onChange={handleChange}
                      />
                      {error.admin_email ? (
                        <p style={{ color: "red" }}>{error.admin_email}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    {/* Second row: Phone Number and Department */}
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_phone"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Phone Number:
                      </label>
                      <br />
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={handlePhoneChange}
                        addonBefore={
                          <Select
                            defaultValue={"+971"}
                            style={{ width: 150 }}
                            onChange={handlePhoneCodeChange}
                          >
                            <Option value="+971" selected={true}>
                              +971 (UAE)
                            </Option>
                          </Select>
                        }
                        value={ADDEDITDATA?.admin_phone}
                      />
                      {error.admin_phone ? (
                        <p style={{ color: "red" }}>{error.admin_phone}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="department_name"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Department Name :
                      </label>
                      <input
                        title="Enter department name."
                        placeholder="Enter department name."
                        type="text"
                        name="department_name"
                        className="form-control store_input_field"
                        id="department_name"
                        value={ADDEDITDATA?.department_name}
                        onChange={handleChange}
                      />
                      {error.department_name ? (
                        <p style={{ color: "red" }}>{error.department_name}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <label> Permission: </label>
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Accounts : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('account', 'user')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="user">User</label>
                                <input
                                  type="checkbox"
                                  id="user"
                                  name="user"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={accountPermission?.user === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('account', 'vendor')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="vendor">Vender</label>
                                <input
                                  type="checkbox"
                                  id="vendor"
                                  name="vendor"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={accountPermission?.vendor === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Category : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('category', 'product')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="product">Product</label>
                                <input
                                  type="checkbox"
                                  id="product"
                                  name="product"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={categoryPermission?.product === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('category', 'service')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="service">Services</label>
                                <input
                                  type="checkbox"
                                  id="service"
                                  name="service"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={categoryPermission?.service === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('category', 'request')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="request">Request</label>
                                <input
                                  type="checkbox"
                                  id="request"
                                  name="request"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={categoryPermission?.request === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> CMS : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', 'banner')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="banner">Banner</label>
                                <input
                                  type="checkbox"
                                  id="banner"
                                  name="banner"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.banner === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', 'termAndCondition')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="termAndCondition">Term & condition</label>
                                <input
                                  type="checkbox"
                                  id="termAndCondition"
                                  name="termAndCondition"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.termAndCondition === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', "privacyPolicy")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="privacyPolicy">Privacy Policy</label>
                                <input
                                  type="checkbox"
                                  id="privacyPolicy"
                                  name="privacyPolicy"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.privacyPolicy === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', "treats")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="treats">Treats</label>
                                <input
                                  type="checkbox"
                                  id="treats"
                                  name="treats"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.treats === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', "breeds")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="breeds">Breeds</label>
                                <input
                                  type="checkbox"
                                  id="breeds"
                                  name="breeds"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.breeds === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', "generalData")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="generalData">General Data</label>
                                <input
                                  type="checkbox"
                                  id="generalData"
                                  name="generalData"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.generalData === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', "emailTemplates")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="emailTemplates">Email Templates</label>
                                <input
                                  type="checkbox"
                                  id="emailTemplates"
                                  name="emailTemplates"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.emailTemplates === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', "reason")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="reason">Reasons</label>
                                <input
                                  type="checkbox"
                                  id="reason"
                                  name="reason"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.reason === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('cms', "ticker")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="ticker">Ticker</label>
                                <input
                                  type="checkbox"
                                  id="ticker"
                                  name="ticker"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={cmsPermission?.ticker === "Y" ? true : false}
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Sub Categories : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('subCategory', 'products')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Products</label>
                                <input
                                  type="checkbox"
                                  id="products"
                                  name="products"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={subCategoryPermission?.products === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Products : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('products', 'products')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Products</label>
                                <input
                                  type="checkbox"
                                  id="products"
                                  name="products"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={productsPermission?.products === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('products', 'request')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Request</label>
                                <input
                                  type="checkbox"
                                  id="request"
                                  name="request"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={productsPermission?.request === "Y" ? true : false}
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="orders" className="all_top_and_bottom_for_input_text"> Orders : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="orders" id="orders" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('orders', 'orders')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="orders">Orders</label>
                                <input
                                  type="checkbox"
                                  id="orders"
                                  name="orders"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={ordersPermission?.orders === "Y" ? true : false}
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Inventory : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('inventory', 'inventory')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Inventory</label>
                                <input
                                  type="checkbox"
                                  id="Inventory"
                                  name="Inventory"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={inventoryPermission?.inventory === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('inventory', 'request')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Request</label>
                                <input
                                  type="checkbox"
                                  id="request"
                                  name="request"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={inventoryPermission?.request === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Services : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('services', 'grooming')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Grooming</label>
                                <input
                                  type="checkbox"
                                  id="grooming"
                                  name="grooming"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesPermission?.grooming === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('services', 'veterinary')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="veterinary">Veterinary</label>
                                <input
                                  type="checkbox"
                                  id="veterinary"
                                  name="veterinary"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesPermission?.veterinary === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('services', 'training')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="training">Training</label>
                                <input
                                  type="checkbox"
                                  id="training"
                                  name="training"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesPermission?.training === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('services', 'boarding')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="boarding">Boarding</label>
                                <input
                                  type="checkbox"
                                  id="boarding"
                                  name="boarding"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesPermission?.boarding === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('services', 'friendly')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="friendly">Friendly</label>
                                <input
                                  type="checkbox"
                                  id="friendly"
                                  name="friendly"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesPermission?.friendly === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('services', 'adoption')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="adoption">Adoption</label>
                                <input
                                  type="checkbox"
                                  id="adoption"
                                  name="adoption"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesPermission?.adoption === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('services', 'dayCare')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="dayCare">Day Care</label>
                                <input
                                  type="checkbox"
                                  id="dayCare"
                                  name="dayCare"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesPermission?.dayCare === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Services Request : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('servicesRequest', 'grooming')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Grooming</label>
                                <input
                                  type="checkbox"
                                  id="grooming"
                                  name="grooming"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesRequestPermission?.grooming === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('servicesRequest', 'veterinary')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="veterinary">Veterinary</label>
                                <input
                                  type="checkbox"
                                  id="veterinary"
                                  name="veterinary"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesRequestPermission?.veterinary === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('servicesRequest', 'training')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="training">Training</label>
                                <input
                                  type="checkbox"
                                  id="training"
                                  name="training"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesRequestPermission?.training === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('servicesRequest', 'boarding')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="boarding">Boarding</label>
                                <input
                                  type="checkbox"
                                  id="boarding"
                                  name="boarding"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesRequestPermission?.boarding === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('servicesRequest', 'adoption')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="adoption">Adoption</label>
                                <input
                                  type="checkbox"
                                  id="adoption"
                                  name="adoption"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesRequestPermission?.adoption === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('servicesRequest', 'dayCare')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="dayCare">Day Care</label>
                                <input
                                  type="checkbox"
                                  id="dayCare"
                                  name="dayCare"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={servicesRequestPermission?.dayCare === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Bookings : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="Bookings" id="Bookings" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('bookings', 'grooming')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Grooming</label>
                                <input
                                  type="checkbox"
                                  id="grooming"
                                  name="grooming"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={bookingsPermission?.grooming === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('bookings', 'veterinary')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="veterinary">Veterinary</label>
                                <input
                                  type="checkbox"
                                  id="veterinary"
                                  name="veterinary"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={bookingsPermission?.veterinary === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('bookings', 'training')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="training">Training</label>
                                <input
                                  type="checkbox"
                                  id="training"
                                  name="training"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={bookingsPermission?.training === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('bookings', 'boarding')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="boarding">Boarding</label>
                                <input
                                  type="checkbox"
                                  id="boarding"
                                  name="boarding"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={bookingsPermission?.boarding === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('bookings', 'dayCare')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="dayCare">Day Care</label>
                                <input
                                  type="checkbox"
                                  id="dayCare"
                                  name="dayCare"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={bookingsPermission?.dayCare === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Insights : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="insights" id="insights" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('insights', 'insights')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="insights">Insights</label>
                                <input
                                  type="checkbox"
                                  id="insights"
                                  name="insights"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={insightsPermission?.insights === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Insurance : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="insurance" id="insurance" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('insurance', 'insurance')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="insights">Insurance</label>
                                <input
                                  type="checkbox"
                                  id="insurance"
                                  name="insurance"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={insurancePermission?.insurance === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Payments : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="payments" id="payments" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('payments', 'grooming')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="products">Grooming</label>
                                <input
                                  type="checkbox"
                                  id="grooming"
                                  name="grooming"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={paymentsPermission?.grooming === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('payments', 'veterinary')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="veterinary">Veterinary</label>
                                <input
                                  type="checkbox"
                                  id="veterinary"
                                  name="veterinary"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={paymentsPermission?.veterinary === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('payments', 'training')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="training">Training</label>
                                <input
                                  type="checkbox"
                                  id="training"
                                  name="training"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={paymentsPermission?.training === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('payments', 'boarding')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="boarding">Boarding</label>
                                <input
                                  type="checkbox"
                                  id="boarding"
                                  name="boarding"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={paymentsPermission?.boarding === "Y" ? true : false}
                                />
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('payments', 'dayCare')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="dayCare">Day Care</label>
                                <input
                                  type="checkbox"
                                  id="dayCare"
                                  name="dayCare"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={paymentsPermission?.dayCare === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Promotion : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="promotion" id="promotion" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('promotion', 'promotion')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="promotion">Promotion</label>
                                <input
                                  type="checkbox"
                                  id="promotion"
                                  name="promotion"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={promotionPermission?.promotion === "Y" ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('promotion', 'request')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="request">Request</label>
                                <input
                                  type="checkbox"
                                  id="request"
                                  name="request"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={promotionPermission?.request === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Rating & Review : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="rating" id="rating" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('rating', 'rating')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="rating">Rating & Review</label>
                                <input
                                  type="checkbox"
                                  id="rating"
                                  name="rating"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={ratingPermission?.rating === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Message : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="message" id="message" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('message', 'message')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="message">Message</label>
                                <input
                                  type="checkbox"
                                  id="message"
                                  name="message"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={messagePermission?.message === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> FAQs : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="faq" id="faq" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('faq', 'faq')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="faq">FAQs</label>
                                <input
                                  type="checkbox"
                                  id="faq"
                                  name="faq"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={faqPermission?.faq === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Notifications : </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="notifications" id="notifications" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handlePermission('notifications', 'notifications')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="notifications">Notifications</label>
                                <input
                                  type="checkbox"
                                  id="notifications"
                                  name="notifications"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={notificationsPermission?.notifications === "Y" ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>
                  </div>

                </div>

                <div className="inventory_edit_submit_btn">
                  <button type="submit" className="btn btn-primary inventory_save_button" > Save </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LoadingEffect isLoading={isLoading} />
    </div>
  );
};

export default UserListEditPage;
