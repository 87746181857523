import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Select, Upload, Flex } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "../ServiceList.css";
import Loading from "../../../components/Loading/LoadingEffect";
import {ucfirst, commonList} from '../../../controllers/common';
import { addEditData } from "../../../controllers/services/serviceController"
import { vendorListByService as vendorlist} from "../../../controllers/vendors/vendorsController"
import { list as serviceList } from "../../../controllers/categories/services"
import { beforeUpload } from '../../../controllers/common';

const ProductListAddEdit = ({ show, editdata, setEditData, setAddEditPopup }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
    
  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
   const handleCancel = () => {
    setEditData("");
    setFormData("");
    setImageList("");
    setAddEditPopup(false);
  };
  /*********************************************************
  *  This function is use to handle success notification show
  *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      duration : 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };//End
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = (msg="") => {
    notification.open({
      message: "Opps!",
      description: error?.formError || msg,
      placement: "topRight",
      duration : 2,
      icon: <CloseOutlined  style={{color : "red"}} />,
    });
  };//End
  const [isLoading, setIsLoading] = useState(false);
  const [error, serError] = useState([]);
  const [success, serSuccess] = useState([]);
  const [formData, setFormData] = useState("");
  const [imageList, setImageList] = useState("");
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [ALLCATEGORY, setAllCategory] = useState([]);
  const [ALLSUBCATEGORY, setAllSubCategory] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);
  const [currentService, setCurrentService] = useState(''); 

/*********************************************************
  *  This function is use to get all vendor list
  *********************************************************/
  const getVendorList = async () => {
    try {
      const options ={
        category_id : currentService,
        condition : { status : "A"},
        select : {
          vendor_id : false, 
          vendor_email : false,
          creation_date : false,
          createdAt : false,
          creation_ip : false,
          vendor_address : false,
          vendor_city : false,
          vendor_country : false,
          vendor_image : false,
          vendor_otp : false,
          vendor_phone : false,
          vendor_pincode : false,
          vendor_state : false,
          created_by : false,
          update_date : false,
          update_ip : false,
          updatedAt : false,
          updated_by : false,
          vendor_commission : false,
          vendor_country_code : false,
          vendor_gender : false,
          vendor_revenue : false,
          vendor_street : false
        },
        sort : {vendor_first_name : 1},
      }
      const res = await vendorlist(options);
      if(res.status === true){
        setAllVendor(res?.result)
      }else{
        setAllVendor([]);
      }
    } catch (error) {
      setAllVendor([]);
    }
  };

  /*********************************************************
  *  This function is use to get service id
  *********************************************************/
  const getService = async () => {
    try {
      const options = {
        condition : { name : 'Grooming' },
        type : "single",
        select : {name : true }
      }
      const currentService = await serviceList(options);
      if(currentService.status === true){
        setCurrentService(currentService?.result?._id);
        getVendorList();
      }
    } catch (error) {
      
    }
  }

  const getCommonListData = async (vendorId) => {
    try{
      setAllStore([]);
      setAllBranch([]);
      setAllCategory([]);
      // setAllSubCategory([]);
      const options ={
        vendorId : vendorId,
        list : ["service_category","store","branch"],
        serviceCat : { select : { name : true} },
        serviceSubCat : { select : { category : true, name : true } },
        store : { select : { shop_name : true } },
        branch : { select : { storeData : true, branch_name : true } }
      }
      const res =await commonList(options);
      if(res?.status === true){
        setAllStore(res?.result?.store);
        setAllBranch(res?.result?.branch);
        setAllCategory(res?.result?.serviceCat);
        // setAllSubCategory(res?.result?.serviceSubCat);

        if(editdata?.branch){
          const filterArray = res?.result?.branch.filter(val=> val.storeData == editdata?.store?._id );
          setFilterBranch(filterArray);
        }
        // if(editdata.subcategory){
        //   const filterArray = res?.result?.serviceSubCat.filter(val=> val.category == editdata?.category?._id )
        //   setFilterSubCat(filterArray);
        // }
      }
      setIsLoading(false);
    } catch (error){
      setIsLoading(false);
    }
  }

  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e)=>{
    const {name, value} = e.target;
    if(name === 'vendor'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      // alert(value)
      getCommonListData(value);
      // setFilterBranch([]);
      // setFilterSubCat([]);
    } else if(name === 'store'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      const filterArray = ALLBRANCH.filter(val=> val.storeData == value )
      setFilterBranch(filterArray);
      // setFilterSubCat([]);
    } else if(name === 'category'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      // const filterArray = ALLSUBCATEGORY.filter(val=> val.category == value )
      // setFilterSubCat(filterArray);
    } else{
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }))
    }
    serError((pre)=>({
      ...pre,
      [e.target.name] : ""
    }))
  }; //End

  const handleTypeClick = (name) => {
    try {
      setFormData((pre) => ({
        ...pre,
        [name] : formData[name] === "Y"?"N":"Y",
      }));
      setEditData((pre) => ({
        ...pre,
        [name] : formData[name] === "Y"?"N":"Y",
      }));
      
      serError((preError)=>({
        ...preError,
        type : ""
      }));
    } catch (error) {
      
    }
  }
  
  /*********************************************************
  *  This function is use to handle image object
  *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj)
  }
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e)=>{
    e.preventDefault();
    setIsLoading(true);
    const {in_store,type_mobile,pick_drop} = editdata;
    const form = new FormData(e.target);
    form.append("image", imageList?imageList:null);
    if(!editdata._id){
      form.append("in_store", formData?.in_store || 'N');
      form.append("type_mobile", formData?.type_mobile || 'N');
      form.append("pick_drop", formData?.pick_drop || 'N');
    } else{
      form.append("in_store", in_store || 'N');
      form.append("type_mobile", type_mobile || 'N');
      form.append("pick_drop", pick_drop || 'N');
    }
    
    form.append("category", currentService || 'N');
    const storeId = form.get('store');
    const filterArray = ALLBRANCH.filter(val=> val.storeData == storeId )
    form.append('branch',filterArray[0]?._id || '');

    form.append("ipAddress", sessionStorage.getItem('IP_ADDRESS'));
    const isValidate = validateFormData(form)
    if(isValidate){
      const res = await addEditData(form);
      if(res.status === true){
        setFormData("");
        setImageList("");
        handleSuccessMsg();
        handleCancel();
      }else{
        serError((pre)=>({
          ...pre,
          formError : res?.message
        }))
        handleErrorMsg();
        // setFormData("");
        // setImageList("");
        // handleCancel();
      }
      setIsLoading(false);
    }else{
      setIsLoading(false);
      handleErrorMsg('Please fill required fields.');
    }
  } //End
  /*********************************************************
  *  This function is use to validate form data before submit
  *********************************************************/
  const validateFormData = (formData) => {
    console.log('details',formData.get('details'));
    if(formData.get('vendor') === 'undefined' || formData.get('vendor') === null || formData.get('vendor').trim() === ''){
      serError((preError)=>({
        ...preError,
        vendor : "Vendor is required"
      }));
      return false;
    } else if(formData.get('store') === 'undefined' || formData.get('store') === null || formData.get('store').trim() === ''){
      serError((preError)=>({
        ...preError,
        store : "Store is required"
      }));
      return false;
    } else if(formData.get('branch') === 'undefined' || formData.get('branch') === null || formData.get('branch').trim() === ''){
      serError((preError)=>({
        ...preError,
        branch : "Branch is required"
      }));
      return false;
    } else if(formData.get('category') === 'undefined' || formData.get('category') === null || formData.get('category').trim() === ''){
      serError((preError)=>({
        ...preError,
        category : "Category is required"
      }));
      return false;
    // } else if(formData.get('subcategory') === 'undefined' || formData.get('subcategory') === null || formData.get('subcategory').trim() === ''){
    //   serError((preError)=>({
    //     ...preError,
    //     subcategory : "Sub category is required"
    //   }));
    //   return false;
    } else if(formData.get('name') === 'undefined' || formData.get('name') === null || formData.get('name').trim() === ''){
      serError((preError)=>({
        ...preError,
        name : "Service name is required"
      }));
      return false;
    } else if(formData.get('details') === 'undefined' || formData.get('details') === null || formData.get('details').trim() === ''){
      serError((preError)=>({
        ...preError,
        details : "Service details is required"
      }));
      return false;
    } else if(formData.get('price') === 'undefined' || formData.get('price') === null || formData.get('price').trim() === ''){
      serError((preError)=>({
        ...preError,
        price : "Price is required"
      }));
      return false;
    } else if(formData.get('in_store') === 'N' && formData.get('type_mobile') === 'N' && formData.get('pick_drop').trim() === 'N'){
      serError((preError)=>({
        ...preError,
        type : "Please select atleast one option."
      }));
      return false;
    } else if(!editdata?._id && formData.get('image') === 'null'){
      serError((preError)=>({
        ...preError,
        image : "Image is required"
      }));
      return false;
    }else {
      return true;
    }
  }; //End

  useEffect(()=>{
    getService();
    // getVendorList();
    if(editdata?._id){
      setIsLoading(true)
      getCommonListData(editdata?.vendor)
    }
  },[show]);
  return ( 
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              {editdata?._id?"Edit":"Add"} Service
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <input type="hidden" name="editId" id="editId" value={editdata?._id}/>
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="vendor" className="all_lable_for_vendor_dashbaord"> Vendor </label>
                      <select id="vendor" name="vendor" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a vendor</option>
                        {ALLVENDOR && ALLVENDOR.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id} 
                            selected={editdata?.vendor === items._id ? true:false}>
                            {`${ucfirst(items.vendor_first_name)} ${ucfirst(items.vendor_last_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.vendor?<p style={{color:"red"}}>{error.vendor}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="store" className="all_lable_for_vendor_dashbaord"> Store </label>
                      <select id="store" name="store" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a store</option>
                        {ALLSTORE && ALLSTORE.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id}
                            selected={editdata?.store?._id === items._id ? true:false}
                          >
                            {`${ucfirst(items.shop_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.store?<p style={{color:"red"}}>{error.store}</p>:""}
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="phone" className="all_lable_for_vendor_dashbaord">
                        <span style={{ color: "red" }}>*</span>Fulfillment Type :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('in_store')}>
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="male">In Store</label>
                                  <input
                                    type="radio"
                                    id="in_store"
                                    name="in_store"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={formData?.in_store === 'Y'?true:false || editdata.in_store === 'Y'?true:false}
                                  />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('type_mobile')}>
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="type_mobile">Mobile</label>
                                  <input
                                    type="radio"
                                    id="type_mobile"
                                    name="type_mobile"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={formData?.type_mobile === 'Y'?true:false || editdata.type_mobile === 'Y'?true:false}
                                  />
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('pick_drop')}>
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="pick_drop">Pick & Drop</label>
                                  <input
                                    type="radio"
                                    id="pick_drop"
                                    name="pick_drop"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={formData?.pick_drop === 'Y'?true:false || editdata.pick_drop === 'Y'?true:false}
                                  />
                              </div>
                            </div>
                            {error?.type?<p style={{color:"red"}}>{error.type}</p>:""}
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="name" className="all_lable_for_vendor_dashbaord"> Service Name </label>
                      <input
                        type="text"
                        class="form-control store_input_field"
                        id="name"
                        placeholder="Enter service name"
                        name="name"
                        value={formData?.name?formData?.name:editdata?.name}
                        onChange={handleChnage}
                      />
                      {error?.name?<p style={{color:"red"}}>{error.name}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="details" className="all_lable_for_vendor_dashbaord"> Details </label>
                      <textarea class="form-control store_input_field"
                        id="details"
                        placeholder="Enter service details"
                        name="details"
                        onChange={handleChnage}>{formData?.details?formData?.details:editdata?.details}</textarea>
                      {error?.details?<p style={{color:"red"}}>{error.details}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="price" className="all_lable_for_vendor_dashbaord"> Price </label>
                      <input
                        type="number"
                        // class="form-control"
                        class="form-control store_input_field"
                        id="price"
                        placeholder="Enter price"
                        name="price"
                        value={formData?.price?formData?.price:editdata?.price}
                        onChange={handleChnage}
                      />
                      {error?.price?<p style={{color:"red"}}>{error.price}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="all_lable_for_vendor_dashbaord" >Image</label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                          beforeUpload={beforeUpload}
                        >
                          <button className="inventroy_edit_page_antd_uplaod_button" type="button">
                            <div className="inventroy_edit_page_antd_choose_file">Choose File</div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{color:"blue"}} className="all_lable_for_vendor_dashbaord">*Note: Please choose an image of JPEG/JPG/PNG format file.</p>
                      {error?.image?<p style={{color:"red"}}>{error.image}</p>:""}
                    </div>

                  </div>

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-danger "
                    >
                      {editdata._id?"Save Changes":'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
          <Loading
            isLoading={isLoading}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ProductListAddEdit;
