import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Switch, Select, notification } from "antd";
import { TbCategory } from "react-icons/tb";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";

import Topnavbar  from '../../DashBaord/Top_navbar'
import DeletePopUp from "./DeletePopUp";
import AddEditData from "./AddEdit"
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaPlusSquare } from "react-icons/fa";
import "./List.css";

import { ASSETS_BASE_URL } from '../../../config/constants'
import moment from 'moment';
import { getPage } from '../../../controllers/common';
import { list, chnageStatus } from "../../../controllers/subcategories/services";
import {list as categoryList } from "../../../controllers/categories/services";

import SkeltonList from "../../../components/SkeltonEffect/list";

function CategoriesProductList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CategoriesProductList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {
  const [deletePopUpCategory, setDeletePopUpCategory] = useState(false);
  const handleProductDeleteToggle = () => {
    setDeletePopUpCategory(!deletePopUpCategory);
  };

/********************** Integratin Stare Here **********************/
  const targetRef = useRef(null);
  const [deteteId, setDeteteId] = useState('');
  const [categoryData, setcategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from : "",
    to : "",
    filter_by : "",
    search : ""
  })//End
  const [showRequest, setShowRequest] = useState("");
  const handleShowRequest = (value) =>{
    setShowRequest(value);
  }
  
  const [showAddEditPopup, setAddEditPopup] = useState(false);
  const [EDITDATA, setEditData] = useState([]);
  /*********************************************************
  *  This function is use to handle notification
  *********************************************************/
  const handleNotification = (status, msg) => {
    notification.open({
      message: status,
      description: msg,
      placement: "topRight",
      duration : 2
    });
   
  };//End
  /*********************************************************
  *  This function is use to handle Add/Edit Popup
  *********************************************************/
  const handleAddEditData = ()=>{
    setAddEditPopup(true);
  };//End

  /*********************************************************
  *  This function is use to fetch category list
  *********************************************************/
  const getList = async (source)=>{
    setListData([]);
    const options = {
      type : "",
      condition : { 
        ...(filter?.from ? {
          createdAt: {
            '$gte': moment(`${filter?.from?.$y}-${filter?.from?.$M + 1}-${filter?.from?.$D}`).format('YYYY-MM-DD'),
            '$lte': moment(`${filter?.to?.$y}-0${filter?.to?.$M + 1}-${filter?.to?.$D + 1}`).format('YYYY-MM-DD'),
          },
        } : null),
        ...(filter?.search?{[filter?.filter_by]:{ $regex: filter.search, $options: 'i' }}:null),
        ...(showRequest?{status : showRequest}:null)
      },
      select    : { },
      sort      : {"_id" : -1},
      populate  : { key : "category", select : "name image" },
      skip      : SKIP?SKIP:0,
      limit     : LIMIT?LIMIT:10
    }
    const listData = await list(options,source);
  if(listData.status === true && listData?.result?.length !== 0){
      setListData(listData?.result);
      const countOptions = {
        type : "count",
        condition : { }
      }
      const count = await list(countOptions,source);
      setTotalPages(getPage(count?.result)); 
    }else{
      setListData([{name : "No Data Found"}]);
    }
  };//End

  /*********************************************************
  *  This function is use to handle chnage status
  *********************************************************/
  const handleStatus = async (item)=>{
    const option = {
     url : `subcategories/services/change-status`,
     postData : {
       id      : item?._id,
       status  : item.status === 'A'?"I":"A"},
    }
    const res = await chnageStatus(option);
    if(res.status === true){
     getList();
     handleNotification('Success!','Status Updated.')
    } else{
     handleNotification('Error!',res?.message)
    }
   };//End

   /*********************************************************
  *  This function is use to fetch category list
  *********************************************************/
  const getCategoryList = async ()=>{
    const options = {
      type : "",
      condition : { status : "A" },
      select    : { name : true },
      sort      : {name : 1},
      populate  : { key : "", select : "" }
    }
    const listData = await categoryList(options);
    if(listData.status === true){
      setcategory(listData?.result);
    }else{
      setcategory([]);
    }
  };//End
  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });

  };

  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
 
 useEffect(()=>{
   const source = axios.CancelToken.source();
   getList(source);
   return () => {
     source.cancel('Request canceled due to component unmount or page change');
    };
  },[currentPage,showAddEditPopup,deletePopUpCategory,showRequest]);
  
  useEffect(()=>{
    getCategoryList();
  },[])
/********************** Integratin End Here **********************/
 return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Topnavbar title="Service Sub-Category"></Topnavbar>
        <div className="order_now_card_tilte" >
          <div class="card">
            <div class="card-body categories_main_div">
              <div className="categories_tb_select">
                <TbCategory />
                <Select 
                  name="show"
                  id="show"
                  className="product_select_tag_rating_review" 
                  style={{ width: 120 }}
                  bordered={false}
                  onChange={handleShowRequest}
                  defaultValue={showRequest}
                >
                  <Select.Option value="" label="All" selected={true}>All</Select.Option>
                  <Select.Option value="A" label="Active" >Active</Select.Option>
                  <Select.Option value="I" label="Inactive" >Inactive</Select.Option>
                  <Select.Option value="D" label="Inactive" >Deleted</Select.Option>
                </Select>
              </div>
              <div className="promotions_filters_div">
                <div className="fa_filter_rating_review">
                  <img
                    src={ASSETS_BASE_URL + "./images/Filter.svg"}
                    
                    alt=""
                    className="user_iconses"
                  />

                  <a href="#" className="filter_btn_invontry_rating_review">
                    Filters
                  </a>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-dark"
                    onClick={handleAddEditData}
                  >
                    <FaPlusSquare className="Promotions_faPlus_Squares" />
                    <span className="request_new_categories">Add New</span>
                  </button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <CategoriesProductList>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Category Name</th>
                              <th>Sub Category</th>
                              <th>Created on</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                          {ALLLISTDATA.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>
                              <tr key={index}>
                                <td>
                                  <div className="admin_prodcut_list_span">  
                                    <img
                                      src={`${ASSETS_BASE_URL}${items?.category?.image}`}
                                      alt=""
                                    />
                                    <span className="admin_prodcut_list_span_name">
                                    {items?.category?.name}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="admin_prodcut_list_span">  
                                    <img
                                      src={`${ASSETS_BASE_URL}${items?.image}`}
                                      alt=""
                                    />
                                    <span className="admin_prodcut_list_span_name">
                                    {items?.name}
                                    </span>
                                  </div>
                                  </td>
                                <td>{moment(`${items.createdAt}`).format('DD/MM/YYYY, h:m A')}</td>
                                <td>
                                  {items._id && items.status !== 'D' && (
                                    <Switch 
                                    className="categories_switch_" 
                                    checked={items?.status === 'A'?true:false}
                                    onClick={()=>handleStatus(items)}
                                  />)
                                  }
                                  {items.status === 'D' && (<p className="text-danger">Deleted</p>)}
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={
                                      <Menu>
                                        {items._id && (
                                          <>
                                          <Menu.Item key="1" onClick={()=>{
                                            setEditData(items)
                                            handleAddEditData()
                                          }}>
                                            <FaEdit />
                                            <span className="show_span_edit">Edit</span>
                                          </Menu.Item>
                                          <Menu.Item key="2" onClick={()=>{
                                            setDeteteId(items?._id)
                                            handleProductDeleteToggle()
                                          }}>
                                            <MdDelete /> <span className="show_span">Remove</span>
                                          </Menu.Item>
                                          </>
                                        )}
                                      </Menu>
                                    }
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </Dropdown>
                                </td>
                              </tr>
                            </>))) : (<SkeltonList row={5} col={5} />)}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CategoriesProductList>
          </Box>
        </div>

        <DeletePopUp
          id={deteteId}
          deletePopUpCategory={deletePopUpCategory}
          deleteProductToggle={handleProductDeleteToggle}
          setDeteteId={setDeteteId}
        />
        <AddEditData
         show={showAddEditPopup}
         editdata={EDITDATA}
         setEditData={setEditData}
         setAddEditPopup = {setAddEditPopup}
         categories = {categoryData}
        />

      </div>
    </>
  );
}
