import { API_ADMIN_URL } from "../../config/constants";
import { postRequest, fetchIpAddress } from "../API";

/*********************************************************
* Function Name : createPet for dog adoption services
* Description   : This function is used for createPet dog adoption services
* By            : Noor Alam
* Date          : 29April-2024 
*********************************************************/
export const createPet = async (options) => {
    try {
        const params = {
            url: `${API_ADMIN_URL}servicesmaster/dog-adoption/create`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: "Under maintenance, Please try after some time." };
    }
}


/*********************************************************
* Function Name : dogAdoptionList
* Description   : This function is used for createStoreList for dog Friendly
* By            : Noor Alam
* Date          : 29 April-2024 
*********************************************************/
export const dogAdoptionList = async (options) => {
    try {
        const params = {
            url: `${API_ADMIN_URL}servicesmaster/dog-adoption/list`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response.result };
        } else {
            return { status: false, result: res?.reponse?.data?.statusMessage };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: "under maintaince, please try after some time." }
    }
}


/*********************************************************
* Function Name : changeStatus
* Description   : This function is used for change status for dog Friendly
* By            :  Noor Alam
* Date          : 29April-2024 
*********************************************************/
export const changeStatus = async (options) => {
    try {
        const params = {
            url: `${API_ADMIN_URL}servicesmaster/dog-adoption/status`,
            postData: {
                ...options,
                ipAddress: await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.changeStatus };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
};//End of Function

/*********************************************************
* Function Name : blukUpload
* Description   : This function is used for bulk upload day care service
* By            : Afsar Ali
* Date          : 09-08-2024 
*********************************************************/
export const blukUpload = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/dog-adoption/bluk-upload`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function