import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Upload } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "./List.css";

import { addEditData } from "../../../controllers/subcategories/services"

const AddEdit = ({ show, editdata, setEditData, setAddEditPopup, categories }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
    
  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
   const handleCancel = () => {
    setEditData("");
    setFormData("");
    setImageList("");
    setAddEditPopup(false);
  };
  /*********************************************************
  *  This function is use to handle success notification show
  *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  };//End
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = () => {
    notification.open({
      message: "Opps!",
      description: error?.formError,
      placement: "topRight",
      icon: <CloseOutlined  twoToneColor="#fc2339" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  };//End
  const [error, serError] = useState([]);
  const [success, serSuccess] = useState([]);
  const [formData, setFormData] = useState({
    status : "A"
  });
  const [imageList, setImageList] = useState("");
  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e)=>{
    setFormData((pre)=>({
      ...pre,
      [e.target.name] : e.target.value
    }))

    serError((pre)=>({
      ...pre,
      [e.target.name] : ""
    }))
  }; //End

  /*********************************************************
  *  This function is use to handle image object
  *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj)
  }
  
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e)=>{
    e.preventDefault();
    // console.log('formData',formData);
    // return false;
    try{
      const form = new FormData();
      if(editdata?._id){
        form.append("editId", editdata?._id);
      }
      form.append("name", formData?.name?formData?.name:editdata?.name);
      form.append("category", formData?.category?formData?.category:editdata?.category?._id);
      form.append("details", formData?.details?formData?.details:editdata?.details);
      if(imageList){
        form.append("image", imageList?imageList:null);
      }

      form.append("status", "A");
      form.append("ipAddress", localStorage.getItem('IP_ADDRESS'));
      const isValidate = validateFormData(form)
      if(isValidate){
        const params = {
          url : `subcategories/services/addeditdata`,
          postData : form
        }
        const res = await addEditData(params);
        if(res.status === true){
          setFormData("");
          setImageList("");
          handleSuccessMsg();
          handleCancel();
        }else{
          serError((pre)=>({
            ...pre,
            formError : res?.message
          }))
          handleErrorMsg();
          setFormData("");
          setImageList("");
          handleCancel();
        }
      }
    
    } catch (error){
      serError((pre)=>({
        ...pre,
        formError : "Opps! Fail to perform."
      }))
    }
  } //End

  /*********************************************************
  *  This function is use to validate form data before submit
  *********************************************************/
  const validateFormData = (formData) => {
    if(formData.get('name') === 'undefined' || formData.get('name') === null || formData.get('name').trim() === ''){
      serError((preError)=>({
        ...preError,
        name : "Name is required"
      }));
      return false;
    } else if(formData.get('details') === 'undefined' || formData.get('details') === null || formData.get('details').trim() === ''){
      serError((preError)=>({
        ...preError,
        details : "details is required"
      }));
      return false;
    } else if(!editdata?._id && formData.get('image') === 'null'){
      serError((preError)=>({
        ...preError,
        image : "Image is required"
      }));
      return false;
    }else {
      return true;
    }
  }; //End
  
  return ( 
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              {editdata?._id?"Edit":"Add"} Sub-Category
            </Typography>
          </div>
          <Typography variant="body1" component="div" className="my_delete_popup_paragraph" >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">

                  <div class="form-group">
                    <div class="form-group">
                      <label for="category" className="categories_open_popup_label_name" >
                        Select Category
                      </label>
                      <select name="category" id="category" className="form-control categories_select_tg_enter_product" onChange={handleChnage}>
                        <option value="" >Select Category</option>
                        {categories && categories.map((item, index)=>(
                          <option value={item?._id}  selected={editdata?.category?._id === item?._id?true:false} > {item?.name}</option>
                        ))}
                      </select>
                      {error?.category?<p style={{color:"red"}}>{error.category}</p>:""}
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="cannedProducts"
                        className="categories_open_popup_label_name"
                      >
                        Sub Category
                      </label>
                      <input
                        type="text"
                        class="form-control categories_select_tg_enter_product"
                        id="cannedProducts"
                        placeholder="Enter Sub Category Name"
                        name="name"
                        value={formData?.name?formData?.name:editdata?.name}
                        onChange={handleChnage}
                      />
                      {error?.name?<p style={{color:"red"}}>{error.name}</p>:""}
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="formGroupExampleInput"
                        className="categories_open_popup_label_name"
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        class="form-control categories_select_tg_enter_product"
                        id="formGroupExampleInput"
                        placeholder="Enter Description"
                        name="details"
                        value={formData?.details?formData?.details:editdata?.details}
                        onChange={handleChnage}
                      />
                      {error?.details?<p style={{color:"red"}}>{error.details}</p>:""}
                    </div>
                  </div>

                  <div className="inventory_edit_page_popup">
                    <div class="form-group inventory_popup_create_promocodes_product_img">
                      <label
                        for="formGroupExampleInput"
                        className="categories_open_popup_label_name"
                      >
                        Product Image
                      </label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                        >
                          <button className="inventroy_edit_page_antd_uplaod_button" type="button">
                            <div className="inventroy_edit_page_antd_choose_file">Choose File</div>
                          </button>
                        </Upload>
                      </div>
                      {error?.image?<p style={{color:"red"}}>{error.image}</p>:""}
                    </div>
                  </div>
                  
                

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={handleSubmit}
                    >
                      {editdata._id?"Save Changes":'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>          
        </div>
      </div>
    </Dialog>
  );
};

export default AddEdit;
