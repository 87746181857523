import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { deleteCategory } from "../../../controllers/subcategories/products"

const CategoryProductDeletePopUp = ({
  id,
  deletePopUpCategory,
  deleteProductToggle,
  setDeteteId
}) => {
  const handleCancel = () => {
    setDeteteId("")
    deleteProductToggle();
  };

  const handleDelete = async () => {
    const param ={
      url :`subcategories/services/delete`,
      postData : { id : id } 
    }
    const res = await deleteCategory(param);
    if(res.status === true){
      notification.open({
        message: "Deleted!",
        description: " Data Deleted Successfully .",
        placement: "topRight",
        duration : 2,
        icon: <DeleteOutlined style={{ color: "red" }} />,
      });
    }else{
      notification.open({
        message: "Opps!!",
        description: " Please try again.",
        placement: "topRight",
        duration : 2,
        icon: <DeleteOutlined style={{ color: "red" }} />,
      });
    }
    setDeteteId("");
    handleCancel();
  };

  return (
    <Dialog
      open={deletePopUpCategory}
      onClose={deletePopUpCategory}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={deleteProductToggle}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you want to remove this item?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default CategoryProductDeletePopUp;
