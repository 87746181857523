import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { deleteNotifications } from "../../controllers/notifications/notificationsControllers";

const NotificationDeletePopUp = ({ popUpOpen, togglePopUp, deleteId, setDeleteId }) => {
  const handleCancel = () => {
    togglePopUp();
  };

  const handleDelete = async() => {
    try {
      const options = {
        url: `notifications/delete-notifications`,
        postData: {
          id : deleteId,
      }
    }
    const res = await deleteNotifications(options);
    if(res.status === true){
      notification.open({
        message: "User Notifiactions deleted successfully.",
        description:  `Notifiactions delete successfully.`,
        placement: "topRight", 
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        duration:2
      });
      setDeleteId("");
      togglePopUp()
    } else{
      notification.open({
        message: "Opps!",
        description:  `${res?.message}`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
    }catch(error){
      notification.open({
        message: "Opps!",
        description:  `Operation not perform yet! please try in some time.`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
  };

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you want to remove this item?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default NotificationDeletePopUp;
