import React, {useState, useEffect} from "react";
import "./main_dashboard.css";
import Top_navbar from "./Top_navbar";
import Recent_order from "./Recent_order";
import { Select, Skeleton } from "antd";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {getDashboardData} from '../../controllers/users/usersControllet';
import Box from "@mui/material/Box";

import * as XLSX from 'xlsx';
import moment from "moment";

const Dashboard = () => {
  // User's Count Section
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setuserDate] = useState('');
  const [totaluser, setTotalUser] = useState('');
  const [orderData, setOrderDate] = useState('');
  const [revenueData, setRevenueDate] = useState('');
  const [salesData, setSalesDate] = useState('');
  const [ordersDetails, setOrdersDetails] = useState('');

  const [TOTALUSERSCOUNT, setTotalUsersCount] = useState('')
  const [TOTALORDERSCOUNT, setTotalordersCount] = useState('')
  const [TOTALREVENUECOUNT, setTotalRevenueCount] = useState('')
  const [TOTALSALECOUNT, setTotalSaleCount] = useState('');

  const [dataSheet1, setDataSheet1] = useState([]);
  const [dataSheet2, setDataSheet2] = useState([]);
  const [dataSheet3, setDataSheet3] = useState([]);
  const [dataSheet4, setDataSheet4] = useState([]);
  const [dataSheet5, setDataSheet5] = useState([]);
  const [dataSheet6, setDataSheet6] = useState([]);
  const [dataSheet7, setDataSheet7] = useState([]);

  const [usersShowRequest, setusersShowRequest] = useState("");
  
  const handleUserShowRequest =async (value) => {
    if(value === 'Active'){
      setusersShowRequest("A");
      const activeUser = userData.find(item => item.status === "A")?.count;
      setTotalUsersCount(activeUser || 0);
    } else if(value === 'Inactive'){
      setusersShowRequest("I");
      const inactiveUser = userData.find(item => item.status === "I")?.count;
      setTotalUsersCount(inactiveUser || 0);
    } else if(value === 'Delete'){
      setusersShowRequest("D");
      const deletedUser = userData.find(item => item.status === "D")?.count;
      setTotalUsersCount(deletedUser  || 0);
    } else{
      setusersShowRequest("");
      setTotalUsersCount(totaluser);
    }
  }
  const handleOrdersShowRequest =async (value) => {
    if(value === 'all'){
      setTotalordersCount(orderData.totalOrder);
    } else {
      setTotalordersCount(orderData[value]);
    }
  }
  const handleRevenueShowRequest =async (value) => {
    if(value === 'all'){
      setTotalRevenueCount(revenueData.totalRevenue);
    } else {
      setTotalRevenueCount(revenueData[value]);
    }
  }
  const handleSaleShowRequest =async (value) => {
    if(value === 'all'){
      setTotalSaleCount(salesData.totalSale);
    } else {
      setTotalSaleCount(salesData[value]);
    }
  }
 

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await getDashboardData();
      prepareDashboardData(res);
      setuserDate(res?.users || '');
      setTotalUsersCount(res?.totalUser || 0)
      setTotalUser(res?.totalUser || 0)
  
      setOrderDate(res?.ordersCount || '')
      setTotalordersCount(res?.ordersCount.totalOrder || 0);
  
      setRevenueDate(res?.revenue || '');
      setTotalRevenueCount(res?.revenue?.totalRevenue || 0)
  
      setSalesDate(res?.sales || '');
      setTotalSaleCount(res?.sales?.totalSale || 0);
  
      setOrdersDetails(res?.ordersList || [] );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    // console.log('res',res);
  }
  useEffect(()=>{
    getData();
  }, [])
   
  const prepareDashboardData = (data) =>{
    const {totalUser, users, ordersCount, ordersList, revenue, sales }= data;
    const {shop,grooming,veterinary,boarding,training,daycare} = ordersList;
    const activeUser = users.find(item => item.status === "A")?.count;
    const inactiveUser = users.find(item => item.status === "I")?.count;
    const deletedUser = users.find(item => item.status === "D")?.count;

    const sheet_01 = [
      { "Label" : "Active Users", "Data" : activeUser.toFixed(2)}, 
      { "Label" : "Inactive Users", "Data" : inactiveUser.toFixed(2)}, 
      { "Label" : "Deleted Users", "Data" : deletedUser.toFixed(2)}, 
      { "Label" : "Total Users", "Data" : totalUser.toFixed(2)}, 
      {},
      { "Label" : "Total Shop Orders", "Data" : ordersCount.shop.toFixed(2)},
      { "Label" : "Total Grooming Orders", "Data" : ordersCount.grooming.toFixed(2)},
      { "Label" : "Total Boarding Orders", "Data" : ordersCount.boarding.toFixed(2)},
      { "Label" : "Total Veterinary Orders", "Data" : ordersCount.veterinary.toFixed(2)},
      { "Label" : "Total Training Orders", "Data" : ordersCount.training.toFixed(2)},
      { "Label" : "Total Day Care Orders", "Data" : ordersCount.daycare.toFixed(2)},
      { "Label" : "Total Orders", "Data" : ordersCount.totalOrder.toFixed(2)},
      {},
      { "Label" : "Total Shop Revenue", "Data" : revenue.shop.toFixed(2)},
      { "Label" : "Total Grooming Revenue", "Data" : revenue.grooming.toFixed(2)},
      { "Label" : "Total Boarding Revenue", "Data" : revenue.boarding.toFixed(2)},
      { "Label" : "Total Veterinary Revenue", "Data" : revenue.veterinary.toFixed(2)},
      { "Label" : "Total Training Revenue", "Data" : revenue.training.toFixed(2)},
      { "Label" : "Total Day Care Revenue", "Data" : revenue.daycare.toFixed(2)},
      { "Label" : "Total Revenue", "Data" : revenue.totalRevenue.toFixed(2)},
      {},
      { "Label" : "Total Shop Sales", "Data" : sales.shop.toFixed(2)},
      { "Label" : "Total Grooming Sales", "Data" : sales.grooming.toFixed(2)},
      { "Label" : "Total Boarding Sales", "Data" : sales.boarding.toFixed(2)},
      { "Label" : "Total Veterinary Sales", "Data" : sales.veterinary.toFixed(2)},
      { "Label" : "Total Training Sales", "Data" : sales.training.toFixed(2)},
      { "Label" : "Total Day Care Sales", "Data" : sales.daycare.toFixed(2)},
      { "Label" : "Total Sales", "Data" : sales.totalSale.toFixed(2)},
      {}
    ];
    setDataSheet1(sheet_01);

    const shopSheet = shop.map((items)=>{
      return {
        "order Id" : items.order_seq_id,
        "Store" : items?.shopId?.shop_name,
        "Date" : moment(`${items?.createdAt}`).format('DD/MM/YYYY, h:m A'),
        "Product" : items?.orderdetails?.[0]?.productId
        ? `${items.orderdetails.length} Item${items.orderdetails.length > 1 ? 's' : ''}`
        : items?.bookingdetails?.[0]?.services?.name,
        "Phone" : `${items?.userData?.country_code} ${items?.userData?.phone}`,
        "Status" : items.status
      }
    });
    setDataSheet2(shopSheet);

    const groomingSheet = grooming.map((items)=>{
      return {
        "Booking Id" : items.booking_seq_id,
        "Store" : items?.shopId?.shop_name,
        "Date" : moment(`${items?.createdAt}`).format('DD/MM/YYYY, hh:mm A'),
        "Product" : items?.orderdetails?.[0]?.productId
        ? `${items.orderdetails.length} Item${items.orderdetails.length > 1 ? 's' : ''}`
        : items?.bookingdetails?.[0]?.services?.name,
        "Phone" : `${items?.userData?.country_code} ${items?.userData?.phone}`,
        "Status" : items.status
      }
    });
    setDataSheet3(groomingSheet);

    const veterinarySheet = veterinary.map((items)=>{
      return {
        "Booking Id" : items.booking_seq_id,
        "Store" : items?.shopId?.shop_name,
        "Date" : moment(`${items?.createdAt}`).format('DD/MM/YYYY, hh:mm A'),
        "Product" : items?.orderdetails?.[0]?.productId
        ? `${items.orderdetails.length} Item${items.orderdetails.length > 1 ? 's' : ''}`
        : items?.bookingdetails?.[0]?.services?.name,
        "Phone" : `${items?.userData?.country_code} ${items?.userData?.phone}`,
        "Status" : items.status
      }
    });
    setDataSheet4(veterinarySheet);

    const boardingSheet = boarding.map((items)=>{
      return {
        "Booking Id" : items.booking_seq_id,
        "Store" : items?.shopId?.shop_name,
        "Date" : moment(`${items?.createdAt}`).format('DD/MM/YYYY, hh:mm A'),
        "Product" : items?.orderdetails?.[0]?.productId
        ? `${items.orderdetails.length} Item${items.orderdetails.length > 1 ? 's' : ''}`
        : items?.bookingdetails?.[0]?.services?.name,
        "Phone" : `${items?.userData?.country_code} ${items?.userData?.phone}`,
        "Status" : items.status
      }
    });
    setDataSheet5(boardingSheet);

    const trainingSheet = training.map((items)=>{
      return {
        "Booking Id" : items.booking_seq_id,
        "Store" : items?.shopId?.shop_name,
        "Date" : moment(`${items?.createdAt}`).format('DD/MM/YYYY, hh:mm A'),
        "Product" : items?.orderdetails?.[0]?.productId
        ? `${items.orderdetails.length} Item${items.orderdetails.length > 1 ? 's' : ''}`
        : items?.bookingdetails?.[0]?.services?.name,
        "Phone" : `${items?.userData?.country_code} ${items?.userData?.phone}`,
        "Status" : items.status
      }
    });
    setDataSheet6(trainingSheet);

    const daycareSheet = daycare.map((items)=>{
      return {
        "Booking Id" : items.booking_seq_id,
        "Store" : items?.shopId?.shop_name,
        "Date" : moment(`${items?.createdAt}`).format('DD/MM/YYYY, hh:mm A'),
        "Product" : items?.orderdetails?.[0]?.productId
        ? `${items.orderdetails.length} Item${items.orderdetails.length > 1 ? 's' : ''}`
        : items?.bookingdetails?.[0]?.services?.name,
        "Phone" : `${items?.userData?.country_code} ${items?.userData?.phone}`,
        "Status" : items.status
      }
    });
    setDataSheet7(daycareSheet);
  }

  const downloadExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Convert data for each sheet
    const worksheet1 = XLSX.utils.json_to_sheet(dataSheet1);
    const worksheet2 = XLSX.utils.json_to_sheet(dataSheet2);
    const worksheet3 = XLSX.utils.json_to_sheet(dataSheet3);
    const worksheet4 = XLSX.utils.json_to_sheet(dataSheet4);
    const worksheet5 = XLSX.utils.json_to_sheet(dataSheet5);
    const worksheet6 = XLSX.utils.json_to_sheet(dataSheet6);
    const worksheet7 = XLSX.utils.json_to_sheet(dataSheet7);
  
    // Append sheets to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Count Data");
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Shop Order");
    XLSX.utils.book_append_sheet(workbook, worksheet3, "Grooming Bookings");
    XLSX.utils.book_append_sheet(workbook, worksheet4, "Veterinary Bookings");
    XLSX.utils.book_append_sheet(workbook, worksheet5, "Boarding Bookings");
    XLSX.utils.book_append_sheet(workbook, worksheet6, "Training Bookings");
    XLSX.utils.book_append_sheet(workbook, worksheet7, "Day Care Bookings");
  
    // Trigger download
    XLSX.writeFile(workbook, "Statistic.xlsx");
  };

  
  return (
    <>
      <Top_navbar></Top_navbar>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="transaction_form">
              <div className="transaction_Card margin-right">
                <div className="transction_main_Card ">
                  <h1 className="dashboard_top_heading_user">Users</h1>
                  <div>
                    <Select
                      defaultValue="All"
                      className="admin_dashboard_select"
                      style={{
                        width: 90,
                      }}
                      bordered={false}
                      onChange={handleUserShowRequest}
                      options={[
                        {
                          value: "All",
                          label: "All",
                          className:"admin_dashboard_select"
                        },
                        {
                          value: "Active",
                          label: "Active",
                          className:"admin_dashboard_select"
                        },
                        {
                          value: "Inactive",
                          label: "Inactive",
                        },
                        {
                          value: "Delete",
                          label: "Deleted",
                        },
                      ]}
                    />
                  </div>
                </div>

                <p className="presentation">
                  Represents total number of registered <br />
                  users till date.
                </p>
                {TOTALUSERSCOUNT !== "" ? (
                  <p className="sales_text">{TOTALUSERSCOUNT}</p>
                  ):(
                    <Skeleton loading={true} active paragraph={{ rows: 0 }} />
                  )}
              </div>

              <div className="transaction_Card margin-right">
                <div className="transction_main_Card">
                  <h1 className="dashboard_top_heading_user">Orders/Bookings</h1>
                  <div>
                    <Select
                      defaultValue="All"
                      style={{
                        width: 90,
                      }}
                      bordered={false}
                      onChange={handleOrdersShowRequest}
                      options={[
                        {
                          value: "all",
                          label: "All",
                        },
                        {
                          value: "shop",
                          label: "Shop",
                        },
                        {
                          value: "grooming",
                          label: "Grooming",
                        },
                        {
                          value: "boarding",
                          label: "Boarding",
                        },
                        {
                          value: "veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "training",
                          label: "Training",
                        },
                        {
                          value: "daycare",
                          label: "Day care",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  Represents total number of Orders/Bookings till date.
                </p>
                {TOTALORDERSCOUNT !== "" ? (
                  <p className="sales_text">{TOTALORDERSCOUNT}</p>
                ):(
                  <Skeleton loading={true} active paragraph={{ rows: 0 }} />
                )}
              </div>

              <div className="transaction_Card margin-right">
                <div className="transction_main_Card">
                  <h1 className="dashboard_top_heading_user">Revenue</h1>
                  <div>
                    <Select
                      defaultValue="All"
                      style={{
                        width: 90,
                      }}
                      bordered={false}
                      onChange={handleRevenueShowRequest}
                      options={[
                        {
                          value: "all",
                          label: "All",
                        },
                        {
                          value: "shop",
                          label: "Shop",
                        },
                        {
                          value: "grooming",
                          label: "Grooming",
                        },
                        {
                          value: "boarding",
                          label: "Boarding",
                        },
                        {
                          value: "veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "training",
                          label: "Training",
                        },
                        {
                          value: "daycare",
                          label: "Day care",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  Represents total revenue generated in <br/> the past 6 months.
                </p>
                {TOTALREVENUECOUNT !== "" ? (
                  <p className="sales_text">{`AED ${TOTALREVENUECOUNT.toFixed(2)}`}</p>
                ):(
                  <Skeleton loading={true} active paragraph={{ rows: 0 }} />
                )}
              </div>

              <div className="transaction_Card margin-right">
                <div className="transction_main_Card">
                  <h1 className="dashboard_top_heading_user">Sales</h1>
                  <div>
                    <Select
                      defaultValue="All"
                      style={{
                        width: 90,
                      }}
                      bordered={false}
                      onChange={handleSaleShowRequest}
                      options={[
                        {
                          value: "all",
                          label: "All",
                        },
                        {
                          value: "shop",
                          label: "Shop",
                        },
                        {
                          value: "grooming",
                          label: "Grooming",
                        },
                        {
                          value: "boarding",
                          label: "Boarding",
                        },
                        {
                          value: "veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "training",
                          label: "Training",
                        },
                        {
                          value: "daycare",
                          label: "Day Care",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  Represents total sales generated <br/> in the past 6 months.
                </p>
                {TOTALSALECOUNT !== "" ? (
                  <p className="sales_text">{`AED ${TOTALSALECOUNT.toFixed(2)}`}</p>
                ):(
                  <Skeleton loading={true} active paragraph={{ rows: 0 }} />
                )}
              </div>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-12">
            <div className="inventory_tabs_main_div mt-0">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <div className="inventory_all_tabs">
                    <div className="all_tabs_value_rating_review" onClick={downloadExcel}>
                      <h6 className="m-0 font-weight-bold common_inventory_heading"> Export to Excel </h6>
                      
                    </div>
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
        <br/>
        {/* tables */}
        <div className="row">
          <div className="col-md-12">
            <Recent_order 
              orderList = {ordersDetails}
              isLoading = {isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
