import React, { useEffect, useState, useRef } from "react";
import Top_navbar from "../../DashBaord/Top_navbar";
import { ASSETS_BASE_URL } from "../../../config/constants";
import { FaPlusSquare } from "react-icons/fa";
import { Select, Typography } from "antd";
import { TbCategory } from "react-icons/tb";
import { Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import moment from "moment";
import { getData, addEditData } from "../../../controllers/cms/treats";

function CategoriesProductList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CategoriesProductList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Treats() {
  /********************** Integratin Stare Here **********************/
  const [GETDATA, SETGETDATA] = useState([]);
  const [DESCRIPTION, setDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [showRequest, setShowRequest] = useState("");
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End
  const targetRef = useRef();
  /*********************************************************
   *  This function is use to handle Add/Edit Popup
   *********************************************************/
  const handleAddEditData = () => {
    setEditMode(!editMode);
  }; //End

  const handleShowRequest = (value) => {
    setShowRequest(value);
  };
  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const getList = async () => {
    SETGETDATA([]);
    const options = {
      type: "",
      condition: {
        ...(filter?.from
          ? {
              createdAt: {
                $gte: moment(
                  `${filter?.from?.$y}-${filter?.from?.$M + 1}-${
                    filter?.from?.$D
                  }`
                ).format("YYYY-MM-DD"),
                $lte: moment(
                  `${filter?.to?.$y}-0${filter?.to?.$M + 1}-${
                    filter?.to?.$D + 1
                  }`
                ).format("YYYY-MM-DD"),
              },
            }
          : null),
        ...(filter?.search
          ? { [filter?.filter_by]: { $regex: filter.search, $options: "i" } }
          : null),
        ...(showRequest ? { status: showRequest } : null),
      },
      select: {},
      sort: { _id: -1 },
      skip: 0,
      limit: 1,
    };
    const listData = await getData(options);
    if (listData.status === true) {
      SETGETDATA(listData?.result);
      setDescription(listData?.result?.description);
    } else {
      SETGETDATA([]);
    }
  }; //End

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditMode(false);
    const options = {
      editId: GETDATA?._id,
      title: GETDATA?.title,
      description: DESCRIPTION || "",
    };

    const res = await addEditData(options);
    // console.log('res',res);
  };

  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = 'Frisbee Super Admin || Treats'
  }, [showRequest, editMode]);

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      SETGETDATA((pre) => ({
        ...pre,
        [name]: value,
      }));
    } catch (error) {}
  };
  /********************** Integratin End Here **********************/
  return (
    <div className="container-fluid" ref={targetRef}>
      <Top_navbar title="Treats - How it Works"></Top_navbar>
      <div className="order_now_card_tilte">
        <div class="card">
          <div class="card-body categories_main_div">
            {/* <div className="categories_tb_select">
              <TbCategory />
              <Select
                name="show"
                id="show"
                className="product_select_tag_rating_review"
                style={{ width: 120 }}
                bordered={false}
                onChange={handleShowRequest}
                defaultValue={showRequest}
              >
                <Select.Option value="" label="All" selected={true}>
                  All
                </Select.Option>
                <Select.Option value="A" label="Active">
                  Active
                </Select.Option>
                <Select.Option value="I" label="Inactive">
                  Inactive
                </Select.Option>
              </Select>
            </div> */}
            <div className="promotions_filters_div">
              {/* <div className="fa_filter_rating_review">
                <img
                  src={ASSETS_BASE_URL + "./images/Filter.svg"}
                  alt=""
                  className="user_iconses"
                />
                <a href="#" className="filter_btn_invontry_rating_review">
                  {" "}
                  Filters{" "}
                </a>{" "}
              </div> */}
              <div>
                <button
                  type="button"
                  class="btn btn-dark"
                  onClick={handleAddEditData}
                >
                  <FaPlusSquare className="Promotions_faPlus_Squares" />
                  <span className="request_new_categories">
                    {editMode ? "Cancel" : "Edit"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inventory_tabs_main_div">
        <Box sx={{ width: "100%" }}>
          <CategoriesProductList>
            <div>
              <div id="content-wrapper" className="d-flex flex-column">
                <div className="card shadow mb-4">
                  <div className="card-body pt-0">
                    <div className="table-responsive term_and_condation_main_section">
                      <div className="row">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                          <label
                            for="title"
                            className="categories_open_popup_label_name all_lebel_margin_top_main_div"
                          >
                            Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            id="title"
                            value={GETDATA?.title || ""}
                            className="form-control categories_select_tg_enter_product"
                            onChange={handleChange}
                            disabled={!editMode}
                          />
                        </div>
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                          <label
                            for="title"
                            className="categories_open_popup_label_name all_lebel_margin_top_main_div"
                          >
                            Description{" "}
                          </label>
                        </div>
                        <div className="term_and_condation_react_quilllll">
                          <ReactQuill
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, false] }],
                                [
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "blockquote",
                                ],
                                [
                                  { list: "ordered" },
                                  { list: "bullet" },
                                  { indent: "-1" },
                                  { indent: "+1" },
                                ],
                                ["link", "image"],
                                ["clean"],
                              ],
                            }}
                            formats={[
                              "header",
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                              "list",
                              "bullet",
                              "indent",
                              "link",
                              "image",
                            ]}
                            value={DESCRIPTION || ""}
                            onChange={setDescription}
                            readOnly={!editMode}
                          />
                        </div>
                        <div>
                          {editMode && (
                            <button
                              type="button"
                              className="btn btn-danger all_save_btn_btn_btn"
                              onClick={handleSubmit}
                            >
                              <span className="request_new_categories">
                                Save
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CategoriesProductList>
        </Box>
      </div>
    </div>
  );
}
