import React, { useState } from "react";
import "./profile.css";
import ProfileTopNavBar from "./ProfileTopNavBar";
import { IoLocationOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import {ASSETS_BASE_URL} from "../../config/constants";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const INFO = JSON.parse(sessionStorage.getItem('ADMIN-INFO'));
  const [ADMININFO, setAdminInfo] = useState(INFO || {});
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate('/storeDetailsForm', { state: { ADMININFO } });
  }
  return (
    <div>
      <ProfileTopNavBar></ProfileTopNavBar>
      <div className="container-fluid">
        <div className="row">
          <div class="col-12">
            <div class="card user_profile_section">
              <div className="user_profile_main_class">
                <div>
                <img
                    src={ADMININFO.admin_image ?  ASSETS_BASE_URL + ADMININFO.admin_image : "https://frisbee-llc.s3.me-central-1.amazonaws.com/images/favicon.png"}
                    alt=""
                    className="user_profile_pic"
                    width="100px"
                  />
                </div>
                <div class="card-body">
                  {" "}
                  <span className="profile_user_name"> {ADMININFO.admin_first_name} {ADMININFO.admin_last_name}</span>{" "}
                  <br />
                  <span className="profile_user_admin">{ADMININFO.admin_type}</span>
                </div>
              </div>
            </div>
            {/* store details */}
            <div className="card profile_store_details">
              <div className="card-body">
                <div className="profile_store_details_for_edit">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Store Details
                    </span>
                  </div>
                  <div>
                    <button type="button" onClick={handleEdit} class="btn btn-dark">
                      Edit Store
                    </button>
                  </div>
                </div>
                <table className="table table-borderless profile_store_table">
                  <tbody>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <div className="profile_details_section">
                          <IoLocationOutline />{" "}
                          <span className="profile_details_location">
                            Location
                          </span>
                        </div>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location">
                          {ADMININFO.admin_country} {ADMININFO.admin_state}
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location1">
                          {ADMININFO.admin_address} {ADMININFO.admin_city}
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <IoCallOutline />{" "}
                        <span className="profile_details_location">
                          Contact Number
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        {" "}
                        <span className="profile_store_location">
                          {ADMININFO.admin_phone}
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <CiMail />{" "}
                        <span className="profile_details_location">E-Mail</span>
                      </td>
                      <td class="text-end order_now_rupees">
                        {" "}
                        <span className="profile_store_location">
                          {ADMININFO.admin_email}
                        </span>{" "}
                      </td>
                    </tr>
                    {/* <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <CiClock2 />{" "}
                        <span className="profile_details_location">
                          Store Timings
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location">
                          Monday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Tuesday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Wednesday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Thursday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Friday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Saturday 10:00 - 22:00
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location">
                          Sunday 10:00 - 22:00
                        </span>{" "}
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            {/* // Bank Details */}
            {/* <div className="card profile_store_details">
              <div className="card-body">
                <div className="profile_store_details_for_edit">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Bank Details
                    </span>
                  </div>
                </div>
                <table className="table table-borderless Bank_details_profile_store_table">
                  <tbody>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <div className="profile_details_section">
                          <span className="profile_details_location">
                          Account Holder Name
                          </span>
                        </div>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location1">
                          Eleanor Patel
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <span className="profile_details_location">
                          Account Number
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location1">
                          1232 **** 2762 (Visa)
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <span className="profile_details_location">
                          Account Type
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location1">
                          Savings Account
                        </span>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
