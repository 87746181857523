import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import Loading from "../../../components/Loading/LoadingEffect";
import { addeditdata as addEditBreedsData } from "../../../controllers/cms/reasons";

const PromotionsAddEditPopUp = ({
  BreedsAddEditPopUpmodal,
  breedsToggleAddEditPopUp,
  addEditData,
  setAddEditData,
}) => {
  const handleCancel = () => {
    setAddEditData("");
    breedsToggleAddEditPopUp();
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChnage = (e) => {
    try {
      const { name, value } = e.target;
      setAddEditData((pre) => ({
        ...pre,
        [name]: value,
      }));

      setErrors((preError) => ({
        ...preError,
        [name]: "",
      }));
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const form = new FormData();
      form.append("reasons", addEditData?.reasons || "");
      if (addEditData?._id) {
        form.append("editId", addEditData._id);
      }
      const isValidate = await validateFormData(form);
      console.log(isValidate);
      if (isValidate) {
        const rawData = {};

        form.forEach((value, key) => {
          rawData[key] = value;
        });
        const res = await addEditBreedsData(rawData);
        if (res.status === true) {
          setLoading(false);
          notification.open({
            message: "Success",
            description: " Data save Successfully.",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          handleCancel();
        } else {
          setLoading(false);
          notification.open({
            message: "Error",
            description: res?.message || "Please try after some time",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        }
      } else {
        setLoading(false);
        notification.open({
          message: "Oops!",
          description: " Please fill required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateFormData = async (formData) => {
    try {
      if (
        formData.get("reasons") === "undefined" ||
        formData.get("reasons") === null ||
        formData.get("reasons").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          reasons: "Reason is required",
        }));
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <Dialog
      open={BreedsAddEditPopUpmodal}
      onClose={breedsToggleAddEditPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              {addEditData?._id ? "Edit" : "Add"} Reason
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form
                  className="categories_open_popup_form"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="editId"
                    id="editId"
                    value={addEditData?._id}
                  />
                  <div className="form-group row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="reasons"
                        className="categories_open_popup_label_name"
                      >
                        <span style={{ color: "red" }}>*</span>Reason
                      </label>
                      <input
                        type="text"
                        name="reasons"
                        id="reasons"
                        value={addEditData?.reasons}
                        onChange={handleChnage}
                        className="form-control categories_select_tg_enter_product"
                        placeholder="Enter Reason"
                      />
                      {errors?.reasons ? (
                        <p className="text-danger">{errors.reasons}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        className="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button type="Submit" className="btn btn-danger ">
                        {addEditData?._id ? "Save Change" : "Create"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
        <Loading isLoading={loading} />
      </div>
    </Dialog>
  );
};

export default PromotionsAddEditPopUp;
