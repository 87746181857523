import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Select, Upload, Flex } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "../../ServiceList.css";

import Loading from '../../../../components/Loading/LoadingEffect';
import { ASSETS_BASE_URL } from "../../../../config/constants";
import {ucfirst, commonList} from '../../../../controllers/common';
import { addEditData } from "../../../../controllers/services/boardingController"
import { list as vendorlist} from "../../../../controllers/vendors/vendorsController"
import { boardingChnageStatus as chnageStatus } from "../../../../controllers/services/requestController";

const RejectPopup = ({ show, editdata, setEditData, setAddEditPopup }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
    
  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
   const handleCancel = () => {
    setEditData("");
    setFormData("");
    setAddEditPopup(false);
  };
  /*********************************************************
  *  This function is use to handle success notification show
  *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      duration : 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };//End
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = (msg="") => {
    notification.open({
      message: "Opps!",
      description: error?.formError || msg,
      placement: "topRight",
      duration : 2,
      icon: <CloseOutlined  style={{color : "red"}} />,
    });
  };//End
  const [isLoading, setIsLoading] = useState(true);
  const [error, serError] = useState([]);
  const [formData, setFormData] = useState("");
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [ALLCATEGORY, setAllCategory] = useState([]);
  const [ALLSUBCATEGORY, setAllSubCategory] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);

/*********************************************************
  *  This function is use to get all vendor list
  *********************************************************/
  const getVendorList = async () => {
    try {
      const options ={
        condition : { status : "A"},
        select : {
          vendor_id : false, 
          vendor_email : false,
          creation_date : false,
          createdAt : false,
          creation_ip : false,
          vendor_address : false,
          vendor_city : false,
          vendor_country : false,
          vendor_image : false,
          vendor_otp : false,
          vendor_phone : false,
          vendor_pincode : false,
          vendor_state : false,
          created_by : false,
          update_date : false,
          update_ip : false,
          updatedAt : false,
          updated_by : false,
          vendor_commission : false,
          vendor_country_code : false,
          vendor_gender : false,
          vendor_revenue : false,
          vendor_street : false
        },
        sort : {vendor_first_name : 1},
        skip : 0,
        limit : 0
      }
      const res = await vendorlist(options);
      if(res.status === true){
        setAllVendor(res?.result)
      }else{
        setAllVendor([]);
      }
    } catch (error) {
      setAllVendor([]);
    }
  };

  const getCommonListData = async (vendorId) => {
    try{
      setAllStore([]);
      setAllBranch([]);
      setAllCategory([]);
      setAllSubCategory([]);
      const options ={
        vendorId : vendorId,
        list : ["service_category","store","branch"],
        serviceCat : { select : { name : true} },
        serviceSubCat : { select : { category : true, name : true } },
        store : { select : { shop_name : true } },
        branch : { select : { storeData : true, branch_name : true } }
      }
      const res =await commonList(options);
      if(res?.status === true){
        setAllStore(res?.result?.store);
        setAllBranch(res?.result?.branch);
        setAllCategory(res?.result?.serviceCat);
        setAllSubCategory(res?.result?.serviceSubCat);

        if(editdata?.branch){
          const filterArray = res?.result?.branch.filter(val=> val.storeData == editdata?.store?._id );
          setFilterBranch(filterArray);
        }
        // if(editdata.subcategory){
        //   const filterArray = res?.result?.serviceSubCat.filter(val=> val.category == editdata?.category?._id )
        //   setFilterSubCat(filterArray);
        // }
      }
      setIsLoading(false)
    } catch (error){
      setIsLoading(false)
    }
  }

  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e)=>{
    const {name, value} = e.target;
    if(name === 'vendor'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      // alert(value)
      getCommonListData(value);
      setFilterBranch([]);
      setFilterSubCat([]);
    } else if(name === 'store'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      const filterArray = ALLBRANCH.filter(val=> val.storeData == value )
      setFilterBranch(filterArray);
      setFilterSubCat([]);
    } else if(name === 'category'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      const filterArray = ALLSUBCATEGORY.filter(val=> val.category == value )
      setFilterSubCat(filterArray);
    } else{
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }))
    }
    serError((pre)=>({
      ...pre,
      [e.target.name] : ""
    }))
  }; //End

  const handleTypeClick = (name) => {
    try {
      setFormData((pre) => ({
        ...pre,
        [name] : editdata[name] === "Y"?"N":"Y",
      }));
      setEditData((pre) => ({
        ...pre,
        [name] : editdata[name] === "Y"?"N":"Y",
      }));
      const size = ['small', 'medium', 'large'];
      
      if(size.includes(name)){
        serError((preError)=>({
          ...preError,
          dog_size : ""
        }));
      }
    } catch (error) {
      
    }
  }
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e)=>{
    e.preventDefault();
    setIsLoading(true);
    const form = new FormData(e.target);
    form.append("vendor", editdata?.vendor);
    form.append("category", editdata?.category?._id);
    form.append("image_url", editdata.image);
    form.append("small", editdata?.small === 'Y'? "Y":"N");
    form.append("medium", editdata?.medium === 'Y'? "Y":"N");
    form.append("large", editdata?.large === 'Y'? "Y":"N");
    form.append('branch',editdata?.branch?._id || '')
    form.append("ipAddress", sessionStorage.getItem('IP_ADDRESS'));
    const isValidate = validateFormData(form)
    if(isValidate){
      const res = await addEditData(form);
      if(res.status === true){
        const options = {
          id : editdata._id,
          status : 'C'
        }
        const result = await chnageStatus(options);
        if(result.status === true){
          setIsLoading(false)
          setFormData("");
          handleSuccessMsg();
          handleCancel();
        }else{
          setIsLoading(false)
          setFormData("");
          handleErrorMsg('Service created by request status not chnage.');
          handleCancel();
        }
      }else{
        setIsLoading(false)
        serError((pre)=>({
          ...pre,
          formError : res?.message
        }))
        handleErrorMsg();
      }
    }else{
      setIsLoading(false)
      handleErrorMsg('Please fill required fields.');
    }
  } //End
  /*********************************************************
  *  This function is use to validate form data before submit
  *********************************************************/
  const validateFormData = (formData) => {
    console.log('details',formData.get('details'));
    if(formData.get('vendor') === 'undefined' || formData.get('vendor') === null || formData.get('vendor').trim() === ''){
      serError((preError)=>({
        ...preError,
        vendor : "Vendor is required"
      }));
      return false;
    } else if(formData.get('store') === 'undefined' || formData.get('store') === null || formData.get('store').trim() === ''){
      serError((preError)=>({
        ...preError,
        store : "Store is required"
      }));
      return false;
    } else if(formData.get('branch') === 'undefined' || formData.get('branch') === null || formData.get('branch').trim() === ''){
      serError((preError)=>({
        ...preError,
        branch : "Branch is required"
      }));
      return false;
    } else if(formData.get('category') === 'undefined' || formData.get('category') === null || formData.get('category').trim() === ''){
      serError((preError)=>({
        ...preError,
        category : "Category is required"
      }));
      return false;
    } else if(formData.get('name') === 'undefined' || formData.get('name') === null || formData.get('name').trim() === ''){
      serError((preError)=>({
        ...preError,
        name : "Service name is required"
      }));
      return false;
    } else if(formData.get('small') === 'N' && formData.get('medium') === 'N' && formData.get('large') === 'N'){
      serError((preError)=>({
        ...preError,
        dog_size : "Please select dog size."
      }));
      return false;
    } else if(formData.get('details') === 'undefined' || formData.get('details') === null || formData.get('details').trim() === ''){
      serError((preError)=>({
        ...preError,
        details : "Service details is required"
      }));
      return false;
    } else if(formData.get('price') === 'undefined' || formData.get('price') === null || formData.get('price').trim() === ''){
      serError((preError)=>({
        ...preError,
        price : "Price is required"
      }));
      return false;
    }else {
      return true;
    }
  }; //End

  useEffect(()=>{
    getVendorList();
    if(editdata?._id){
      getCommonListData(editdata?.vendor)
    }
  },[show]);
  return ( 
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              Request Service Details
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="store" className="categories_open_popup_label_name"> Store </label>
                      <select id="store" name="store" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a store</option>
                        {ALLSTORE && ALLSTORE.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id}
                            selected={editdata?.store?._id === items._id ? true:false}
                          >
                            {`${ucfirst(items.shop_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.store?<p style={{color:"red"}}>{error.store}</p>:""}
                    </div>

                    {/* <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="branch" className="categories_open_popup_label_name"> Branch </label>
                      <select id="branch" name="branch" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a branch</option>
                        {filterBranch && filterBranch.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id}
                            selected={editdata?.branch?._id === items._id ? true:false}
                          >
                            {`${ucfirst(items.branch_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.branch?<p style={{color:"red"}}>{error.branch}</p>:""}
                    </div> */}

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="name" className="categories_open_popup_label_name"> Service Name </label>
                      <input
                        type="text"
                        class="form-control store_input_field"
                        id="name"
                        placeholder="Enter service name"
                        name="name"
                        value={formData?.name?formData?.name:editdata?.name}
                        onChange={handleChnage}
                      />
                      {error?.name?<p style={{color:"red"}}>{error.name}</p>:""}
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="phone" className="categories_open_popup_label_name">
                        <span style={{ color: "red" }}>*</span>Dog Size :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="small" id="small" size="large" >
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('small')}>
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="male">Small</label>
                                  <input
                                    type="radio"
                                    id="small"
                                    name="small"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={editdata.small === 'Y'?true:false}
                                  />
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('medium')}>
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="medium">Medium</label>
                                  <input
                                    type="radio"
                                    id="medium"
                                    name="medium"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={editdata.medium === 'Y'?true:false}
                                  />
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" onClick={()=>handleTypeClick('large')}>
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="large">Large</label>
                                  <input
                                    type="radio"
                                    id="large"
                                    name="large"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={editdata.large === 'Y'?true:false}
                                  />
                              </div>
                            </div>
                            {error?.dog_size?<p style={{color:"red"}}>{error.dog_size}</p>:""}
                          </div>
                        </div>
                      </Flex>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="details" className="categories_open_popup_label_name"> Details </label>
                      <textarea class="form-control store_input_field"
                        id="details"
                        placeholder="Enter service details"
                        name="details"
                        onChange={handleChnage}>{formData?.details?formData?.details:editdata?.details}</textarea>
                      {error?.details?<p style={{color:"red"}}>{error.details}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="price" className="categories_open_popup_label_name"> Price </label>
                      <input
                        type="number"
                        class="form-control store_input_field"
                        id="price"
                        placeholder="Enter price"
                        name="price"
                        value={formData?.price?formData?.price:editdata?.price}
                        onChange={handleChnage}
                      />
                      {error?.price?<p style={{color:"red"}}>{error.price}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="categories_open_popup_label_name" >Image</label>
                      <div className="inventory_upload_file_main_div">
                        <img src={`${ASSETS_BASE_URL}${editdata?.image}`} width={`100px`}/>
                      </div>
                    </div>

                  </div>

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-danger "
                    >
                      {`Save & Complete`}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
          {/* <DialogActions>
          <Button color="primary">Cancel</Button>
          <Button style={{ color: "red" }}>Delete</Button>
        </DialogActions> */}
        </div>
        <Loading
          isLoading={isLoading}
        />
      </div>
    </Dialog>
  );
};

export default RejectPopup;
