import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Flex, notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";

import Loading from "../../components/Loading/LoadingEffect";
import { addeditdata } from "../../controllers/promocode/promocodeController";
import moment from "moment";

import { list as vendorlist} from "../../controllers/vendors/vendorsController";
import {ucfirst, commonList, serviceList} from '../../controllers/common';

const PromotionsAddEditPopUp = ({
  promotionsAddEditPopUpmodal,
  promotionaToggleAddEditPopUp,
  addEditData,
  setAddEditData
}) => {
  const handleCancel = () => {
    setAddEditData("");
    promotionaToggleAddEditPopUp();
  };
  const handleDelete = () => {
    notification.open({
      message: "Notification Title",
      description: " Data Send Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /************************************ */
  const [errors, setErrors]= useState("");
  const [loading, setLoading] = useState(false);

  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [ALLSERVICES, setAllServices] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);

    /*********************************************************
  *  This function is use to get all vendor list
  *********************************************************/
    const getVendorList = async () => {
      try {
        const options ={
          condition : { status : "A"},
          select : {
            vendor_id : false, 
            vendor_email : false,
            creation_date : false,
            createdAt : false,
            creation_ip : false,
            vendor_address : false,
            vendor_city : false,
            vendor_country : false,
            vendor_image : false,
            vendor_otp : false,
            vendor_phone : false,
            vendor_pincode : false,
            vendor_state : false,
            created_by : false,
            update_date : false,
            update_ip : false,
            updatedAt : false,
            updated_by : false,
            vendor_commission : false,
            vendor_country_code : false,
            vendor_gender : false,
            vendor_revenue : false,
            vendor_street : false
          },
          sort : {vendor_first_name : 1},
          skip : 0,
          limit : 0
        }
        const res = await vendorlist(options);
        if(res.status === true){
          setAllVendor(res?.result)
        }else{
          setAllVendor([]);
        }
      } catch (error) {
        setAllVendor([]);
      }
    };
  
    const getCommonListData = async (vendorId) => {
      try{
        setLoading(true);
        setAllStore([]);
        setAllBranch([]);
        const options ={
          vendorId : vendorId,
          list : ["service_category","store","branch"],
          serviceCat : { select : { name : true} },
          serviceSubCat : { select : { category : true, name : true } },
          store : { select : { shop_name : true } },
          branch : { select : { storeData : true, branch_name : true } }
        }
        const res =await commonList(options);
        
        if(res?.status === true){
          setAllStore(res?.result?.store);
          setAllBranch(res?.result?.branch);
  
          if(addEditData?.branch){
            const filterArray = res?.result?.branch.filter(val=> val.storeData == addEditData?.store );
            setFilterBranch(filterArray);
          }
        }
        setLoading(false);
      } catch (error){
        setLoading(false);
      }
    }

    const getServiceListData = async (vendorId) => {
      try{
        setLoading(true);
        setAllStore([]);
        setAllBranch([]);
        const options ={
          condition : { status : "A" },
          select : { name : true }
        }
        const res =await serviceList(options);
        if(res?.status === true){
          setAllServices(res?.result)
        }
        setLoading(false);
      } catch (error){
        setLoading(false);
      }
    }

  const handleChnage = (e) => {
    try {
      const {name, value} = e.target;
      if(name === 'promocode'){
        const pattern = /[^a-zA-Z0-9\s]/g;
        const cleanText = value.replace(pattern, '');
        setAddEditData((pre)=>({
          ...pre,
          [name] : cleanText.toUpperCase()
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }))
      } else if(name === 'vendor'){
        setAddEditData((pre)=>({
          ...pre,
          [name] : value
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }));
        getCommonListData(value);
        setFilterBranch([]);
      } else if(name === 'store'){
        setAddEditData((pre)=>({
          ...pre,
          [name] : value
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }));
        const filterArray = ALLBRANCH.filter(val=> val.storeData == value )
        setFilterBranch(filterArray);
      } else{
        setAddEditData((pre)=>({
          ...pre,
          [name] : value
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }))
      }
    } catch (error) {
      
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const form = new FormData(e.target);
      form.append("ipAddress", sessionStorage.getItem('IP_ADDRESS'));
      form.append("redeem_to",addEditData?.redeem_to?addEditData.redeem_to:'all');
      form.append("discount_type",addEditData?.discount_type?addEditData.discount_type:'AED');
      const isValidate = await validateFormData(form);
      // console.log('isValidate',isValidate);
      if(isValidate){
        const formDataObject = {};
        form.forEach((value, key) => {
          formDataObject[key] = value;
        });
        const res = await addeditdata(formDataObject);
        if(res.status === true){
          setLoading(false);
          notification.open({
            message: "Success",
            description: " Data save Successfully .",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          handleCancel();
        } else{
          setLoading(false);
          notification.open({
            message: "Error",
            description: res?.message || 'Please try after some time',
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        }
      } else{
        setLoading(false);
        notification.open({
          message: "Oops!",
          description: " Please fill required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateFormData = async (formData) => {
    // console.log('discount_type',formData.get('discount_type'));
    try {
      if(formData.get('title') === 'undefined' || formData.get('title') === null || formData.get('title').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          title : "Title is required"
        }));
        return false;
      } else if(formData.get('promocode') === 'undefined' || formData.get('promocode') === null || formData.get('promocode').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          promocode : "Promo code is required"
        }));
        return false;
      } else if(formData.get('qty') === 'undefined' || formData.get('qty') === null || formData.get('qty').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          qty : "Quantity is required"
        }));
        return false;
      } else if(formData.get('valid_date') === 'undefined' || formData.get('valid_date') === null || formData.get('valid_date').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          valid_date : "Start date is required"
        }));
        return false;
      } else if(formData.get('valid_time') === 'undefined' || formData.get('valid_time') === null || formData.get('valid_time').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          valid_time : "Start time is required"
        }));
        return false;
      } else if(formData.get('expire_date') === 'undefined' || formData.get('expire_date') === null || formData.get('expire_date').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          expire_date : "End date is required"
        }));
        return false;
      } else if(formData.get('expire_time') === 'undefined' || formData.get('expire_time') === null || formData.get('expire_time').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          expire_time : "End time is required"
        }));
        return false;
      } else if(formData.get('eligible_amount') === 'undefined' || formData.get('eligible_amount') === null || formData.get('eligible_amount').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          eligible_amount : "Eligible amount is required"
        }));
        return false;
      } else if(formData.get('discount_type') === 'undefined' || formData.get('discount_type') === null || formData.get('discount_type').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          discount_type : "Discount type is required"
        }));
        return false;
      } else if(formData.get('discounts') === 'undefined' || formData.get('discounts') === null || formData.get('discounts').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          discounts : "Discounts is required"
        }));
        return false;
      } else if(formData.get('discounts_cap') === 'undefined' || formData.get('discounts_cap') === null || formData.get('discounts_cap').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          discounts_cap : "Max. discount cap is required"
        }));
        return false;
      } else if(formData.get('redeem_to')){
        const redeem_to = formData.get('redeem_to');
        if(redeem_to === 'store'){
          if(formData.get('vendor') === 'undefined' || formData.get('vendor') === null || formData.get('vendor').trim() === ''){
            setErrors((preError)=>({
              ...preError,
              vendor : "Please select vendor."
            }));
            return false;
          }else if(formData.get('store') === 'undefined' || formData.get('store') === null || formData.get('store').trim() === ''){
            setErrors((preError)=>({
              ...preError,
              store : "Please select store."
            }));
            return false;
          } else{
            return true;
          }
        }else if(redeem_to === 'branch'){
          if(formData.get('vendor') === 'undefined' || formData.get('vendor') === null || formData.get('vendor').trim() === ''){
            setErrors((preError)=>({
              ...preError,
              vendor : "Please select vendor."
            }));
            return false;
          } else if(formData.get('store') === 'undefined' || formData.get('store') === null || formData.get('store').trim() === ''){
            setErrors((preError)=>({
              ...preError,
              store : "Please select store."
            }));
            return false;
          } else if(formData.get('branch') === 'undefined' || formData.get('branch') === null || formData.get('branch').trim() === ''){
            setErrors((preError)=>({
              ...preError,
              branch : "Please select branch."
            }));
            return false;
          } else{
            return true;
          }
        }else{
          return true;
        }
      }else{
        return true;
      }
    } catch (error) {
      return false;
    }
  } 

  const handleTypeClick = (name) => {
    try {
      setAddEditData((pre) => ({
        ...pre,
        redeem_to : name,
      }));
    } catch (error) {
      
    }
  }

  const handleDiscountTypeClick = (name) => {
    try {
      setAddEditData((pre) => ({
        ...pre,
        discount_type : name,
      }));
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    getVendorList();
    getServiceListData();
    if(addEditData?._id){
      setLoading(true)
      getCommonListData(addEditData?.vendor)
    }
  },[promotionsAddEditPopUpmodal]);
// console.log('addEditData',addEditData);
  return (
    <Dialog
      open={promotionsAddEditPopUpmodal}
      onClose={promotionaToggleAddEditPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Edit Promo Code
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <input type="hidden" name="editId" id="editId" value={addEditData?._id}/>
                  <div className="form-group row">
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="phone" className="all_lable_for_vendor_dashbaord">
                        <span style={{ color: "red" }}>*</span>Select Option :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" 
                              onClick={()=>handleTypeClick('all')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="redeem_to">All</label>
                                  <input
                                    type="radio"
                                    id="redeem_to"
                                    name="redeem_to"
                                    value="all"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={!addEditData?.redeem_to?true:(addEditData?.redeem_to === 'all'?true:false)}
                                  />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" 
                              onClick={()=>handleTypeClick('store')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="redeem_to">Store Only</label>
                                  <input
                                    type="radio"
                                    id="redeem_to"
                                    name="redeem_to"
                                    value="store"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={addEditData?.redeem_to === 'store'?true:false}
                                  />
                              </div>
                            </div>
                            
                            {/* <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" 
                              onClick={()=>handleTypeClick('branch')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="redeem_to">Branch Only</label>
                                  <input
                                    type="radio"
                                    id="redeem_to"
                                    name="redeem_to"
                                    value="branch"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={addEditData?.redeem_to === 'branch'?true:false}
                                  />
                              </div>
                            </div> */}

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" 
                              onClick={()=>handleTypeClick('new_user')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="redeem_to">New Users</label>
                                  <input
                                    type="radio"
                                    id="redeem_to"
                                    name="redeem_to"
                                    value="new_user"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={addEditData?.redeem_to === 'new_user'?true:false}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>
                    {addEditData?.redeem_to === 'store' || addEditData?.redeem_to === 'branch'?(<>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="vendor" className="all_lable_for_vendor_dashbaord"> Vendor </label>
                        <select id="vendor" name="vendor" onChange={handleChnage} class="form-control store_input_field">
                          <option value="">Select a vendor</option>
                          {ALLVENDOR && ALLVENDOR.map((items, index)=>(
                            <option 
                              key={index} 
                              value={items._id} 
                              selected={addEditData?.vendor === items._id ? true:false}>
                              {`${ucfirst(items.vendor_first_name)} ${ucfirst(items.vendor_last_name)}`}
                            </option>
                          ))}
                        </select>
                        {errors?.vendor?<p style={{color:"red"}}>{errors.vendor}</p>:""}
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <label for="store" className="all_lable_for_vendor_dashbaord"> Store </label>
                        <select id="store" name="store" onChange={handleChnage} class="form-control store_input_field">
                          <option value="">Select a store</option>
                          {ALLSTORE && ALLSTORE.map((items, index)=>(
                            <option 
                              key={index} 
                              value={items._id}
                              selected={addEditData?.store === items._id ? true:false}
                            >
                              {`${ucfirst(items.shop_name)}`}
                            </option>
                          ))}
                        </select>
                        {errors?.store?<p style={{color:"red"}}>{errors.store}</p>:""}
                      </div>
                      
                    </>):(<></>)}
                    {addEditData?.redeem_to === 'branch'?(<>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <label for="branch" className="all_lable_for_vendor_dashbaord"> Branch </label>
                        <select id="branch" name="branch" onChange={handleChnage} class="form-control store_input_field">
                          <option value="">Select a branch</option>
                          {filterBranch && filterBranch.map((items, index)=>(
                            <option 
                              key={index} 
                              value={items._id}
                              selected={addEditData?.branch === items._id ? true:false}
                            >
                              {`${ucfirst(items.branch_name)}`}
                            </option>
                          ))}
                        </select>
                        {errors?.branch?<p style={{color:"red"}}>{errors.branch}</p>:""}
                      </div>
                    </>):(<></>)}
                    
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="service" className="all_lable_for_vendor_dashbaord"> Service Category </label>
                      <select id="service" name="service" onChange={handleChnage} class="form-control all_input_tag_tagenter_product_">
                        <option value="">Select a service</option>
                        {ALLSERVICES && ALLSERVICES.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id}
                            selected={addEditData?.service === items._id ? true:false}
                          >
                            {`${ucfirst(items.name)}`}
                          </option>
                        ))}
                      </select>
                      {errors?.branch?<p style={{color:"red"}}>{errors.branch}</p>:""}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="title" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        value={addEditData?.title}
                        onChange={handleChnage}
                        class="form-control all_input_tag_tagenter_product_"
                        placeholder="Enter title"
                      />
                      {errors?.title?(<p className="text-danger">{errors.title}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="promocode" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Promo Code
                      </label>
                      <input
                        type="text"
                        name="promocode"
                        id="promocode"
                        value={addEditData?.promocode}
                        onChange={handleChnage}
                        class="form-control all_input_tag_tagenter_product_"
                        placeholder="Enter promo code name"
                      />
                      {errors?.promocode?(<p className="text-danger">{errors.promocode}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="qty" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Quantity
                      </label>
                      <input
                        type="text"
                        name="qty"
                        id="qty"
                        value={addEditData?.qty}
                        onChange={handleChnage}
                        class="form-control all_input_tag_tagenter_product_"
                        placeholder="Enter quantity"
                      />
                      {errors?.qty?(<p className="text-danger">{errors.qty}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="valid_date" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Start Date
                      </label>
                      <input
                        type="date"
                        name="valid_date"
                        id="valid_date"
                        value={moment(`${addEditData?.valid_date}`).format("YYYY-MM-DD")}
                        onChange={handleChnage}
                        class="form-control all_input_tag_tagenter_product_"
                        placeholder="Enter quantity"
                      />
                      {errors?.valid_date?(<p className="text-danger">{errors.valid_date}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="valid_time" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Start Time
                      </label>
                      <input
                        type="time"
                        name="valid_time"
                        id="valid_time"
                        value={addEditData?.valid_time}
                        onChange={handleChnage}
                        class="form-control all_input_tag_tagenter_product_"
                      />
                      {errors?.valid_time?(<p className="text-danger">{errors.valid_time}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="expire_date" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>End Date
                      </label>
                      <input
                        type="date"
                        name="expire_date"
                        id="expire_date"
                        value={moment(`${addEditData?.expire_date}`).format("YYYY-MM-DD")}
                        onChange={handleChnage}
                        class="form-control all_input_tag_tagenter_product_"
                      />
                      {errors?.expire_date?(<p className="text-danger">{errors.expire_date}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="expire_time" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>End Time
                      </label>
                      <input
                        type="time"
                        name="expire_time"
                        id="expire_time"
                        value={addEditData?.expire_time}
                        onChange={handleChnage}
                        class="form-control all_input_tag_tagenter_product_"
                      />
                      {errors?.expire_time?(<p className="text-danger">{errors.expire_time}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="eligible_amount" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Eligible Amount
                      </label>
                      <input
                        type="number"
                        name="eligible_amount"
                        id="eligible_amount"
                        value={addEditData?.eligible_amount}
                        onChange={handleChnage}
                        class="form-control all_input_tag_tagenter_product_"
                        placeholder="Enter eligible amount"
                      />
                      {errors?.eligible_amount?(<p className="text-danger">{errors.eligible_amount}</p>):""}
                    </div>
                    
                    
                    <div className="from-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label htmlFor="phone" className="all_lable_for_vendor_dashbaord">
                        <span style={{ color: "red" }}>*</span>Discount Type :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" 
                              onClick={()=>handleDiscountTypeClick('AED')}
                            > 
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="discount_type">AED</label>
                                  <input
                                    type="radio"
                                    id="discount_type"
                                    name="discount_type"
                                    value="AED"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={!addEditData?.discount_type?true:(addEditData?.discount_type === 'AED'?true:false)}
                                  />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" 
                              onClick={()=>handleDiscountTypeClick('treats')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="discount_type">Treats</label>
                                  <input
                                    type="radio"
                                    id="discount_type"
                                    name="discount_type"
                                    value="treats"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={addEditData?.discount_type === 'treats'?true:false}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                      {errors?.discount_type?(<p className="text-danger">{errors.discount_type}</p>):""}
                    </div>
                    
                    {addEditData?.discount_type === 'AED'?(<>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="discounts" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Discounts (%)
                        </label>
                        <input
                          type="number"
                          name="discounts"
                          id="discounts"
                          value={addEditData?.discounts}
                          onChange={handleChnage}
                          className="form-control all_input_tag_tagenter_product_"
                          placeholder="Enter discount"
                        />
                        {errors?.discounts?(<p className="text-danger">{errors.discounts}</p>):""}
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="discounts_cap" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Max. Discount Cap (AED)
                        </label>
                        <input
                          type="number"
                          name="discounts_cap"
                          id="discounts_cap"
                          value={addEditData?.discounts_cap}
                          onChange={handleChnage}
                          class="form-control all_input_tag_tagenter_product_"
                          placeholder="Enter maximum discount cap"
                        />
                        {errors?.discounts_cap?(<p className="text-danger">{errors.discounts_cap}</p>):""}
                      </div>
                    </>):(<>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="discounts" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Discounts (Treats)
                        </label>
                        <input
                          type="number"
                          name="discounts"
                          id="discounts"
                          value={addEditData?.discounts}
                          onChange={handleChnage}
                          className="form-control all_input_tag_tagenter_product_"
                          placeholder="Enter discount"
                        />
                        {errors?.discounts?(<p className="text-danger">{errors.discounts}</p>):""}
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="discounts_cap" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Max. Discount Cap (Treats)
                        </label>
                        <input
                          type="number"
                          name="discounts_cap"
                          id="discounts_cap"
                          value={addEditData?.discounts_cap}
                          onChange={handleChnage}
                          class="form-control all_input_tag_tagenter_product_"
                          placeholder="Enter maximum discount cap"
                        />
                        {errors?.discounts_cap?(<p className="text-danger">{errors.discounts_cap}</p>):""}
                      </div>
                    </>)}

                    


                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        class="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="Submit"
                        class="btn btn-danger "
                      >
                        {addEditData?._id?"Save Chnage":"Create"}
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </Typography>
          {/* <DialogActions>
          <Button color="primary">Cancel</Button>
          <Button style={{ color: "red" }}>Delete</Button>
        </DialogActions> */}
        </div>
        <Loading
          isLoading={loading}
        />
      </div>
    </Dialog>
  );
};

export default PromotionsAddEditPopUp;
