import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Select, Button, notification } from "antd";
import { TbCategory } from "react-icons/tb";
import Pagination from "@mui/material/Pagination";
import Topnavbar from "../../DashBaord/Top_navbar";
import CategoryProductDeletePopUp from "./ProductDeletePopUp";
import ProductAddEditData from "./ProductAddEdit";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import "./ProductList.css";
import { FaTimes, FaCheck, FaEye } from "react-icons/fa";
import RejectPopup from "./RejectPopup";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ASSETS_BASE_URL } from "../../../config/constants";
import moment from "moment";
import { getPage, requestStatusMessage } from "../../../controllers/common";
import { list, chnageStatus } from "../../../controllers/categories/request";
import SkeletonList from "../../../components/SkeltonEffect/list";

import RequestFilterDrawer from "./FilterDrawer";

function CategoriesProductList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CategoriesProductList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {
  const [deletePopUpCategory, setDeletePopUpCategory] = useState(false);
  const handleProductDeleteToggle = () => {
    setDeletePopUpCategory(!deletePopUpCategory);
  };

  const [isRejectPopup, setIsRejectPopup] = useState(false);
  const handleRejectToggle = () => {
    setIsRejectPopup(!isRejectPopup);
  };

  /********************** Integratin Stare Here **********************/
  const [deteteId, setDeteteId] = useState("");
  const [isLoading, setIsLoading] = useState(true)
  const targetRef = useRef(null);
  const [rejectId, setRejectId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [showRequest, setShowRequest] = useState("");
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End

  const [showAddEditPopup, setAddEditPopup] = useState(false);
  const [EDITDATA, setEditData] = useState([]);

  const handleShowRequest = (value) => {
    setShowRequest(value);
  };


   /*********************************************************
  *  This function is for handle page change
  *********************************************************/
   const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  /*********************************************************
   *  This function is use to handle notification
   *********************************************************/
  const handleNotification = (status, msg) => {
    notification.open({
      message: status,
      description: msg,
      placement: "topRight",
      duration: 2,
    });
  }; //End
  const handleComplete = async (id) => {
    const param = {
      url: `categories/request/chnage-status`,
      postData: {
        id: id,
        status: "C",
      },
    };
    const res = await chnageStatus(param);
    if (res.status === true) {
      notification.open({
        message: "Complete!",
        description: " Request completed Successfully.",
        placement: "topRight",
        duration: 2,
        icon: <CheckOutlined style={{ color: "green" }} />,
      });
      getList();
    } else {
      notification.open({
        message: "Opps!!",
        description: " Please try again.",
        placement: "topRight",
        duration: 2,
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
      });
    }
  };

  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const getList = async () => {
    setListData([]);
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        ...(filter?.from
          ? {
              createdAt: {
                $gte: moment(
                  `${filter?.from?.$y}-${filter?.from?.$M + 1}-${
                    filter?.from?.$D
                  }`
                ).format("YYYY-MM-DD"),
                $lte: moment(
                  `${filter?.to?.$y}-0${filter?.to?.$M + 1}-${
                    filter?.to?.$D + 1
                  }`
                ).format("YYYY-MM-DD"),
              },
            }
          : null),
        ...(filter?.search
          ? { [filter?.filter_by]: { $regex: filter.search, $options: "i" } }
          : null),
        ...(showRequest ? { request: showRequest } : null),
      },
      select: {},
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    setListData(listData?.result);
    setIsLoading(false);
    const countOptions = {
      type: "count",
      condition: {},
    };
    const count = await list(countOptions);
    setTotalPages(getPage(count?.result));
    setIsLoading(false);
  }; //End

  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, [currentPage, showAddEditPopup, rejectId, showRequest, filter]);
  /********************** Integratin End Here **********************/

  // filter Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const RequesttoggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Topnavbar title="Request Category"></Topnavbar>
        <div className="order_now_card_tilte">
          <div class="card">
            <div class="card-body categories_main_div">
              <div className="categories_tb_select">
                <TbCategory />
                <Select
                  name="show"
                  id="show"
                  className="product_select_tag_rating_review"
                  style={{ width: 120 }}
                  bordered={false}
                  onChange={handleShowRequest}
                  defaultValue={showRequest}
                >
                  <Select.Option value="" label="All" selected={true}>
                    All
                  </Select.Option>
                  <Select.Option value="Product" label="Products">
                    Products
                  </Select.Option>
                  <Select.Option value="Service" label="Services">
                    Services
                  </Select.Option>
                </Select>
              </div>
              <div className="promotions_filters_div">
                {/* <div className="fa_filter_rating_review" onClick={RequesttoggleDrawer}>
                  <img
                    src={ASSETS_BASE_URL + "./images/Filter.svg"}
                    alt=""
                    className="user_iconses"
                  />
                  <Link
                    to={false}
                    className="filter_btn_invontry_rating_review"
                  >
                    {" "}
                    Filters
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <CategoriesProductList>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Category Name</th>
                              <th>Details</th>
                              <th>Request Type</th>
                              <th>Created on</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      <div className="admin_prodcut_list_span">
                                        {items?.name}
                                      </div>
                                    </td>
                                    <td>{items?.description}</td>
                                    <td>{items?.request}</td>
                                    <td>
                                      {moment(`${items.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}
                                    </td>
                                    <td
                                      dangerouslySetInnerHTML={{
                                        __html: requestStatusMessage(
                                          items.status
                                        ),
                                      }}
                                    ></td>
                                    <td>
                                      {items.status === "R" ? (
                                        <>
                                          <Dropdown
                                            overlay={
                                              <Menu>
                                                <Menu.Item key="1">
                                                  <span>
                                                    Reason :{" "}
                                                    {items && items.reason
                                                      ? items.reason
                                                      : "Reason not described."}
                                                  </span>
                                                  <br />
                                                  <span>
                                                    Reject At :{" "}
                                                    {moment(
                                                      `${items.updatedAt}`
                                                    ).format(
                                                      "DD/MM/YYYY, h:m A"
                                                    )}
                                                  </span>
                                                </Menu.Item>
                                              </Menu>
                                            }
                                            placement="bottomLeft"
                                            arrow
                                          >
                                            <FaEye />
                                          </Dropdown>
                                        </>
                                      ) : (
                                        <>
                                          {items.status !== "C" ? (
                                            <>
                                              <Dropdown
                                                overlay={
                                                  <Menu>
                                                    <Menu.Item
                                                      key="1"
                                                      onClick={() => {
                                                        setRejectId(items?._id);
                                                        handleRejectToggle();
                                                      }}
                                                    >
                                                      <FaTimes />
                                                      <span className="show_span_edit">
                                                        Reject
                                                      </span>
                                                    </Menu.Item>
                                                    <Menu.Item
                                                      key="2"
                                                      onClick={() => {
                                                        handleComplete(
                                                          items._id
                                                        );
                                                      }}
                                                    >
                                                      <FaCheck />{" "}
                                                      <span className="show_span">
                                                        Complete
                                                      </span>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                placement="bottomLeft"
                                                arrow
                                              >
                                                <HiOutlineDotsHorizontal />
                                              </Dropdown>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </>
                            ))) : isLoading ? <SkeletonList row={10} col={6} /> : <tr> 
                            <td colspan="6" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                        </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CategoriesProductList>
          </Box>
        </div>

        <CategoryProductDeletePopUp
          id={deteteId}
          deletePopUpCategory={deletePopUpCategory}
          deleteProductToggle={handleProductDeleteToggle}
          setDeteteId={setDeteteId}
        />
        <ProductAddEditData
          show={showAddEditPopup}
          editdata={EDITDATA}
          setEditData={setEditData}
          setAddEditPopup={setAddEditPopup}
        />

        <RejectPopup
          id={rejectId}
          isRejectPopup={isRejectPopup}
          handleRejectToggle={handleRejectToggle}
          setRejectId={setRejectId}
        />
        <RequestFilterDrawer
          drawerOpen={drawerOpen}
          RequesttoggleDrawer={RequesttoggleDrawer}
        />
      </div>
    </>
  );
}
