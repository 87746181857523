import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Flex, notification, Upload } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { beforeUpload } from '../../controllers/common';
import Loading from "../../components/Loading/LoadingEffect";
import { addeditdata as addEditInsightData } from "../../controllers/insights/insightsController";
import moment from "moment";

const PromotionsAddEditPopUp = ({
  InsightsAddEditPopUpmodal,
  insightsToggleAddEditPopUp,
  addEditData,
  setAddEditData
}) => {
  const handleCancel = () => {
    setAddEditData("");
    insightsToggleAddEditPopUp();
    setImageList("");
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /************************************ */
  const [errors, setErrors]= useState("");
  const [loading, setLoading] = useState(false);

  const [imageList, setImageList] = useState("");
   
  const handleChnage = (e) => {
    try {
      const {name, value} = e.target;
        setAddEditData((pre)=>({
          ...pre,
          [name] : value
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }))
    } catch (error) {
      
    }
  };

  /*********************************************************
  *  This function is use to handle image object
  *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj)
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const form = new FormData(e.target);
      form.append('image',imageList?imageList:null);
      const isValidate = await validateFormData(form);
      if(isValidate){
        const res = await addEditInsightData(form);
        if(res.status === true){
          setLoading(false);
          notification.open({
            message: "Success",
            description: " Data save Successfully .",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          handleCancel();
        } else{
          setLoading(false);
          notification.open({
            message: "Error",
            description: res?.message || 'Please try after some time',
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        }
      } else{
        setLoading(false);
        notification.open({
          message: "Oops!",
          description: " Please fill required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateFormData = async (formData) => {
    try {
      if(formData.get('title') === 'undefined' || formData.get('title') === null || formData.get('title').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          title : "Title is required"
        }));
        return false;
      } else if(formData.get('short_description') === 'undefined' || formData.get('short_description') === null || formData.get('short_description').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          short_description : "Short description is required"
        }));
        return false;
      } else if(formData.get('description') === 'undefined' || formData.get('description') === null || formData.get('description').trim() === ''){
        setErrors((preError)=>({
          ...preError,
          description : "Description is required"
        }));
        return false;
      } else if(!addEditData?._id && (formData.get('image') === null || formData.get('image') === 'undefined' )){
        setErrors((preError)=>({
          ...preError,
          image : "Image is required"
        }));
        return false;
      }else{
        return true;
      }
    } catch (error) {
      return false;
    }
  } 


// console.log('addEditData',addEditData);
  return (
    <Dialog
      open={InsightsAddEditPopUpmodal}
      onClose={insightsToggleAddEditPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              {addEditData?._id?'Edit':'Add'} Insights
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <input type="hidden" name="editId" id="editId" value={addEditData?._id}/>
                  <div className="form-group row">

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="title" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        value={addEditData?.title}
                        onChange={handleChnage}
                        class="form-control categories_select_tg_enter_product"
                        placeholder="Enter title"
                      />
                      {errors?.title?(<p className="text-danger">{errors.title}</p>):""}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="short_description" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Short Description
                      </label>
                      <textarea 
                        name="short_description" 
                        id="short_description"
                        class="form-control categories_select_tg_enter_product"
                        placeholder="Enter short description."
                        onChange={handleChnage}
                      >{addEditData?.short_description}</textarea>
                      {errors?.short_description?(<p className="text-danger">{errors.short_description}</p>):""}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="description" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Description
                      </label>

                      <textarea 
                        name="description" 
                        id="description"
                        class="form-control categories_select_tg_enter_product"
                        placeholder="Enter description."
                        onChange={handleChnage}
                      >{addEditData?.description}</textarea>

                      {errors?.description?(<p className="text-danger">{errors.description}</p>):""}
                    </div>

                    <div>
                    <div class="form-group inventory_popup_create_promocodes_product_img">
                      <label
                        for="formGroupExampleInput"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        Product Image
                      </label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          beforeUpload={beforeUpload}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                        >
                          <button className="inventroy_edit_page_antd_uplaod_button" type="button">
                            <div className="inventroy_edit_page_antd_choose_file">Choose File</div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{color:"blue"}} className="all_lable_for_vendor_dashbaord">*Note: Please choose an image of JPEG/JPG/PNG format file.</p>
                      {errors?.image?<p style={{color:"red"}}>{errors.image}</p>:""}
                    </div>
                    </div>
                    
                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        class="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="Submit"
                        class="btn btn-danger "
                      >
                        {addEditData?._id?"Save Chnage":"Create"}
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </Typography>
          {/* <DialogActions>
          <Button color="primary">Cancel</Button>
          <Button style={{ color: "red" }}>Delete</Button>
        </DialogActions> */}
        </div>
        <Loading
          isLoading={loading}
        />
      </div>
    </Dialog>
  );
};

export default PromotionsAddEditPopUp;
