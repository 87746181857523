import {API_ADMIN_URL , API_BASE_URL} from '../../config/constants'
import { postRequest, fetchIpAddress } from '../API'

/*********************************************************
* Function Name : List
* Description   : Get list of all sub category
* By            : Afsar Ali
* Date          : 15-02-2024 
*********************************************************/
export const list = async (options, cancelToken) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}subcategories/products/list`,
            postData : options
        }
        const res = await postRequest(params,cancelToken);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : addEditData
* Description   : Add/Edit service category
* By            : Afsar Ali
* Date          : 15-02-2024 
*********************************************************/
export const addEditData = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function
/*********************************************************
* Function Name : chnageStatus
* Description   : chnage sub status
* By            : Afsar Ali
* Date          : 15-02-2024 
*********************************************************/
export const chnageStatus = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function
/*********************************************************
* Function Name : deleteCategory
* Description   : delete sub category
* By            : Afsar Ali
* Date          : 15-02-2024
*********************************************************/
export const deleteCategory = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : sessionStorage.getItem('IP_ADDRESS')
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function



/*********************************************************
* Function Name : List
* Description   : Get list of all sub category
* By            : Afsar Ali
* Date          : 15-02-2024 
*********************************************************/
export const breedslist = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}front/v1/common/breeds-list`,
            postData : options
        }
        const res = await postRequest(params);
        // console.log('breed res',res);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function
