import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { chnageStatus } from "../../../controllers/categories/request"

const CategoryProductDeletePopUp = ({
  id,
  isRejectPopup,
  handleRejectToggle,
  setRejectId
}) => {
  const [ reason, setReason ] = useState("");
  const handleCancel = () => {
    setRejectId("")
    setReason("");
    handleRejectToggle();
  };

  const handleConform = async () => {
    const param ={
      url :`categories/request/chnage-status`,
      postData : { 
        id : id,
        status : "R",
        reason : reason
      } 
    }
    const res = await chnageStatus(param);
    if(res.status === true){
      notification.open({
        message: "Rejected!",
        description: " Request Rejected Successfully .",
        placement: "topRight",
        duration : 2,
        icon: <DeleteOutlined style={{ color: "green" }} />,
      });
    } else{
      notification.open({
        message: "Opps!!",
        description: " Please try again.",
        placement: "topRight",
        duration : 2,
        icon: <DeleteOutlined style={{ color: "red" }} />,
      });
    }
    setRejectId("");
    setReason("");
    handleCancel();
  };

  return (
    <Dialog
      open={isRejectPopup}
      onClose={handleCancel}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={handleRejectToggle}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you want to reject this request?</p>
          <input 
            type="text" 
            name="reason" 
            id="reason" 
            value={reason} 
            class="form-control categories_select_tg_enter_product" 
            placeholder="Enter the reason for rejection here." 
            onChange={(e)=>(setReason(e.target.value))}

          />
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConform} style={{ color: "red" }}>
            Yes
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default CategoryProductDeletePopUp;
